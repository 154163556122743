@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);
.ver_compatibility_subwrapper {
  // padding: 62 * $desk + vw 55 * $desk + vw 0 * $desk + vw 30 * $desk + vw;
  .compatibility_partition {
    display: flex;
    // gap: 13vw;
    margin-bottom: 5%;


    .compatibility_lhs {
      // display: flex;
      // flex-wrap: wrap;
      // height: 500px;
      width: 450 * $desk + vw ;

    }
    .compatibility_rhs {
      text-align: start;
    }
  }
  .compatibility_header {
    border-radius: 10 * $desk + vw;
    width: 253 * $desk + vw;
    padding: 5 * $desk + vw;

    color: #013aa2;
    // margin-top: 1vw;
    // margin-bottom: 1vw;
    font-size: 22 * $desk + vw;
    background: linear-gradient(97deg, #eff3ff, #f3f6ff);
  }
  .dependent_header {
    border-radius: 10 * $desk + vw;
    padding: 5 * $desk + vw;
    width: 253 * $desk + vw;
    color: #013aa2;
    margin-top: 2vw;
    // margin-bottom: 1vw;
    font-size: 22 * $desk + vw;
    background: linear-gradient(97deg, #eff3ff, #f3f6ff);
  }
  .basicDetails_upper_wrapper{
    justify-content: flex-start !important;
  }
  .content_main_wrapper{
    margin-right: 15* $desk + vw;
  }
}
