@use "sass:math";
$desk: math.div(100, 1920);

.title {
  text-align: center;
}

.custom-select-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .dd-label {
    font-size: 15 * $desk + vw;
    color: var(--primaryInvert);
    font-weight: 600;
    margin-bottom: 5px;
  }
  .selected-text {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: left;
    font-family: "Open Sans" !important;
    font-size: 14 * $desk + vw;
    background-color: var(--device-filter);
    // box-shadow: var(--box-shadow);
    border-radius: 8px;
    border: 2px solid #dddddd;
    padding: 6px 16 * $desk + vw;
    cursor: pointer;
    color: var(--text);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .drop_icon {
    position: absolute;
    right: 8 * $desk + vw;
    width: 10 * $desk + vw;
    height: 10 * $desk + vw;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    transition: all 0.2s ease-in;
    ~ {
      fill: var(--text);
    }
    path {
      stroke: var(--text);
    }
  }
  .list_open {
    top: 45%;
    transform: rotate(180deg);
  }

  .select-options {
    position: absolute;
    width: 100%;
    // max-height: 9vw;
    // overflow: auto;
    opacity: 0;
    top: 90%;
    visibility: hidden;
    border-radius: 9px;
    transform: scale(0.01);
    transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s;
    transform-origin: center top;
    box-shadow: var(--box-shadow);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    z-index: 5;
    .custom-select-option {
      color: var(--text);
      width: 100%;
      border: 0;
      padding: 6px 16 * $desk + vw;
      text-align: left;
      background-color: var(--device-filter);
      border-bottom: 1px solid gray;
      cursor: pointer;
      word-break: break-all;
      overflow: hidden;
      display: block;
      text-transform: capitalize;
      font-family: "Open Sans" !important;
      font-size: 14 * $desk + vw;
      &:hover {
        background-color: var(--primary);
        color: #ffffff;
      }
    }
  }

  .custom-select-option:first-child {
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
  }
  .custom-select-option:last-child {
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }

  .active__ {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transform-origin: center top;
    // transform: translateY(0);
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      top: -12px;
      border-color: transparent transparent transparent var(--device-filter);
      border-width: 6px;
      transform: rotate(270deg);
      left: 85.5%;
    }
  }
  .active_option {
    background-color: var(--primary) !important;
    color: #ffffff !important;
  }
}
