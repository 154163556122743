.__loading__ {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.6);
  .loading_wrapper {
    position: absolute;
    right: 0;
    top: 45%;
    left: 50%;
    color: #fff;
    transform: translate(-50%);
    text-align: center;
    h2 {
      color: #fff;
      font-size: 28px;
      margin-top: 10px;
    }

    .loading_anim {
      width: 50px;
      height: 50px;
      margin: auto;
      border: 6px solid #f2f2f2;
      border-top: 6px dotted #f2f2f2;
      border-bottom: 6px dotted #f2f2f2;
      border-radius: 50%;
      animation: loading 4s infinite;
    }
    @keyframes loading {
      0% {
        transform: rotate(0);
      }
      50% {
        transform: rotate(360deg);
      }
    }
  }
}

// .__loading__adjust__ {
//   background: rgba(0, 0, 0, 0.9);
//   position: fixed;
//   left: 0;
//   top: 0;
//   z-index: 500;
//   h2 {
//     animation: none;
//   }
// }
