@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.filedrop_label {
  // display: inline-block;
  text-transform: lowercase;
}
.filedrop_label:first-letter {
  text-transform: uppercase;
  // color: red;
}

.filedrop_name {
  margin-top: -1%;
  color: #848484;

  font-size: 0.78125vw;
  margin-bottom: 8 * $desk + vw;
  label {
    &::after {
      content: "*";
      color: red;
      margin-left: 0.5vw;
      font-weight: 400;
    }
  }
}
.helperText {
  // top:  88% !important;
}
.filedrop__error {
  margin-top: 17 * $desk + vw;
  text-align: left;
  border: 2px solid rgba(248, 78, 78, 0.7) !important;
  margin-bottom: 1vw !important;
}

.filedrop_main_wrapper {
  position: relative;
  width: 100%;
  height: 121 * $desk + vw;
  // background-color: aqua;
  border: 1.2 * $desk + vw solid #dddddd;
  border-radius: 14 * $desk + vw;
  margin-bottom: 20 * $desk + vw;

  .filedrop_subwrapper {
    margin-top: 1.15vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    .profile_holder {
      width: 75 * $desk + vw;
      height: 75 * $desk + vw;
      border-radius: 50%;
      border: 2px solid rgba(69, 0, 255, 0.2);
      // border: solid 1px #4500ff;
      padding: 9 * $desk + vw;
    }

    .no_profile {
      background: linear-gradient(97deg, #013aa2, #2d62ed);

      width: 100%;
      height: 100%;
      border: 2px solid white;
      border-radius: 50%;
      // box-shadow: var(--box-shadow);
      box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.26);
      object-fit: cover;
    }
    .btn {
      text-transform: capitalize;
    }
  }
  .multipleimg_cross {
    color: #ffffff;
    border-radius: 15 * $desk + vw;
    align-items: center;
    text-align: center;
    width: 1vw;
    height: 1vw;
    position: absolute;
    top: 5%;
    background-color: #2d62ed;
    border: 1 * $desk + vw solid #013aa2;
    display: flex;
    right: 5%;
    justify-content: center;
  }
  .filedrop_img {
    width: 75 * $desk + vw;
    height: 75 * $desk + vw;
  }
  .dropzone_name {
    position: relative;
    width: 180 * $desk + vw;
    background-color: var(--body-background);
    border-radius: 14 * $desk + vw;
    
  }
  .multiple_filename {
    text-overflow: ellipsis;
    max-width: 10.208333vw;
    overflow: hidden;
    white-space: nowrap;
    font-size: 15 * $desk + vw;
    width: 150 * $desk + vw;
    justify-content: space-evenly;
    margin: 0 5 * $desk + vw 7 * $desk + vw   5 * $desk + vw;
  }
  .filee_name {
    width: 500 * $desk + vw;
    height: 3.822917vw;
    // display: inline-block;
    text-overflow: ellipsis;
    max-width: 11.625vw;
    overflow: hidden;
    white-space: nowrap;
    padding: 0.5vw;
    // color: #111b60;
    color: var(--primaryInvert);
    justify-content: center;
    font-size: 15 * $desk + vw;
    align-items: center;
    text-align: center;
    display: flex;
    border: 4 * $desk + vw solid #dddddd;
    border-radius: 22 * $desk + vw;
    border-style: dashed;
    margin-bottom: 7 * $desk + vw;
  }
  .file_name {
    padding: 5 * $desk + vw;
    margin-top: 5 * $desk + vw;
    text-overflow: ellipsis;
    max-width: 6.625vw;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0.8854166667vw;
    justify-content: center;
    border-radius: 0.7291666667vw;
    margin-bottom: 0.3645833333vw;
    // display: flex;
    // border: 4 * $desk + vw solid #dddddd;
    // border-radius: 22 * $desk + vw;
    // border-style: dashed;
    // width: 282* $desk + vw;
    // padding: 15 * $desk + vw;
  }
  .btn {
    width: 106.42 * $desk + vw;
    height: 31 * $desk + vw;
    font-size: 15 * $desk + vw;
    top: 33%;
    position: absolute;
    width: 13%;
    // align-items: center;
    right: 10%;
    padding: 4 * $desk + vw;
    /* text-align: center; */
    /* color: white; */
    /* height: 3.87037vh; */
    font-size: 0.78125vw;
  }
}
.resolution_sent {
  // position: absolute;
  // bottom: -18%;
  // position: absolute;
  text-align: center;
  font-size: 10 * $desk + vw;
  color: #111b60;
  margin-left: 22.5%;
  margin-bottom: 22.5%;
  text-align: center;
  width: 250 * $desk + vw;
  display: flex;
  justify-content: center;
}
p.dashed {
  border-style: dashed;
}
.draganddrop_tag {
  // margin-top: 5 * $desk + vw;
}
.filedrop_reset_image {
  background-color: #013aa2;
  width: 26 * $desk + vw;
  height: 28 * $desk + vw;
  position: absolute;
  top: -1%;
  right: 0%;
  border-top-right-radius: 6 * $desk + vw;
}

.files_uploaded_name {
  font-size: 14 * $desk + vw;
  color: #72778f;
  width: 120 * $desk + vw;
  height: 22 * $desk + vw;
}
