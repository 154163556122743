@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);
._Usecase_wrapper_ {
  position: relative;
  .Usecase_body {
    position: relative;
    height: 100%;
    .profile_bg {
      width: 100%;
    }
    .body_content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1500 * $desk + vw;
      top: 75 * $desk + vw;
      h1 {
        color: #fff;
        margin-bottom: 15 * $desk + vw;
        font-size: 32 * $desk + vw;
        letter-spacing: 2px;
      }
      .goBack {
        color: #fff;
        font-family: Poppins;
        font-size: 18 * $desk + vw;
        text-decoration: underline;
        margin-right: 1.5vw;
        cursor: pointer;
      }
      .Usecase_data {
        width: 100%;
        background-color: var(--background);
        height: 750 * $deskHeight + vh;

        border-radius: 12px;
        overflow: hidden;
        border: 2px solid #e7e7e7;
        .usecase_main_wrapper {
          padding: 35 * $desk + vw 32 * $desk + vw 28 * $desk + vw 32 * $desk +
            vw;
          .usecase_subwrapper {
            .partition_wrapper {
              padding: 4.604167vw 32 * $desk + vw 40 * $desk + vw 0 * $desk + vw;
              display: flex;
              // justify-content: space-between;
              gap: 3vw;

              .lhs_wrapper {
                width: 54.666667vw;
                position: relative;
                .slick-prev {
                  // right: 80 * $desk + vw !important;
                  // bottom: -50 * $desk + vw !important;
                }
                // .slick-next {
                //   // background-color: #013aa2;
                //   opacity: 100%;
                //   right: 0.5%;
                //   border-radius: 100%;
                // }
                .slick-next:before {
                  content: none !important;
                }
                .slick-prev:before {
                  content: none !important;
                }

                .slick-track {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-evenly;
                  // width: 100% !important;
                  // height: 419 * $desk + vw !important;
                }
                .slick-slide {
                  width: 193 * $desk + vw !important;
                  width: 50% !important;
                  margin: 10 * $desk + vw;
                  // height: 419 * $desk + vw !important;
                }
                .usecase_mid_subwrapper {
                  .viewImages {
                    // display: flex;
                    // flex-direction: row;
                  }
                }
                .usecase_lower_subwrapper {
                  position: relative;
                }
                .slick-prev {
                  left: -6.9px;
                  z-index: 2;

                  // transform: rotate(180deg) ;
                  // transform-origin: s;
                  svg {
                    right: 80 * $desk + vw;
                    bottom: -50 * $desk + vw;
                    background-color: #fff;
                    border-radius: 50%;
                    width: 2vw;
                    height: 2vw;
                    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.35);
                  }
                  &:disabled {
                    cursor: not-allowed;
                    svg {
                      path {
                        fill: #6a6a6d;
                      }
                    }
                  }
                }
                .slick-next {
                  // right: 55 * $desk + vw;
                  right: 5*$desk + vw;
                  // bottom: -50 * $desk + vw;

                  svg {
                    background-color: #fff;
                    border-radius: 50%;
                    width: 2vw;
                    height: 2vw;
                    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.35);
                  }
                  &:disabled {
                    cursor: not-allowed;
                    svg {
                      path {
                        fill: #6a6a6d;
                      }
                    }
                  }
                }
              }

              .rhs_wrapper {
                flex: 1;
              }
            }
          }
        }
      }
    }
  }
  .close_otp_modal{
position: fixed;
  }
  .permission_wrapper{
    padding: 35 * $desk + vw 32 * $desk + vw 28 * $desk + vw 32 * $desk + vw;
    width: 900 * $desk + vw;
    .about_upper_wrapper {
      display: flex;
      gap: 2vw;
      margin-bottom: 3%;
      align-items: center;

      .about_appImage {
        width: 100 * $desk + vw;
        height: 100 * $desk + vw;
        border-radius: 10* $desk + vw;
        object-fit: cover;
      }
    }
    .next_icon{
      // width: 18 * $desk + vw;
      transform: rotate(180deg);
      cursor: pointer;
    }
    .permission_back_icon{
      display: flex;
      gap: 0.5vw;
      color: blue;
    }
    .permission_wrapper{
    padding: 35 * $desk + vw 32 * $desk + vw 0 * $desk + vw 32 * $desk + vw;
      
    }
    .permission_subwrapper {
      .permission_partition {
        display: flex;
        gap: 19vw;
        margin-bottom: 5%;
        margin-top: 5%;
        .permission_lhs {
          // display: flex;
          // flex-wrap: wrap;
          // height: 500px;
          width: 12.416667vw;
        }
        .permission_rhs {
          text-align: start;
        }
      }
    }
  }
  .about_main_wrapper {
    padding: 35 * $desk + vw 32 * $desk + vw 28 * $desk + vw 32 * $desk + vw;
    width: 900 * $desk + vw;
    .about_upper_wrapper {
      display: flex;
      margin-bottom: 5%;
      align-items: center;


      .about_appImage {
        width: 100 * $desk + vw;
        height: 100 * $desk + vw;
        border-radius: 10* $desk + vw;
        object-fit: cover;
      }
    }
    .about_side_wrapper {
      text-transform: lowercase;
      padding: 35 * $desk + vw 32 * $desk + vw 28 * $desk + vw 32 * $desk + vw;

      .about_header{
        font-size: 22 * $desk + vw;
        text-transform: lowercase;
      }
      .about_header::first-letter{
        text-transform: uppercase;
      }
    }
    .about_side_wrapper::first-letter{
      text-transform: uppercase;
    }
    .about_lower_wrapper::first-letter{
      text-transform: capitalize;
    }
    .about_lower_wrapper {
      text-transform: lowercase;
      .details_subwrapper {
        .details_partition {
          display: flex;
          // gap: 19vw;
          // margin-bottom: 5%;
          margin-top: 5%;
          .details_lhs {
            width: 450 * $desk + vw ;
            // display: flex;
            // flex-wrap: wrap;
            // height: 500px;
          }
          .details_rhs {
            text-align: start;
          }
        }
      }
      .compatibility_subwrapper {
        // .compatibility_partition {
        //   display: flex;
        //   // gap: 13vw;
        //   margin-bottom: 5%;


        //   .compatibility_lhs {
        //     // display: flex;
        //     // flex-wrap: wrap;
        //     // height: 500px;
        //     width: 450 * $desk + vw ;

        //   }
        //   .compatibility_rhs {
        //     text-align: start;
        //   }
        // }
      }
      .dependent_services_subwrapper {
        .dependent_services_partition {
          display: flex;
          // gap: 23vw;
          margin-bottom: 5%;


          .dependent_services_lhs {
            // display: flex;
            // flex-wrap: wrap;
            // height: 500px;
            width: 450 * $desk + vw ;

          }
          .dependent_services_lhs {
            text-align: start;
          }
        }
      }
    }
   
  }
  .ratings_main_wrapper {
    padding: 35 * $desk + vw 32 * $desk + vw 28 * $desk + vw 32 * $desk + vw;
    width: 900 * $desk + vw;
    .ratings_upper_wrapper {
      display: flex;
      align-items: center;

      .ratings_appImage {
        width: 5.2083333333vw;
        height: 5.2083333333vw;
        border-radius: 0.5208333333vw;
        object-fit: cover;
      }
    }
    .ratings_side_wrapper {
      padding: 35 * $desk + vw 32 * $desk + vw 28 * $desk + vw 32 * $desk + vw;
    }
  }
}
