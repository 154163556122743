@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.__profile_wrapper__ {
  position: relative;
  .profile_body {
    position: relative;
    height: 100%;
    .profile_bg {
      width: 100%;
    }
    .profile_content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1500 * $desk + vw;
      top: 75 * $desk + vw;
      h1 {
        color: #fff;
        font-family: Poppins;
        margin-bottom: 15 * $desk + vw;
        font-size: 30 * $desk + vw;
      }
      .goBack {
        color: #fff;
        font-family: Poppins;
        font-size: 18 * $desk + vw;
        text-decoration: underline;
        margin-right: 1.5vw;
        cursor: pointer;
      }
      .profile_data {
        display: flex;
        width: 100%;
        background-color: var(--background);
        height: 750 * $deskHeight + vh;
        border-radius: 12px;
        overflow: hidden;

        .deployed_usecase_nodata {
          justify-content: center;
          display: flex;
          align-items: center;
          width: 80%;
          font-weight: 600;
          font-size: 32* $desk + vw;
          text-transform: capitalize;
        }
        .profile_left {
          width: 300 * $desk + vw;
          height: 100%;
          border-right: 1px solid #e7e7e7;
          .profile_holder {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 250 * $desk + vw;
            border-bottom: 1px solid #e7e7e7;
            .profile_image_container {
              position: relative;
              width: 165 * $desk + vw;
              overflow: hidden;
              .profile_hover {
                position: absolute;
                display: flex;
                opacity: 0;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                top: 0;
                left: 0;
                width: 100%;
                cursor: pointer;
                height: 100%;
                border-radius: 50%;
                transition: opacity 300ms ease-in-out;
                p {
                  font-size: 14 * $desk + vw;
                  margin-top: 10 * $desk + vw;
                  width: 70%;
                  text-align: center;
                  color: white;
                }
                &:hover {
                  opacity: 1;
                }
              }
            }
            .profile_image {
              width: 100%;
              height: 165 * $desk + vw;
              border-radius: 50%;
              border: 6px solid #e1e1e1;
            }
            h2 {
              font-size: 18 * $desk + vw;
              margin-top: 10 * $desk + vw;
              text-align: center;
              font-weight: 500;
            }
          }
          .pro_option {
            position: relative;
            padding: 14 * $desk + vw 0;
            padding-left: 85 * $desk + vw;
            font-size: 15 * $desk + vw;
            cursor: pointer;
            border-bottom: 1px solid #e7e7e7;
            color: var(--label);
            .arrow-right {
              position: absolute;
              right: 30 * $desk + vw;
              top: 44%;
              transform: translateY(-50%);
              width: 10 * $desk + vw;
              height: 10 * $desk + vw;
              border-top: 2px solid var(--primaryInvert);
              border-left: 2px solid var(--primaryInvert);
              transform: rotate(135deg);
            }
          }
          // .active_stage {
          //   color: var(--primary);
          // }
        }
        .profile_right {
          padding: 50 * $desk + vw 110 * $desk + vw;
          flex: 1;
          .device_title {
            font-weight: 600;
            font-size: 22 * $desk + vw;
            margin-bottom: 40 * $desk + vw;
          }
          .register_data {
            display: flex;
            width: 100%;
            .input_style {
              padding: 8 * $desk + vw;
              font-size: 18 * $desk + vw;
            }
            .left-section {
              width: 50%;
              margin-right: 40 * $desk + vw;
            }
            .right-section {
              margin-left: 40 * $desk + vw;
              width: 50%;
              .custom-multi-select-container {
                width: 440 * $desk + vw;
              }
            }
            #_country_ {
              position: relative;
            }
            .suggestions {
              position: absolute;
              width: 99%;
              margin-left: 1%;
              top: 92%;
              border-radius: 5px;
              border: 2px solid #dddddd;
              background-color: #fff;
              z-index: 5;
              .suggestion_item {
                margin: 0 5 * $desk + vw;
                border-bottom: 1px solid #ddd;
                padding: 8 * $desk + vw;
                font-size: 14 * $desk + vw;
                font-weight: 400;
                color: #000;
              }
              .suggestion_item:last-child {
                border-bottom: 0;
              }
            }
          }
          .left {
            // background-color: red;
            height: 100%;
            flex: 1;
            border-radius: 12px;
            border: 0.1041666667vw solid rgba(218, 228, 248, 0.5);
            padding: 16 * $desk + vw 30 * $desk + vw;
            padding-right: 1vw;
            .user-name {
              font-size: 30 * $desk + vw;
              color: var(--text);
              letter-spacing: unset;
              margin-bottom: 0;
              text-transform: capitalize;
            }
            .user-created {
              font-size: 18 * $desk + vw;
              color: var(--text);
              opacity: 0.7;
              margin-top: 5 * $desk + vw;
            }
            .flex {
              display: flex;
              // height: 100%;
              margin-top: 20 * $desk + vw;
              padding-left: 0.5vw;
              padding-bottom: 1vw;
              .flex_left {
                flex: 4;
                .profile_image {
                  width: 9vw;
                  height: 9vw;
                  object-fit: cover;
                  border-radius: 12px;
                  margin-bottom: 10 * $desk + vw;
                  box-shadow: var(--box-shadow);
                }
                .user_avatar_img {
                  width: 210 * $desk + vw;
                  height: 210 * $desk + vw;
                  border-radius: 8px;
                  border: 1px solid var(--primary);
                }
                .requestCard {
                  margin-top: 20 * $desk + vw;
                  margin-bottom: 20 * $desk + vw;
                  .manu_request_card_ {
                    border-radius: 12px;
                    box-shadow: var(--box-shadow);
                    width: 90%;
                  }
                }
                .role_table {
                  border-collapse: collapse;
                  box-shadow: var(--box-shadow);
                  font-size: 16 * $desk + vw;
                  border-radius: 12px;
                  td {
                    padding: 0.5vw;
                    border: 1px solid #dddddd;
                    text-transform: capitalize;
                  }
                }
              }
              .flex_right {
                display: flex;
                flex-direction: column;
                gap: 50 * $desk + vw;
                flex: 5;
                height: 85%;
                .user-data {
                  display: flex;
                  .icon {
                    display: grid;
                    place-content: center;
                    width: 50 * $desk + vw;
                    height: 50 * $desk + vw;
                    border-radius: 50%;
                    background-color: var(--sidenav);
                    svg {
                      width: 25 * $desk + vw;
                      height: 25 * $desk + vw;
                    }
                  }
                  .info {
                    font-weight: 500;
                    font-size: 16 * $desk + vw;
                    margin-left: 20 * $desk + vw;
                  }
                }
              }
              ._text {
                font-size: 16 * $desk + vw;
                opacity: 0.5;
                margin-bottom: 9 * $desk + vw;
              }
            }
          }
        }
      }
    }
  }
}

._profile_wrapper_ {
  position: relative;
  .profile_body {
    position: relative;
    height: 100%;
    .profile_bg {
      width: 100%;
    }
    .profile_content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1500 * $desk + vw;
      top: 75 * $desk + vw;
      h1 {
        color: #fff;
        font-family: Poppins;
        margin-bottom: 15 * $desk + vw;
        font-size: 30 * $desk + vw;
      }
      .profile_data {
        width: 100%;
        background-color: var(--background);
        height: 750 * $deskHeight + vh;
        border-radius: 12px;
        // overflow: hidden;
        padding: 22 * $desk + vw;
        .profile_data2 {
          width: 100%;
          height: 100%;
          border-radius: 12px;
          // background-color: red;
          padding: 25 * $desk + vw 40 * $desk + vw;
          border: 2px solid var(--sidenav);
          .top {
            display: flex;
            width: 100%;
            gap: 2.6vw;
            // justify-content: space-between;
            height: 230 * $desk + vw;
            .profile_with_name {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 390 * $desk + vw;
              height: 100%;
              margin-right: 2vw;
              .name {
                font-size: 24 * $desk + vw;
                color: var(--text);
                font-weight: 500;
                margin-bottom: 0;
              }
              .profile_holder_ {
                display: flex;
                justify-content: space-between;
                .image_holder {
                  position: relative;
                  width: 152 * $desk + vw;
                  height: 152 * $desk + vw;
                  // background-color: #fff;
                  user-select: none;
                  border-radius: 8px;
                  overflow: hidden;
                  img,
                  .user_avatar_img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                  .user_avatar_img {
                    border: 4px solid var(--primary);
                  }
                  &:hover {
                    #circle,
                    .click {
                      display: block;
                    }
                  }
                  #circle {
                    display: none;
                    position: absolute;
                    width: 85%;
                    height: 85%;
                    box-shadow: 0 0 0 100px rgba(255, 255, 255, 0.5); /* !!! */
                    border-radius: 50%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                  .click {
                    position: absolute;
                    top: 50%;
                    display: none;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    p {
                      font-size: 13 * $desk + vw;
                      text-align: center;
                      margin-top: 5 * $desk + vw;
                      padding: 0.5vw;
                      color: rgba(255, 255, 255, 1);
                      // background-color: #fff;
                      opacity: 0.7;
                      // background-color: rgba(0, 0, 0, 0.5);
                      text-shadow: 4px 2px 3px rgba(0, 0, 0, 1);
                    }
                    .profileIcon {
                      margin: 0 auto;
                      display: block;
                    }
                  }
                }

                .username_button {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  p {
                    font-size: 22 * $desk + vw;
                    font-weight: 600;
                    align-self: flex-end;
                    max-width: 11vw;
                    word-break: break-all;
                    align-self: flex-start;
                  }
                  .btn {
                    width: unset;
                    padding: 8 * $desk + vw 18 * $desk + vw;
                    margin: 0;
                    font-size: 14 * $desk + vw;
                    margin: 0 5 * $desk + vw;
                    // margin-bottom: 0.8vw;
                  }
                }
              }
            }
            .role_selector {
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              width: 350 * $desk + vw;
              height: 100%;
              border-radius: 22px;
              background: rgb(65, 54, 241);
              background: linear-gradient(
                121deg,
                rgba(65, 54, 241, 1) 15%,
                rgba(1, 58, 162, 0.9976365546218487) 43%
              );
              padding: 15 * $desk + vw 33 * $desk + vw;
              overflow: hidden;

              .text {
                font-size: 22 * $desk + vw;
                color: #fff;
              }
              .sub-text {
                font-size: 14 * $desk + vw;
                color: #fff;
                margin-top: 18 * $desk + vw;
              }
              .btn {
                background: #fff;
                color: #000;
                width: 40%;
                font-size: 14 * $desk + vw;
                margin: 0;
                margin-top: 18 * $desk + vw;
                padding: 10 * $desk + vw 0;
              }
              .mask1 {
                width: 207 * $desk + vw;
                height: 157 * $desk + vw;
                position: absolute;
                bottom: -5vw;
                right: -5vw;
              }
              .mask2 {
                width: 45 * $desk + vw;
                height: 20 * $desk + vw;
                position: absolute;
                bottom: 2.5vw;
                right: 1vw;
              }
              .mask3 {
                width: 7vw;
                height: 7vw;
                position: absolute;
                top: 0.7vw;
                left: 0.2vw;
              }
            }
            .recent_activity {
              width: 490 * $desk + vw;
              height: 100%;
              border-radius: 22px;
              padding: 14 * $desk + vw 30 * $desk + vw;
              box-shadow: 0px 3px 40px -20px rgba(0, 0, 0, 0.45);
              overflow: hidden;
              padding-right: 0.3vw;
              h1 {
                font-size: 22 * $desk + vw;
                font-weight: 500;
                color: var(--text);
              }
              .activities_wrapper {
                .activities {
                  position: relative;
                  display: flex;
                  margin-top: 15 * $desk + vw;
                  #circle {
                    position: relative;
                    width: 5%;
                    z-index: 5;
                    top: 0.1vw;
                    &::after {
                      content: "";
                      width: 16 * $desk + vw;
                      height: 16 * $desk + vw;
                      border: 3px solid var(--primary);
                      border-radius: 100%;
                      position: absolute;
                      background: var(--background);
                    }
                  }
                  .msg_wrapper {
                    margin-left: 16 * $desk + vw;
                    flex: 1;
                    padding-top: 0.3vw;
                    h2 {
                      font-size: 16 * $desk + vw;
                      font-weight: normal;
                      line-height: 1;
                    }
                    p {
                      font-size: 12 * $desk + vw;
                      margin-top: 8 * $desk + vw;
                      font-weight: normal;
                      font-size: 14 * $desk + vw;
                      opacity: 0.7;
                      padding-right: 0.8vw;
                    }
                  }
                  &::after {
                    content: "";
                    position: absolute;
                    width: 3px;
                    height: 100%;
                    z-index: 2;
                    background-color: var(--sidenav);
                    left: 0.5vw;
                    top: 1vw;
                  }
                }
                .activities:first-child {
                  margin-top: 0;
                }
                .activities:last-child {
                  &::after {
                    display: none;
                  }
                }
              }
            }
          }
          .bottom {
            margin-top: 40 * $desk + vw;
            width: 103%;
            .phone-ib {
              .phone_input_style {
                color: black;
              }
            }
            .grid_box {
              display: grid;
              --auto-grid-min-size: 17vw;
              grid-template-columns: repeat(
                auto-fill,
                minmax(var(--auto-grid-min-size), 1fr)
              );
              grid-gap: 55 * $desk + vw;
              padding-right: 1.5vw;
              width: 100%;
              .editIcon {
                position: absolute;
                right: 1vw;
                top: 70%;
                transform: translateY(-50%);
                cursor: pointer;
                width: 1.2vw;
                height: 1.2vw;
                left: unset;
                outline: none;
                path {
                  fill: var(--primary);
                }
              }
              #_country_ {
                position: relative;
                .input_style {
                  text-transform: capitalize;
                }
              }
              .suggestions {
                position: absolute;
                width: 99%;
                margin-left: 1%;
                top: 92%;
                border-radius: 5px;
                border: 2px solid #dddddd;
                background-color: var(--body-background);
                z-index: 5;

                overflow: hidden;
                .suggestion_item {
                  // margin: 0 5 * $desk + vw;
                  border-bottom: 1px solid #ddd;
                  padding: 8 * $desk + vw;
                  padding-left: 5 * $desk + vw;
                  padding-right: 5 * $desk + vw;
                  font-size: 14 * $desk + vw;
                  font-weight: 400;
                  cursor: pointer;
                  color: #000;
                }
                .suggestion_item:last-child {
                  border-bottom: 0;
                }
              }
              .phone-ib {
                label {
                  color: var(--primaryInvert);
                }
                .country_dial_code {
                  color: #000;
                }
                .phone_no_wrapper {
                  background-color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
  .both_modal_adjust {
    .modalAdjust_ {
      align-self: center;
      width: 520 * $desk + vw;
      padding: 8 * $desk + vw 60 * $desk + vw;
      padding-bottom: 30 * $desk + vw;
      .continue_msg {
        text-align: center;
        margin-bottom: 40 * $desk + vw;
        font-size: 18 * $desk + vw;
      }
      ._flex {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 30 * $desk + vw;
        gap: 1vw;
        .image_wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 120 * $desk + vw;
          .hover_wrapper {
            position: relative;
            display: flex;
            transition: all 300ms ease-in-out;
            transform: scale(1);
            &:hover {
              transform: scale(1.1);
              .img_bg {
                background: linear-gradient(97deg, #013aa2, #2d62ed);
              }
              img {
                filter: grayscale(1) brightness(5);
              }
            }
          }
          .img_bg {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 12px;
            transition: all 300ms ease-in-out;
            background: var(--sidenav);
          }
          img {
            width: 100%;
            padding: 22 * $desk + vw;
            border-radius: 12px;
            box-shadow: var(--box-shadow);
            transition: all 300ms ease-in-out;
            transform: scale(1);
            cursor: pointer;
          }
          .title {
            font-family: Segoe UI;
            font-size: 18 * $desk + vw;
            font-weight: 300;
            margin-top: 10 * $desk + vw;
          }
        }
      }
    }
  }
}
