@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.usecase_ratings_reviews{
  display: flex;
  justify-content: space-between;
  width: 235* $desk + vw ;
  font-size: 20* $desk + vw;
  align-items: center;
}

.usecase_rr_main_wrapper {
  display: flex;
  width: 57.5vw;
  align-items: center;
   gap: 2vw; 
   font-size: 20* $desk + vw;

  // justify-content: space-between;
  .usecase_rr_upper_wrapper {
    text-align: center;
    // align-items: center;
    margin-top: 1vw;
    margin-bottom: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .review_tag{
      text-transform: capitalize;
      width: 4.5vw;
      display: flex;
      justify-content: space-between;
      gap: 0.5vw;
    }
  }
  .ratings_wrapper {
    // width:  50* $desk + vw;
    margin-left: 10* $desk + vw;
    .stars_progressbar{
      width: 45vw;
    }
  }
}

.usecase_rr_lower_wrapper {
  margin-top: 5%;
  margin-bottom: 5%;
  .image_name_wrapper {
    display: flex;
    align-items: center;
    gap: 1vw;
    margin-bottom: 1%;

    .usecase_reviewer_name {
      margin-bottom: 1%;
    }
    .reviewer_img {
      position: relative;
      // bottom: 15%;
      left: 0%;
      width: 50 * $desk + vw;
      // height: 20 * $desk + vw ;
      border-radius: 27 * $desk + vw;
    }
  }
}
