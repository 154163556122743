@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

._manufacturer_users_wrapper_ {
  position: relative;
  .manufacturer_body {
    position: relative;
    height: 100%;
    .profile_bg {
      width: 100%;
    }
    .body_content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1500 * $desk + vw;
      top: 75 * $desk + vw;
      h1 {
        color: #fff;
        margin-bottom: 15 * $desk + vw;
        font-size: 32 * $desk + vw;
        letter-spacing: 2px;
      }
      .goBack {
        color: #fff;
        font-family: Poppins;
        font-size: 18 * $desk + vw;
        text-decoration: underline;
        margin-right: 1.5vw;
        cursor: pointer;
      }
      .manufacturer_data {
        width: 100%;
        background-color: var(--background);
        height: 750 * $deskHeight + vh;
        border-radius: 12px;
        overflow: hidden;
        border: 2px solid #e7e7e7;
        .__input__ svg{
          left: 82%;
        }
        .__input__{
          margin-left: 2vw;
        }
        .result_table_container {
          border: 2px solid #f4f7fe;
          width: 96%;
          border-radius: 12px;
          // height: 560 * $deskHeight + vh;
          height: 45.851852vh;
          margin: 0 auto;
          margin-top: 20 * $desk + vw;
          // margin-left: -14 * $desk + vw;
          .noData {
            width: 100%;
            height: 100%;
            display: grid;
            place-content: center;
            font-weight: 600;
            font-size: 28 * $desk + vw;
          }
          .data_table {
            border-collapse: separate;
            border-spacing: 0 9 * $desk + vw;
            width: 100%;
            padding-right: 1vw;
            padding-left: 1vw;
            td {
              border-bottom: 1px solid #f0f0f0;
              // border-top: 1px solid #f0f0f0;
              padding-top: 15 * $desk + vw;
              padding-bottom: 15 * $desk + vw;
              font-size: 15 * $desk + vw;
            }
            tr {
              cursor: pointer;
              &:last-child {
                td {
                  border-bottom: 0;
                }
              }
            }
            .adjust_info {
              display: flex;
              align-items: center;
            }

            tr {
              border-radius: 35px;
              border: 2px solid red;
              // background-color: red;
            }
            td {
              padding: 20 * $desk + vw 15 * $desk + vw;
              text-align: center;
              vertical-align: middle;
              // font-weight: 300;
              font-size: 18 * $desk + vw;
              // color: #fff;
              svg {
                display: inline-block;
                width: 30 * $desk + vw !important;
                height: 30 * $desk + vw !important;
                vertical-align: top;
                margin-right: 12 * $desk + vw;
              }
            }
            .sr {
              font-size: 20 * $desk + vw;
              font-weight: 500;
              // color: var(--primaryInvert);
              font-style: italic;
            }
            .userInfo {
              display: flex;
              justify-content: flex-start;
              .user_avatar_img {
                // display: inline-block;
                width: 60 * $desk + vw !important;
                height: 60 * $desk + vw !important;
                border-radius: 12px;
                object-fit: cover;
                margin-right: 0;
              }
              .meta {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                text-align: left;
                margin-left: 20 * $desk + vw;
                p:first-child {
                  font-weight: bold;
                }
                p:nth-child(2) {
                  font-size: 16 * $desk + vw;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }
  .operator_modal_adjust {
    .modalAdjust_ {
      align-self: center;
      width: 520 * $desk + vw;
      max-height: 90vh;
      padding: 8 * $desk + vw 30 * $desk + vw;
      padding-bottom: 50 * $desk + vw;
      .continue_msg {
        text-align: center;
        margin-bottom: 40 * $desk + vw;
        font-size: 18 * $desk + vw;
      }
      .btn {
        padding: 0.4375vw 0.5208333333vw;
        display: block;
        margin: 0 auto;
        font-size: 16 * $desk + vw;
      }
      .error {
        text-align: center;
        color: red;
        margin-top: 1vw;
        font-size: 18 * $desk + vw;
      }
      .label {
        font-size: 0.78125vw;
        color: var(--primaryInvert);
        font-weight: 600;
        &::after {
          content: "*";
          color: red;
          margin-left: 0.5vw;
          font-weight: 400;
        }
      }
      .msg_area {
        width: 100%;
        resize: none;
        margin-top: 5px;
        border: 2px solid #dddddd;
        border-radius: 0.6770833333vw;
        padding: 0.3vw;
        font-size: 0.7770833333vw;
        outline: none;
      }
    }
  }
}
