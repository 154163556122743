@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.__super_profile_wrapper__ {
  position: relative;
  .profile_body {
    position: relative;
    height: 100%;
    .profile_bg {
      width: 100%;
    }
    .profile_content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1500 * $desk + vw;
      top: 75 * $desk + vw;
      h1 {
        color: #fff;
        font-family: Poppins;
        margin-bottom: 15 * $desk + vw;
        font-size: 30 * $desk + vw;
      }
      .goBack {
        color: #fff;
        font-family: Poppins;
        font-size: 18 * $desk + vw;
        text-decoration: underline;
        margin-right: 1.5vw;
        cursor: pointer;
      }
      .profile_data {
        display: flex;
        width: 100%;
        background-color: var(--background);
        height: 750 * $deskHeight + vh;
        border-radius: 12px;
        overflow: hidden;
        .profile_left {
          width: 300 * $desk + vw;
          height: 100%;
          border-right: 1px solid #e7e7e7;
          .profile_holder {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 250 * $desk + vw;
            border-bottom: 1px solid #e7e7e7;
            .profile_image_container {
              position: relative;
              width: 165 * $desk + vw;
              overflow: hidden;
              .profile_hover {
                position: absolute;
                display: flex;
                opacity: 0;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                top: 0;
                left: 0;
                width: 100%;
                cursor: pointer;
                height: 100%;
                border-radius: 50%;
                transition: opacity 300ms ease-in-out;
                p {
                  font-size: 14 * $desk + vw;
                  margin-top: 10 * $desk + vw;
                  width: 70%;
                  text-align: center;
                  color: white;
                }
                &:hover {
                  opacity: 1;
                }
              }
            }
            .profile_image {
              width: 100%;
              height: 165 * $desk + vw;
              border-radius: 50%;
              border: 6px solid #e1e1e1;
            }
            h2 {
              font-size: 18 * $desk + vw;
              margin-top: 10 * $desk + vw;
              text-align: center;
              font-weight: 500;
            }
          }
          .pro_option {
            position: relative;
            padding: 14 * $desk + vw 0;
            padding-left: 85 * $desk + vw;
            font-size: 15 * $desk + vw;
            cursor: pointer;
            border-bottom: 1px solid #e7e7e7;
            color: var(--label);
            .arrow-right {
              position: absolute;
              right: 30 * $desk + vw;
              top: 44%;
              transform: translateY(-50%);
              width: 10 * $desk + vw;
              height: 10 * $desk + vw;
              border-top: 2px solid var(--primaryInvert);
              border-left: 2px solid var(--primaryInvert);
              transform: rotate(135deg);
            }
          }
          // .active_stage {
          //   color: var(--primary);
          // }
        }
        .profile_right {
          padding: 50 * $desk + vw 110 * $desk + vw;
          flex: 1;
          .device_title {
            font-weight: 600;
            font-size: 22 * $desk + vw;
            margin-bottom: 40 * $desk + vw;
          }
          .register_data {
            display: flex;
            width: 100%;
            .input_style {
              padding: 8 * $desk + vw;
              font-size: 18 * $desk + vw;
            }
            .left-section {
              width: 50%;
              margin-right: 40 * $desk + vw;
            }
            .right-section {
              margin-left: 40 * $desk + vw;
              width: 50%;
              .custom-multi-select-container {
                width: 440 * $desk + vw;
              }
            }
            #_country_ {
              position: relative;
              input[type="text"] {
                text-transform: capitalize;
              }
            }
            .suggestions {
              position: absolute;
              width: 99%;
              margin-left: 1%;
              top: 92%;
              border-radius: 5px;
              border: 2px solid #dddddd;
              background-color: #fff;
              z-index: 5;
              .suggestion_item {
                margin: 0 5 * $desk + vw;
                border-bottom: 1px solid #ddd;
                padding: 8 * $desk + vw;
                font-size: 14 * $desk + vw;
                font-weight: 400;
                color: #000;
              }
              .suggestion_item:last-child {
                border-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

.__super_profile_wrapper__ {
  position: relative;
  .profile_body {
    position: relative;
    height: 100%;
    .profile_bg {
      width: 100%;
    }
    .profile_content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1500 * $desk + vw;
      top: 75 * $desk + vw;
      h1 {
        color: #fff;
        font-family: Poppins;
        margin-bottom: 15 * $desk + vw;
        font-size: 30 * $desk + vw;
      }
      .profile_data {
        display: flex;
        width: 100%;
        background-color: var(--background);
        height: 750 * $deskHeight + vh;
        border-radius: 12px;
        overflow: hidden;
        padding: 22 * $desk + vw;
        .profile_data2 {
          width: 100%;
          height: 100%;
          flex: 4;
          border-radius: 12px;
          padding: 25 * $desk + vw 40 * $desk + vw;
          border: 2px solid var(--sidenav);
          .top {
            display: flex;
            width: 100%;
            gap: 2.6vw;
            .profile_with_name {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;
              .name {
                font-size: 24 * $desk + vw;
                color: var(--text);
                font-weight: 500;
                margin-bottom: 35 * $desk + vw;
              }
              .profile_holder_ {
                display: flex;
                .image_holder {
                  position: relative;
                  width: 152 * $desk + vw;
                  height: 152 * $desk + vw;
                  // background-color: #fff;
                  border-radius: 8px;
                  overflow: hidden;
                  margin-right: 40 * $desk + vw;
                  img,
                  .user_avatar_img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                  .user_avatar_img {
                    border: 4px solid var(--primary);
                  }
                  &:hover {
                    #circle,
                    .click {
                      display: block;
                    }
                  }
                  #circle {
                    display: none;
                    position: absolute;
                    width: 85%;
                    height: 85%;
                    box-shadow: 0 0 0 100px rgba(255, 255, 255, 0.5); /* !!! */
                    border-radius: 50%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                  .click {
                    position: absolute;
                    top: 50%;
                    display: none;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    p {
                      font-size: 13 * $desk + vw;
                      text-align: center;
                      margin-top: 5 * $desk + vw;
                      padding: 0.5vw;
                      color: rgba(255, 255, 255, 1);
                      // background-color: #fff;
                      opacity: 0.7;
                      // background-color: rgba(0, 0, 0, 0.5);
                      text-shadow: 4px 2px 3px rgba(0, 0, 0, 1);
                    }
                    .profileIcon {
                      margin: 0 auto;
                      display: block;
                    }
                  }
                }

                .username_button {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  p {
                    font-size: 22 * $desk + vw;
                    font-weight: 600;
                    align-self: flex-end;
                    max-width: 11vw;
                    word-break: break-all;
                    align-self: flex-start;
                  }
                  .btn {
                    width: unset;
                    padding: 8 * $desk + vw 18 * $desk + vw;
                    margin: 0;
                    font-size: 14 * $desk + vw;
                    margin: 0 5 * $desk + vw;
                    // margin-bottom: 0.8vw;
                  }
                }
              }
            }
          }
          .bottom {
            margin-top: 40 * $desk + vw;
            width: 103%;
            .phone-ib {
              .phone_input_style {
                color: black;
              }
              label {
                color: var(--primaryInvert);
              }
              .country_dial_code {
                color: #000;
              }
              .phone_no_wrapper {
                background-color: #fff;
              }
            }
            .grid_box {
              display: grid;
              --auto-grid-min-size: 17vw;
              grid-template-columns: repeat(
                auto-fill,
                minmax(var(--auto-grid-min-size), 1fr)
              );
              grid-gap: 25 * $desk + vw;
              padding-right: 1.5vw;
              width: 100%;
              .phone-ib {
                margin-bottom: 0;
              }
              .__input__ {
                margin-bottom: 0;
              }
              .editIcon {
                position: absolute;
                right: 1vw;
                top: 70%;
                transform: translateY(-50%);
                cursor: pointer;
                width: 1.2vw;
                height: 1.2vw;
                left: unset;
                outline: none;
              }
              #_country_ {
                position: relative;
                input[type="text"] {
                  text-transform: capitalize;
                }
              }
              .suggestions {
                position: absolute;
                width: 99%;
                margin-left: 1%;
                top: 92%;
                border-radius: 5px;
                border: 2px solid #dddddd;
                background-color: #fff;
                z-index: 5;
                overflow: hidden;
                .suggestion_item {
                  // margin: 0 5 * $desk + vw;
                  border-bottom: 1px solid #ddd;
                  padding: 8 * $desk + vw;
                  padding-left: 5 * $desk + vw;
                  padding-right: 5 * $desk + vw;
                  font-size: 14 * $desk + vw;
                  font-weight: 400;
                  cursor: pointer;
                  color: #000;
                }
                .suggestion_item:last-child {
                  border-bottom: 0;
                }
              }
            }
          }
        }
        .manufacturer_request {
          position: relative;
          overflow: hidden;
          flex: 2;
          border: 2px solid var(--sidenav);
          border-radius: 12px;
          margin-left: 25 * $desk + vw;
          .manu_request_card_ {
            border-bottom: 1px solid #e7e7e7;
            width: 93%;
            margin-left: 0.8vw;
            padding-left: 1vw;
            &:last-child {
              border-bottom: 0;
            }
          }
          h1 {
            font-size: 26 * $desk + vw;
            color: var(--text);
            font-weight: 500;
            margin-left: 22 * $desk + vw;
            margin-top: 10 * $desk + vw;
            margin-bottom: 0;
          }
          .manu_count {
            margin-left: 22 * $desk + vw;
            font-size: 16 * $desk + vw;
            opacity: 0.7;
          }
          .search_container {
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: var(--sidenav);
            .search_input {
              width: 100%;
              background-color: var(--sidenav);
              outline: none;
              border: 0;
              padding: 0.5vw 1.5vw;
              color: var(--text);
              &::placeholder {
                font-size: 16 * $desk + vw;
              }
            }
            .search_svg {
              position: absolute;
              top: 50%;
              transform: translateY(-35%);
              left: 4%;
              width: 1.1458333333vw;
              height: 1.1458333333vw;
              z-index: 1;
              left: unset;
              right: 0.9375vw;
              transform: scaleX(-1) translateY(-50%);
              #Ellipse_18 {
                stroke-width: 2px;
              }
              #Rectangle_668 {
                fill: #000;
                width: 2px;
                height: 6px;
              }
            }
          }
        }
      }
    }
  }
  .both_modal_adjust {
    .modalAdjust_ {
      align-self: center;
      width: 520 * $desk + vw;
      padding: 8 * $desk + vw 60 * $desk + vw;
      padding-bottom: 30 * $desk + vw;
      .continue_msg {
        text-align: center;
        margin-bottom: 40 * $desk + vw;
        font-size: 18 * $desk + vw;
      }
      ._flex {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 30 * $desk + vw;
        gap: 1vw;
        .image_wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 120 * $desk + vw;
          .hover_wrapper {
            position: relative;
            display: flex;
            transition: all 300ms ease-in-out;
            transform: scale(1);
            &:hover {
              transform: scale(1.1);
              .img_bg {
                background: linear-gradient(97deg, #013aa2, #2d62ed);
              }
              img {
                filter: grayscale(1) brightness(5);
              }
            }
          }
          .img_bg {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 12px;
            transition: all 300ms ease-in-out;
            background: var(--sidenav);
          }
          img {
            width: 100%;
            padding: 22 * $desk + vw;
            border-radius: 12px;
            box-shadow: var(--box-shadow);
            transition: all 300ms ease-in-out;
            transform: scale(1);
            cursor: pointer;
          }
          .title {
            font-family: Segoe UI;
            font-size: 18 * $desk + vw;
            font-weight: 300;
            margin-top: 10 * $desk + vw;
          }
        }
      }
    }
  }
}
