@use "sass:math";
$desk: math.div(100, 1920);
._home_wrapper_ {
  .body_section {
    background-color: var(--background) !important;
  }
  .home_body {
    padding: 27 * $desk + vw 25 * $desk + vw;
    .grid-style {
      padding-right: 0.7vw;
    }
  }
}

.registered-user {
  width: 100%;
  height: 100%;
  background-color: var(--sidenav);
  padding-right: 0 * $desk + vw;
  border-radius: 10 * $desk + vw;
  overflow: hidden;
  .heading-registered-container {
    color: var(--primaryInvert);
    background-color: var(--body-background);
    font-size: 18 * $desk + vw;
    padding: 23 * $desk + vw;
    margin-bottom: 10 * $desk + vw;
  }
  .user-container {
    padding: 22 * $desk + vw 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid var(--body-background);
    margin-right: 1.2vw;
    margin-left: 1.2vw;
  }
  .img-side {
    display: flex;
    gap: 23 * $desk + vw;
    .user-photo {
      height: 60 * $desk + vw;
      width: 60 * $desk + vw;
      border-radius: 40 * $desk + vw;
    }
    .user-name {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      .first-name {
        font-size: 16 * $desk + vw;
        font-weight: 400;
      }
      .designation {
        font-size: 12 * $desk + vw;
        color: var(--text);
        opacity: 0.6;
        font-weight: 400;
      }
    }
  }
  .calendar-side {
    height: 60 * $desk + vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .options {
      display: flex;
      justify-content: end;
      gap: 7 * $desk + vw;
      .dots {
        height: 6 * $desk + vw;
        width: 6 * $desk + vw;
        border-radius: 100%;
        background-color: var(--text);
        opacity: 0.7;
      }
    }
    .day-date {
      font-size: 12 * $desk + vw;
      color: var(--text);
      opacity: 0.6;
      margin-right: 15 * $desk + vw;
    }
  }
}

.request-user {
  // background-color: red;
  width: 100%;
  height: 100%;
  background-color: var(--sidenav);
  // padding: 23 * $desk + vw;
  // padding-right: 0 * $desk + vw;
  border-radius: 10 * $desk + vw;
  .rq-Header {
    background-color: var(--body-background);
    border-top-left-radius: 10 * $desk + vw;
    border-top-right-radius: 10 * $desk + vw;
    font-size: 18 * $desk + vw;
    padding: 12 * $desk + vw 15 * $desk + vw;
    color: var(--primaryInvert);
  }
  .requests-table {
    .userInfo {
      display: table-cell;
      text-align: left;
      .user_avatar_img {
        vertical-align: top;
        width: 45 * $desk + vw;
        height: 45 * $desk + vw;
      }
      .post {
        font-size: 14 * $desk + vw;
        opacity: 0.7;
      }
    }
    .email_ {
      .address_ {
        font-size: 18 * $desk + vw;
        display: inline-block;
      }
    }
    .request-btn {
      button {
        display: inline-block;
        border: 00;
        background-color: #3056f5;
        font-size: 12 * $desk + vw;
        color: white;
        padding: 6 * $desk + vw 23 * $desk + vw;
        margin: 0 7 * $desk + vw;
        border-radius: 12px;
        cursor: pointer;
      }
      button:nth-child(2) {
        background-color: #b9bec9;
        opacity: 1;
      }
      button:active {
        animation: press 0.2s 1 linear;
      }
    }
  }
}
