@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);


.selector_mainwrapper {
    font-size: 18 * $desk + vw !important;
    // width: 500 * $desk + vw;
    // height: 19 * $desk + vw;
    margin-top: 26 * $desk + vw;
    margin-bottom: 2.354167vw;
    display: flex;
    justify-content: center;
    gap: -0.010417vw;

    .selector_wrapper {
      font-size: 18 * $desk + vw;
      gap: 5 * $desk + vw;
      align-items: center;
      display: flex;
      width: 200 * $desk + vw;
      // justify-content: space-between;
      text-transform: capitalize;
    }
    .selector_option {
        // background-color: #013AA2 !important;

      border: 4 * $desk + vw solid #d8d6fc;
      width: 14 * $desk + vw;
      height: 14 * $desk + vw;
      border-radius: 10 * $desk + vw;
      background-color: #fff;
    }
}