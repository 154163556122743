@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);


.options_wrapper{
    .options_tag{
        position: relative;
        cursor: pointer;
        border-bottom: 1* $desk + vw solid #E7E7E7;
    padding: 20* $desk + vw;
    font-size: 16* $desk + vw;
    // text-align: center;


    }
    .options_tag:hover{
        color: #285de4;
    }

}