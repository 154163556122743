@use "sass:math";
$desk: math.div(100, 1920);

.title {
  text-align: center;
}

.custom-multi-select-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 0.9375vw;
  .dd-label {
    font-size: 15 * $desk + vw;
    color: var(--text);
    font-weight: 600;
    margin-bottom: 5px;
  }
  .selected-text {
    position: relative;
    background-color: var(--background);
    padding: 12 * $desk + vw;
    border-radius: 0.6770833333vw;
    border: 2px solid #dddddd;
    overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    cursor: pointer;
    color: var(--text);
    font-size: 14 * $desk + vw;
    font-weight: 400;
    font-family: "Work Sans";
    padding-right: 1.8vw;
    // background: transparent;
    // box-shadow: unset;
    // padding-left: 0;
    z-index: 1;
    ._text {
      overflow: hidden;
    }
  }
  .multi-checkbox {
    margin-right: 0.5vw;
    pointer-events: none;
  }
  .drop_icon {
    position: absolute;
    right: 10 * $desk + vw;
    width: 10 * $desk + vw;
    height: 10 * $desk + vw;
    transform: translateY(-50%);
    pointer-events: none;
    transform: rotate(180deg);
    top: 40%;
    transition: all 0.2s ease-in;
    path {
      stroke: var(--text);
    }
  }
  .list_open {
    transform: rotate(0deg);
  }

  .select-options {
    position: absolute;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    border-radius: 6px;
    transform: scale(0.01);
    transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s;
    transform-origin: center top;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 100%;
      width: 0;
      height: 0;
      border-style: solid;
    }
    &:after {
      top: -10px;
      border-color: transparent transparent transparent #fff;
      border-width: 5px;
      transform: rotate(270deg);
      left: 90.5%;
    }

    /* this border color controlls the outside, thin border */
    &:before {
      top: -17px;
      border-color: transparent transparent transparent #ddd;
      border-width: 10px;
      transform: rotate(270deg);
      left: 88.5%;
    }
    .custom-select-option {
      color: var(--text);
      width: 100%;
      border: 0;
      padding: 8 * $desk + vw 24 * $desk + vw;
      text-align: left;
      background-color: var(--background);
      border-bottom: 1px solid #f4f7fe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      word-break: break-all;
      overflow: hidden;
      font-size: 15 * $desk + vw;
      border-radius: 6px;
      text-transform: capitalize;
      // color: #3b3b3b;
      &:hover {
        background-color: var(--primary);
        color: #ffffff;
      }
    }
  }
  .multi-parent {
    background: var(--background);
  }
  .active__ {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transform-origin: center top;
    border: 2px solid #dddddd;
    // top: 103%;
    z-index: 5;
    // transform: translateY(0);
  }
  .active_option {
    background-color: var(--primary) !important;
    color: #ffffff !important;
  }
}
