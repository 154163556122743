@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.__device_wrapper__ {
  position: relative;
  .device_body {
    position: relative;
    // background: #f0f3ff;
    height: 100%;
    .device_bg {
      width: 100%;
      // margin-top: -0.35vw;
    }
    .__device_content {
      position: absolute;
      left: 30vw;
      /* left: 50%; */
      right: 75%;
      transform: translateX(-50%);
      width: 59.125vw;
      top: 0.1vw;
      overflow: hidden;
      .device_data {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: var(--background);
        height: 820 * $deskHeight + vh;
        border-radius: 12px;
        overflow: hidden;
        padding: 20 * $desk + vw 48 * $desk + vw;
        padding-right: 1vw;
        .device_header {
          display: flex;
          width: 100%;
          height: 64 * $desk + vw;
          justify-content: space-between;
          padding-right: 1.5vw;
          align-items: center;
          .head_left {
            font-size: 32 * $desk + vw;
            font-weight: 600;
          }
          .head_right {
            display: flex;
            gap: 30 * $desk + vw;

            .active_view {
              svg {
                path {
                  stroke: #013aa2;
                }
              }
              .flex_p {
                color: #013aa2;
              }
            }
          }
        }
        .flex_ {
          display: flex;
          align-items: center;
          cursor: pointer;
          align-self: flex-start;
          svg {
            width: 14 * $desk + vw;
            height: 14 * $desk + vw;
          }
          .flex_p {
            font-size: 12 * $desk + vw;
            margin-left: 5 * $desk + vw;
            color: #a9a9a9;
          }
          .device_count {
            font-family: Poppins;
            font-size: 16 * $desk + vw;
            font-weight: 600;
            padding: 0 8 * $desk + vw;
            background-color: var(--body-background);
            color: var(--primaryInvert);
            border-radius: 20px;
            line-height: 1.5;
          }
          .device_count_text {
            color: var(--primaryInvert);
            font-size: 16 * $desk + vw;
            margin-left: 4 * $desk + vw;
          }
        }
        .device_filter {
          display: flex;
          width: 100%;
          justify-content: space-between;
          padding-right: 1.5vw;
          margin-bottom: 22 * $desk + vw;
          .search_container {
            width: 285 * $desk + vw;
            .__input__,
            .input_style {
              margin: 0;
              svg {
                left: unset;
                right: 18 * $desk + vw;
                transform: scaleX(-1) translateY(-50%);
                #Rectangle_668 {
                  fill: var(--text);
                  width: 5px;
                  height: 6px;
                }
                #Ellipse_18,
                #Vector_7 {
                  stroke-width: 2px;
                  stroke: var(--text);
                }
              }
            }
            .input_style {
              padding-right: 2.5vw;
              background-color: var(--device-filter);
              color: var(--text);
              // background-color: #f9f9f9;
            }
          }
          .filter-options-wrapper {
            margin-top: -1.3vw;
            .filter-options {
              display: flex;
              gap: 25 * $desk + vw;
              height: 44 * $desk + vw;
              .adjust_dd {
                .selected-text {
                  padding: 8 * $desk + vw 0;
                  padding-right: 1.4vw;
                  padding-left: 0.5vw;
                  font-size: 0.7291666667vw;
                  background-color: var(--device-filter);
                  border-radius: 8px;
                }
                .marquee {
                  font-family: "Open Sans" !important;
                }
                .select-options {
                  .custom-select-option {
                    padding-right: 0;
                    padding-left: 8 * $desk + vw;
                  }
                  &:before {
                    left: 82.5%;
                  }
                  &::after {
                    left: 86.5%;
                  }
                }
                // .active__ {
                //   top: unset;
                // }
              }
              .location_search {
                display: inline-table;
                .selected_options {
                  position: relative;
                  z-index: 100;
                  display: flex;
                  border: 2px solid #dddddd;
                  align-self: flex-end;
                  height: 39 * $desk + vw;
                  padding: 0.4166666667vw 0;
                  padding-right: 1.5vw;
                  padding-left: 0.5vw;
                  width: 10vw;
                  border-radius: 0 12px 12px 0;
                  font-size: 0.7291666667vw;
                  p {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    text-transform: capitalize;
                  }
                }
                .location_search_input {
                  border: 1px solid #dddddd;
                  padding: 0.35vw 0;
                  padding-right: 1.4vw;
                  padding-left: 0.5vw;
                  border-radius: 6px;
                  outline: none;
                  background-color: var(--device-filter);
                  color: var(--text);
                  font-size: 0.7291666667vw;
                  width: 90%;
                  display: block;
                  margin: 0.5vw auto;
                }
                .suggestion_wrapper {
                  position: absolute;
                  top: 100%;
                  left: 0;
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  border-radius: 6px;
                  border-bottom-left-radius: 6px;
                  border-bottom-right-radius: 6px;
                  border: 2px solid #dddddd;
                  background-color: var(--background);
                  .suggestion {
                    position: relative;
                    z-index: 1;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    background-color: var(--background);
                    .options {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      border: 0;
                      color: var(--text);
                      padding: 8 * $desk + vw 24 * $desk + vw;
                      padding-right: 10 * $desk + vw;
                      text-align: left;
                      background-color: var(--background);
                      border-bottom: 1px solid #f4f7fe;
                      cursor: pointer;
                      text-transform: capitalize;
                      font-size: 0.8291666667vw;
                      &:last-child {
                        border-bottom: 0;
                      }
                      &:hover {
                        background-color: var(--primary);
                        color: #ffffff;
                      }
                    }
                  }
                }
              }
              .adjust_location_dd {
                .selected-text {
                  padding: 8 * $desk + vw 0;
                  padding-right: 1.4vw;
                  padding-left: 0.5vw;
                  font-size: 0.7291666667vw;
                  background-color: var(--device-filter);
                  border-radius: 8px;
                  border-right: 0;
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;
                  text-transform: capitalize;
                }
                .marquee {
                  font-family: "Open Sans" !important;
                }
                .select-options {
                  .custom-select-option {
                    padding-right: 0;
                    padding-left: 8 * $desk + vw;
                  }
                  &:before {
                    left: 82.5%;
                  }
                  &::after {
                    left: 86.5%;
                  }
                }
              }
            }
          }

          .selected_filter {
            margin-top: 40 * $desk + vw;
            display: flex;
            flex-wrap: wrap;
            gap: 15 * $desk + vw;
            width: 33vw;
            .filter_tab {
              align-items: center;
              background: var(--device-filter);
              border-radius: 2px;
              color: var(--text);
              display: flex;
              list-style: none;
              // margin-right: 40 * $desk + vw;
              border-radius: 20 * $desk + vw;
              padding-left: 9 * $desk + vw;
              padding-top: 4 * $desk + vw;
              padding-bottom: 4 * $desk + vw;
              padding-right: 8 * $desk + vw;
              border: 1px solid #e7e7e7;
              // margin-bottom: 10 * $desk + vw;
              p {
                font-size: 12 * $desk + vw;
                margin-right: 20 * $desk + vw;
                font-weight: 600;
              }
              svg {
                width: 20 * $desk + vw;
                height: 20 * $desk + vw;
                cursor: pointer;
              }

              .closeIcon {
                display: none;
                background: linear-gradient(
                  321deg,
                  rgba(221, 30, 66, 1) 5%,
                  rgba(162, 0, 0, 1) 73%
                );
                padding: 0.17vw;
                border-radius: 100%;
                width: 1vw;
                height: 1vw;
                border: 1px solid var(--text);
              }
              &:hover {
                .tickIcon2 {
                  display: none;
                }
                .closeIcon {
                  display: block;
                }
              }
            }
          }
        }
        .device_data_container {
          display: grid;
          --auto-grid-min-size: 11vw;
          // grid-template-columns: repeat(
          //   auto-fill,
          //   minmax(var(--auto-grid-min-size), 1fr)
          // );
          // grid-template-columns: repeat(5, 1fr);
          grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
          grid-gap: 30 * $desk + vw;
          padding-right: 1.3vw;
          // padding-bottom: 6vw;
          // margin-top: 20 * $desk + vw;
          // margin-bottom: 30 * $desk + vw;
          // padding-right: 1vw;
          .add_device {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            background-color: var(--sidenav);
            border-radius: 12px;
            p {
              user-select: none;
              font-size: 14 * $desk + vw;
              margin-top: 20 * $desk + vw;
              font-weight: 500;
              color: #111b60;
            }
            .addDevice {
              width: 60 * $desk + vw;
              height: 60 * $desk + vw;
              cursor: pointer;
            }
            .addDevice:active {
              animation: press 0.2s 1 linear;
            }
          }
        }
        .noData {
          width: 100%;
          height: 65%;
          display: grid;
          place-content: center;
          font-size: 32 * $desk + vw;
          font-weight: bold;
        }
        .device_bottom {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 94%;
          background: rgb(255, 255, 255);
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0.6783088235294117) 100%
          );
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.7vw 0;
          // .pagination_wrapper {
          //   width: 215px;
          //   height: 35px;
          //   li:not(.active, .previous) {
          //     font-size: 14px;
          //   }
          //   .active {
          //     font-size: 14px;
          //   }
          // }
          .btn {
            width: 200px;
            font-size: 15px;
            padding-top: 13px;
            padding-bottom: 13px;
            margin: 0;
          }
        }
      }
    }
  }
  .modalAdjust_ {
    position: relative;
    width: 560 * $desk + vw;
    padding: 20 * $desk + vw 92 * $desk + vw;
    padding-bottom: 20 * $desk + vw;
  }
  .modal_head {
    font-size: 20 * $desk + vw;
    font-weight: normal;
    text-align: center;
    padding-top: 20 * $desk + vw;
    padding-bottom: 45 * $desk + vw;
  }
  .skeleton {
    background: #fff;
    border: 1px solid;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    border-radius: 15px;
    box-shadow: var(--dd-box-shadow);
    background: var(--background);
    // min-height: 250 * $desk + vw;

    &.skeleton--card {
      width: 100%;
      display: inline-block;
      vertical-align: text-top;
      overflow: hidden;
    }
    .skeleton--content {
      height: 100%;
      padding: 7 * $desk + vw;
      position: relative;
      overflow: hidden;
      .skeleton--title {
        height: 100%;
        width: 100%;
      }
      .skeleton--content-wrapper {
        height: 75%;
      }
      .skeleton--content-wrapper2 {
        height: 24%;
      }

      .skeleton--hr {
        height: 2 * $desk + vw;
        width: calc(100% - 1vw);
        margin: 0 10 * $desk + vw 10 * $desk + vw;
      }
      .skeleton--line {
        height: 10px;
        // width: calc(100% - 20px);
        width: 100%;
        margin: 10 * $desk + vw 0;
      }
    }
    .s_loader {
      background-color: var(--card-bg) !important;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeholderSkeleton;
      animation-timing-function: linear;
      background-image: -webkit-linear-gradient(
        left,
        var(--card-bg) 0%,
        var(--card-bg2) 20%,
        var(--card-bg) 40%,
        var(--card-bg) 100%
      );
      background-repeat: no-repeat;
      height: 100%;
      position: relative;
    }
    @keyframes placeholderSkeleton {
      0% {
        background-position: -100vw 0;
      }
      100% {
        background-position: 100vw 0;
      }
    }
  }
}

.device_list_container {
  width: 100%;
  .device_list_wrapper {
    width: 98%;
    border: 3px solid #dae4f8;
    margin-bottom: 34 * $desk + vw;
    padding: 8 * $desk + vw 32 * $desk + vw 8 * $desk + vw 20 * $desk + vw;
    border-radius: 6px;
    display: flex;
    align-items: center;

    .d_name_leftwrapper {
      width: 17vw;
      .device_name_wrapper {
        display: flex;
        align-items: flex-start;

        .deviceIcon_wrapper {
          position: relative;
          display: grid;
          place-items: center;
          // width: 82 * $desk + vw;
          // height: 82 * $desk + vw;
          background-color: var(--device-card);
          border: 3 * $desk + vw solid #e1e1e1;
          border-radius: 60%;
          padding: 0.7vw;
          // margin-bottom: 15 * $desk + vw;

          .deviceIcon {
            width: 50 * $desk + vw;
            height: 50 * $desk + vw;
            fill: var(--text);
            line {
              stroke: var(--text);
            }
          }
          .activeIcon,
          .inactive_icon {
            position: absolute;
            bottom: 4 * $desk + vw;
            width: 1vw;
            height: 1vw;
            left: 4 * $desk + vw;
          }
        }

        .device_status_wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          margin-left: 24 * $desk + vw;
          // height: 4.2708333333vw;
          .device_status {
            border: 1px solid #008000;
            font-size: 12 * $desk + vw;
            padding: 0.04vw 0.4vw;
            border-radius: 6px;
            color: #008000;
            display: inline-block;
          }

          .device_user_name {
            color: var(--text);
            cursor: pointer;
            font-size: 22 * $desk + vw;
            font-weight: 600;
            display: flex;
            align-items: center;
            text-transform: capitalize;
            // padding: 8 * $desk + vw 0 6 * $desk + vw 0;
            margin: 0.5vw 0;
            width: 7vw;
            white-space: nowrap;
            // overflow: hidden;
            text-overflow: ellipsis;
            flex-direction: column;
            align-items: flex-start;
            .device_username {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 8vw;
            }
            .status_holder {
              // font-size: 10 * $desk + vw;
              // color: #008000;
              // background-color: #00800026;
              // border-radius: 40px;
              // padding: 0.08vw 0.4vw;
              // margin-left: 0.4vw;
              // line-height: 1;
            }

            .status_holder_inactive {
              background-color: #fff1cd;
              color: #dcaa3d;
            }
          }

          .device_location {
            color: #757575;
            font-size: 15 * $desk + vw;
            line-height: 1;
            svg {
              margin-right: 8 * $desk + vw;
            }
          }
        }
      }

      .filter_wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 8 * $desk + vw;
        width: 100%;
        gap: 12 * $desk + vw;
        .tags {
          color: #dae4f8;
          padding: 2 * $desk + vw 4 * $desk + vw;
          border-radius: 3px;
          // margin-left: 12 * $desk + vw;
          display: inline-block;
          white-space: nowrap;
          max-width: 150 * $desk + vw;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // text-align: center;
          font-size: 12 * $desk + vw;
        }
      }
    }

    .device_info_wrapper {
      display: flex;
      // flex-wrap: wrap;
      gap: 40 * $desk + vw;
      margin-left: 40 * $desk + vw;
      width: 100%;
      .info_wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        .device_info {
          margin-bottom: 18 * $desk + vw;
          // width: 270 * $desk + vw;
          display: inline-block;
          .d_label {
            font-size: 14 * $desk + vw;
            color: #848484;
            margin-bottom: 4 * $desk + vw;
          }

          .value_wrapper {
            font-size: 14 * $desk + vw;
            background-color: var(--sidenav);
            padding: 4 * $desk + vw 15 * $desk + vw;
            // display: flex;
            // align-items: center;
            border-radius: 4px;
            display: inline-block;
            position: relative;
            .d_value {
              overflow: hidden;
            }
            .copy_icon {
              // margin: 0.2vw 0 0 0 * $desk + vw;
              cursor: pointer;

              position: absolute;
              right: 4 * $desk + vw;
              bottom: -2 * $desk + vw;
              svg {
                width: 1vw;
                height: 1vw;
              }
              &:active {
                animation: press 0.2s 1 linear;
              }
            }
          }

          .beta_toggle {
            margin: 0.4vw 0 0 0.6vw;
          }
          &:nth-child(2) {
            margin-bottom: 0;
          }
        }

        .beta_info {
          display: flex;
          align-items: center;
          // width: 15vw;
          margin-left: 0.6vw;

          .beta_labels {
            display: flex;
            align-items: center;
            width: 124 * $desk + vw;
            background-color: var(--sidenav);
            justify-content: space-evenly;
            margin: 1.4vw 0.4vw 0 0;
            font-size: 10.8 * $desk + vw;
            padding: 4 * $desk + vw 15 * $desk + vw;
            border-radius: 6px;
            user-select: none;
            cursor: pointer;
            .log_icon {
              margin-top: 0.1vw;
            }
            &:active {
              animation: press 0.2s 1 linear;
            }
          }
        }

        .license_info {
          display: inline-block;
          width: 18vw;
          position: relative;
          .value_wrapper {
            display: inline-block;
            position: relative;
          }
          .renew_btn {
            position: absolute;
            // top: 00.2vw;
            bottom: 0;
            left: 102%;
            background-color: #285de4;
            color: #dae4f8;
            border-radius: 4px;
            border: none;
            padding: 2 * $desk + vw 8 * $desk + vw;
            cursor: pointer;
            font-size: 13 * $desk + vw;
            min-width: 6vw;
            &:disabled {
              opacity: 0.4;
              cursor: not-allowed;
            }
            &:active {
              animation: press 0.2s 1 linear;
            }
          }
        }
      }
    }
  }
}

.add_licence_modal {
  .modalAdjust_ {
    width: 1200 * $desk + vw;
    // min-height: 650 * $desk + vw;
    height: 650 * $desk + vw;
    padding: 0vw;
    padding-top: 60 * $desk + vw;
    overflow: hidden;

    .inputs {
      display: grid;
      position: relative;
      // flex: 2;
      // padding: 1vw;
      // border: 2px solid #ddd;
      // border-radius: 8px;
      align-self: normal;
      .btn {
        position: relative;
        bottom: 0;
        margin: 0;
      }
    }
    .header {
      font-size: 18 * $desk + vw;
      text-align: center;
      margin: 0.3vw 0;
    }
    .s_wrapper {
      display: grid;
      --auto-grid-min-size: 11vw;
      grid-template-columns: repeat(auto-fit, minmax(280 * $desk + vw, 1fr));
      grid-gap: 30 * $desk + vw;
      height: 100%;
      gap: 3vw;
      // flex-wrap: wrap;
      padding: 1vw 2vw;
      // border: 2px solid #ddd;
      // border-radius: 8px;
      // padding-top: 60 * $desk + vw;
    }

    .loading_wrapper {
    }

    .flex_container {
      width: 100%;
      height: 100%;
      display: flex;
      transition: 1s ease;
      .sidebar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 0;
        opacity: 0;
        transition: 1s ease;
        overflow: hidden;
        border: 2px solid #ddd;
        margin-bottom: 1vw;
        padding: 0vw;
        padding-top: 0.4vw;
        .save_cancel {
          display: flex;
          justify-content: space-evenly;
          .btn {
            padding: 0.2vw 0;
            font-size: 0.7vw;
            width: 5vw;
          }
        }
        .inputs {
          .markup_wrapper {
            display: grid;
            place-content: center;
            position: absolute;
            width: 50%;
            height: 59%;
            background-color: #ddd;
            border-radius: 0 6px 6px 0;
            bottom: 0;
            right: 2px;
            font-size: 0.6770833333vw;
            color: var(--primary);
          }
          .__input__ {
            margin-bottom: 0.7vw;
            width: 98%;
            .input_style {
              margin-top: 0.11vw;
              padding: 0.2208333333vw;
              border-radius: 6px;
            }
            label {
              text-transform: capitalize;
              white-space: nowrap;
              font-size: 0.6125vw;
            }
          }
        }
      }
      .sidebar_open {
        width: 30%;
        opacity: 1;
        padding: 1vw;
        border: 2px solid #ddd;
        border-radius: 8px;
        margin-left: 1vw;
        margin-bottom: 1vw;
        padding-top: 0.4vw;
        .header {
          margin-bottom: 1vw;
          white-space: nowrap;
        }
      }
      .mainContent_small {
        width: 100%;
      }
      .mainContent {
        width: 100%;
        .off {
          display: none;
        }
        .preview_wrapper {
          display: grid;
          --auto-grid-min-size: 11vw;
          // grid-template-columns: repeat(
          //   auto-fill,
          //   minmax(var(--auto-grid-min-size), 1fr)
          // );
          // grid-template-columns: repeat(5, 1fr);
          grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
          grid-gap: 30 * $desk + vw;
          // display: flex;
          padding: 1vw;
          padding-top: 0;
          // margin: 0 1vw 1vw 1vw;
          // gap: 1.2vw;

          .licence_preview_wrapper {
            position: relative;
            flex: 1;
            border: 2px solid #ddd;
            border-radius: 8px;
            padding: 0.5vw;
            padding-top: 2vw;
            .deleteIcon {
              position: absolute;
              top: 0.3vw;
              right: 1.7vw;
              cursor: pointer;
              width: 1vw;
              path {
                fill: var(--primaryInvert);
              }
              &:active {
                animation: press 0.2s 1 linear;
              }
            }
            .editIcon {
              position: absolute;
              top: 0.3vw;
              width: 1vw;
              right: 0.3vw;
              height: 1vw;
              cursor: pointer;
              path {
                fill: var(--primaryInvert);
              }
              &:active {
                animation: press 0.2s 1 linear;
              }
            }
            .licence_card {
              position: relative;
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid #ddd;
              margin-bottom: 0.3vw;
              .l_header {
                text-transform: capitalize;
                font-weight: 600;
                font-size: 18 * $desk + vw;
              }
              .l_data {
                font-weight: 400;
                font-size: 16 * $desk + vw;
                max-width: 60%;
              }

              &:last-child {
                margin-bottom: 0;
                border-bottom: 0;
              }
            }
          }

          // .licence_input {
          //   .__input__ {
          //     label {
          //       text-transform: capitalize;
          //     }
          //   }
          // }
        }
      }
      .no_licence {
        display: grid;
        place-content: center;
        height: 28vw;
        width: 100%;
        svg {
          position: relative;
          left: 2vw;
          width: 100%;
          height: 28vw;
        }
        .btn {
          position: absolute;
          bottom: 2vw;
          left: 40%;
          margin: 0;
          width: 12vw;
        }
      }
    }
  }
}

.upgrade_licence_modal {
  .modalAdjust_ {
    width: 1200 * $desk + vw;
    height: 800 * $desk + vw;
    padding: 00.5vw;
    padding-top: 60 * $desk + vw;
    overflow: hidden;
    .s_wrapper {
      display: grid;
      // --auto-grid-min-size: 11vw;
      // grid-template-columns: repeat(auto-fit, minmax(280 * $desk + vw, 1fr));
      // grid-gap: 30 * $desk + vw;
      height: 100%;
      gap: 2vw;
      // flex-wrap: wrap;
      padding: 1vw 2vw;
      // border: 2px solid #ddd;
      // border-radius: 8px;
      // padding-top: 60 * $desk + vw;
      .skeleton--content-wrapper {
        height: 100%;
      }
      // .skeleton--card {
      //   min-height: 3vw;
      // }
    }
    .adjust_dd {
      text-transform: capitalize;
      width: 20%;
      height: unset;
      margin-left: 0.25vw;
      margin-top: -2vw;
      .selected-text {
        // background-color: white;
        // color: #000;
        padding: 0.3vw 0.8333333333vw;
        border-radius: 0.6770833333vw;
        text-transform: capitalize;
      }
      button {
        height: 44 * $desk + vw !important;
      }
    }
    .data_table {
      border-collapse: separate;
      border-spacing: 0 9 * $desk + vw;
      width: 100%;
      padding-right: 1vw;
      padding-left: 1vw;
      td {
        border-bottom: 1px solid #f0f0f0;
        // border-top: 1px solid #f0f0f0;
        padding-top: 15 * $desk + vw;
        padding-bottom: 15 * $desk + vw;
        font-size: 15 * $desk + vw;
      }
      tr {
        &:last-child {
          td {
            border-bottom: 0;
          }
        }
      }
      .adjust_info {
        display: flex;
        align-items: center;
      }

      tr {
        border-radius: 35px;
        border: 2px solid red;
        // background-color: red;
      }
      td {
        padding: 20 * $desk + vw 15 * $desk + vw;
        text-align: center;
        vertical-align: middle;
        // font-weight: 300;
        font-size: 18 * $desk + vw;
        // color: #fff;
        svg {
          display: inline-block;
          width: 30 * $desk + vw !important;
          height: 30 * $desk + vw !important;
          vertical-align: top;
          margin-right: 12 * $desk + vw;
        }
      }
      .sr {
        max-width: 6vw;
        font-size: 20 * $desk + vw;
        font-weight: 500;
        font-style: italic;
      }
      .plans {
        display: flex;
        gap: 1vw;
        justify-content: space-evenly;
        .plan-block {
          background: var(--sidenav);
          border-radius: 6px;
          text-align: center;
          box-shadow: var(--dd-box-shadow);
          // cursor: pointer;
          // padding: 0.5vw;
          .plan_title {
            background-color: var(--primary);
            color: #fff;
            border-radius: 6px;
            border: 1px solid var(--primary);
            border-bottom: 4px solid var(--secondary);
            font-size: 16 * $desk + vw;
            line-height: 35 * $desk + vw;
            text-transform: capitalize;
          }
          .plan_price {
            line-height: 40 * $desk + vw;
            font-size: 20 * $desk + vw;
            font-weight: bold;
          }
          .plan_volumes {
            padding: 0 0.5vw;
            margin-top: 0.5vw;
          }
        }
      }
    }
    .price-block {
      font-size: 16 * $desk + vw;
      text-align: end;
      font-weight: 500;
      padding-right: 2vw;
      margin-top: 0.5vw;
      span {
        color: var(--primaryInvert);
        font-size: 18 * $desk + vw;
        font-weight: bold;
      }
    }
  }
}
