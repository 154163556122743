@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);
.slider_card_wrapper {
    position: relative;
    width: 91.2%;
    height: 140 * $desk + vw;
    border-radius: 8px;
    overflow: hidden;
    .slider_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .slider_name {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-family: Segoe UI;
      font-size: 25 * $desk + vw;
      font-weight: bold;
      color: #fff;
    }
  }