@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.otp_modal_adjust {
  .modalAdjust_ {
    padding: 0;
    width: unset;
  }
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   height: 100%;
  //   width: 100%;
  //   backdrop-filter: blur(10px);
  //   background-color: rgba(0, 0, 0, 0.6);
  //   display: flex;
  //   justify-content: center;
  //   z-index: 100;
  .pass_container {
    width: 530 * $desk + vw;
    height: 670 * $desk + vw;
    padding: 63 * $desk + vw;
    padding-top: 30 * $desk + vw !important;
    background: #fff;
  }
  .reg_success_container {
    position: relative;
    overflow: hidden;
    width: 530 * $desk + vw;
    height: 420 * $deskHeight + vh;
    padding: 63 * $desk + vw;
    padding-top: 73 * $desk + vw !important;
    padding-bottom: 0 * $desk + vw !important;
    background: #fff;
    .reg_head {
      text-align: center;
      font-size: 17 * $desk + vw;
      color: #013aa2;
      margin-top: 12 * $desk + vw;
      font-family: "Rubik";
    }
    .otp_desc {
      padding: 0;
      padding-top: 50 * $desk + vw;
      // padding: 0 90 * $desk + vw;
    }
    svg {
      position: relative;
      z-index: 5;
    }
    .firework,
    .firework::before,
    .firework::after {
      --top: 60vh;
      content: "";
      position: absolute;
      top: 33%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0.5vmin;
      aspect-ratio: 1;
      background:
    /* random backgrounds */ radial-gradient(
            circle,
            #e0e0e0 0.2vmin,
            #0000 0
          )
          50% 00%,
        radial-gradient(circle, #013aa2 0.3vmin, #0000 0) 00% 50%,
        radial-gradient(circle, #e0e0e0 0.5vmin, #0000 0) 50% 99%,
        radial-gradient(circle, #013aa2 0.2vmin, #0000 0) 99% 50%,
        radial-gradient(circle, #e0e0e0 0.3vmin, #0000 0) 80% 90%,
        radial-gradient(circle, #013aa2 0.5vmin, #0000 0) 95% 90%,
        radial-gradient(circle, #e0e0e0 0.5vmin, #0000 0) 10% 60%,
        radial-gradient(circle, #013aa2 0.2vmin, #0000 0) 31% 80%,
        radial-gradient(circle, #013aa2 0.3vmin, #0000 0) 80% 10%,
        radial-gradient(circle, #e0e0e0 0.2vmin, #0000 0) 90% 23%,
        radial-gradient(circle, #013aa2 0.3vmin, #0000 0) 45% 20%,
        radial-gradient(circle, #e0e0e0 0.5vmin, #0000 0) 13% 24%;
      background-size: 0.5vmin 0.5vmin;
      background-repeat: no-repeat;
      animation: firework 2s infinite;
    }
    .firework::before {
      transform: translate(-50%, -50%) rotate(25deg) !important;
    }

    .firework::after {
      transform: translate(-50%, -50%) rotate(-37deg) !important;
    }

    @keyframes firework {
      0% {
        transform: translate(-50%, 0vh);
        width: 0.5vmin;
        opacity: 1;
      }
      50% {
        width: 0.5vmin;
        opacity: 1;
      }
      100% {
        width: 45vmin;
        opacity: 0;
      }
    }
    .res_success_btn {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 40 * $desk + vw;
      .btn {
        width: 160 * $desk + vw;
        font-size: 14 * $desk + vw;
        padding: 12 * $desk + vw 10 * $desk + vw;
      }
    }
  }
}
