@use "sass:math";
$desk: math.div(100, 1920);

.reset_password_modal {
  .modalAdjust_ {
    position: relative;
    width: 29.1666666667vw;
    padding: 1.0416666667vw 4.7916666667vw;
    padding-bottom: 1.0416666667vw;

    .modal_head {
      font-size: 20 * $desk + vw;
      font-weight: normal;
      text-align: center;
      padding-top: 20 * $desk + vw;
      padding-bottom: 45 * $desk + vw;
    }

    .modal_sub {
      font-size: 18 * $desk + vw;
      font-weight: normal;
      text-align: center;
      padding-bottom: 45 * $desk + vw;
      color: #002465;
    }
    .recaptcha_login {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .res_success_btn {
      margin: 45 * $desk + vw 0;
      text-align: center;
    }

    .btn {
      width: 90%;
    }

    .dropdown {
      margin-top: 0.4vw;
      position: relative;

      p:first-child {
        padding-bottom: 0.5vw;
        padding-left: 0.5vw;
      }

      .dropdown-content {
        background-color: lightyellow;
        width: 100%;
        overflow: auto;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        padding: 0.5vw;
        border-radius: 1vw;
        font-size: 12 * $desk + vw;
        position: absolute;
        top: 50%;
        z-index: 8;

        /* Add a red text color and an "x" when the requirements are wrong */
        .invalid {
          color: red;
        }

        .invalid:before {
          margin: 0.2vw 1vw;
          content: "✖";
        }

        /* Add a green text color and a checkmark when the requirements are right */
        .valid {
          color: green;
        }

        .valid:before {
          margin: 0.2vw 1vw;
          content: "✔";
        }
      }
    }
  }
}
