@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

._license_wrapper_ {
  position: relative;

  .license_body {
    position: relative;
    height: 100%;

    .profile_bg {
      width: 100%;
    }
  }
  .body_content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 1500 * $desk + vw;
    top: 75 * $desk + vw;

    h1 {
      color: #fff;
      margin-bottom: 15 * $desk + vw;
      font-size: 32 * $desk + vw;
      letter-spacing: 2px;
    }
    .license_data {
      width: 100%;
      background-color: var(--background);
      height: 69.4444444444vh;
      border-radius: 12px;
      overflow: hidden;
      border: 2px solid #e7e7e7;
      padding: 1vw;
      padding-left: 2.2vw;

      .add_plan_btn {
        display: flex;
        justify-content: space-between;
        height: 2.6vw;
        margin-bottom: 0.5vw;
        .search_container {
          width: 285 * $desk + vw;
          // margin-top: 1.3vw;

          .__input__,
          .input_style {
            margin: 0;

            svg {
              left: unset;
              right: 18 * $desk + vw;
              transform: scaleX(-1) translateY(-50%);

              #Rectangle_668 {
                fill: var(--text);
                width: 5px;
                height: 6px;
              }

              #Ellipse_18,
              #Vector_7 {
                stroke-width: 2px;
                stroke: var(--text);
              }
            }
          }

          .input_style {
            padding-right: 2.5vw;
            background-color: var(--device-filter);
            color: var(--text);
            // background-color: #f9f9f9;
          }
        }

        .btn {
          width: 12vw;
          font-weight: 600;
          margin-bottom: 1vw;
          margin-right: 1.5vw;
          padding: 0.2vw;
          height: 2.1vw;
        }
      }
    }
  }
  .license_modal {
    .modalAdjust_ {
      width: 876 * $desk + vw;
      height: 570 * $desk + vw;
      background-color: var(--background);
      padding: 32 * $desk + vw 0;
    }
    .license_modal_wrapper {
      .__input__ {
        width: 21.5vw;
      }
      .__input__ .input_style {
        width: 20vw;
        height: 2.5vw;
        margin-left: 0.8vw;
        border-radius: 3px;
        box-shadow: 0px 1px 10px 4px rgb(0 0 0 / 7%);
        border: none;
        font-size: 1vw;
        font-style: normal;
        font-weight: 500;
        color: var(--primary);
      }
      table {
        // border-collapse: collapse;
        font-size: 1vw;
        border-collapse: separate;
        border-spacing: 0 15px;
        width: 95%;
        margin: 0 auto;
        border-radius: 10px;
        .attribute_row {
          position: relative;
          height: 5vh;
          border-radius: 8px;
          box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.06);
          .center {
            background: var(--background);
            cursor: pointer;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              -3px 2px 6px rgba(0, 0, 0, 0.08);
            width: 1.5vw;
            height: 1.5vw;
            font-size: 0.8vw;
            text-align: center;
            color: var(--text);
            font-weight: 700;
            border-radius: 50%;
            position: absolute;
            right: -0.5vw;
            top: -0.5vw;
            display: grid;
            place-items: center;
          }
        }
        th {
          text-transform: capitalize;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          color: #0000004d;
          // color: var(--text);
        }
        td {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          text-align: center;
          padding: 8px;
        }
        input {
          text-align: center;
          font-size: 1vw;
          width: 5vw;
          outline: none;
          border: none;
          background-color: var(--background);
          color: var(--text);
        }
      }
      #add_row {
        margin: 0;
        padding: 1.5vh;
        background-color: #0433981a;
        color: #00000066;
        width: 95%;
        margin-left: 1vw;
        border: none;
      }
      #save_btn {
        width: 9vw;
        padding: 0.4vw;
      }
    }
  }
  ._edit_license_modal {
    .modalAdjust_ {
      width: 876 * $desk + vw;
      height: 570 * $desk + vw;
      background-color: var(--background);
      padding: 32 * $desk + vw 0;
    }

    .edit_license_modal_wrapper {
      .__input__ {
        width: 21.5vw;
        // margin-bottom: 0;
      }

      .__input__ .input_style {
        text-transform: capitalize;
        width: 20vw;
        height: 2.5vw;
        margin-left: 0.8vw;
        border-radius: 3px;
        box-shadow: 0px 1px 10px 4px rgb(0 0 0 / 7%);
        border: none;
        font-size: 1vw;
        font-style: normal;
        font-weight: 500;
        color: var(--primary);
        background-color: #fff;
      }

      table {
        // border-collapse: collapse;
        font-size: 1vw;
        border-collapse: separate;
        border-spacing: 0 15px;
        width: 95%;
        margin: 0 auto;
        border-radius: 10px;
        // margin-top: 1vw;

        .attribute_row {
          position: relative;
          height: 5vh;
          border-radius: 8px;
          box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.06);

          .center {
            background: var(--background);
            cursor: pointer;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              -3px 2px 6px rgba(0, 0, 0, 0.08);
            width: 1.5vw;
            height: 1.5vw;
            font-size: 0.8vw;
            text-align: center;
            color: var(--text);
            font-weight: 700;
            border-radius: 50%;
            position: absolute;
            right: -0.5vw;
            top: -0.5vw;
            display: grid;
            place-items: center;
          }
        }

        th {
          text-transform: capitalize;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          color: #0000004d;
          // color: var(--text);
        }

        td {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          text-align: center;
          padding: 8px;
        }

        input {
          text-align: center;
          font-size: 1vw;
          width: 6vw;
          outline: none;
          border: none;
          background-color: var(--background);
          color: var(--text);
        }
      }

      #add_row {
        margin: 0;
        padding: 1.5vh;
        background-color: #0433981a;
        color: #00000066;
        width: 95%;
        margin-left: 1vw;
      }

      #save_btn {
        width: 9vw;
        padding: 0.4vw;
      }
    }
  }
  .markup_modal {
    .modalAdjust_ {
      width: 876 * $desk + vw;
      height: 530 * $desk + vw;
      background-color: var(--background);
      padding: 32 * $desk + vw 0;
    }
    .markup_modal_wrapper {
      .__input__ {
        width: 21.5vw;
        margin-bottom: 0;
      }

      .__input__ .input_style {
        text-transform: capitalize;
        width: 20vw;
        height: 2.5vw;
        margin-left: 0.8vw;
        border-radius: 3px;
        box-shadow: 0px 1px 10px 4px rgb(0 0 0 / 7%);
        border: none;
        font-size: 1vw;
        font-style: normal;
        font-weight: 500;
        color: var(--primary);
        background-color: #fff;
      }
      table {
        // border-collapse: collapse;
        font-size: 0.9vw;
        border-collapse: separate;
        border-spacing: 0 15px;
        width: 95%;
        margin: 0 auto;
        border-radius: 10px;

        .attribute_row {
          position: relative;
          height: 5vh;
          border-radius: 8px;
          transition: height 0.3s;
          // transition: height 0.3s linear;
          box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.06);

          .center {
            background: var(--background);
            cursor: pointer;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
              -3px 2px 6px rgba(0, 0, 0, 0.08);
            width: 1.5vw;
            height: 1.5vw;
            font-size: 0.8vw;
            text-align: center;
            color: var(--text);
            font-weight: 700;
            border-radius: 50%;
            position: absolute;
            right: -0.5vw;
            top: -0.5vw;
            display: grid;
            place-items: center;
          }
        }

        th {
          text-transform: capitalize;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          color: #0000004d;
          // color: var(--text);
          transition: 0.7s;
        }

        td {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 1vw;
          color: #323232b2;
          text-align: center;
          // padding: 8px;
          .btn {
            transition: height 5s linear;
          }
        }
        .markup_input_ {
          // width: 1.7vw;
          font-size: 1vw;
          height: 1.665vw;
          margin-left: 0.6vw;
          border-bottom: 1px solid var(--primary);
          border-top: 1px solid var(--primary);
          border-left: 1px solid var(--primary);
          border-radius: 4px 0 0 4px;
        }
        .percentage_para_ {
          border-bottom: 1px solid var(--primary);
          border-top: 1px solid var(--primary);
          border-right: 1px solid var(--primary);
          width: 0.8vw;
          height: 1.665vw;
          font-size: 0.7vw;
          display: flex;
          align-items: center;
          border-radius: 0 4px 4px 0;
        }

        input {
          text-align: center;
          font-size: 1vw;
          width: 5vw;
          outline: none;
          border: none;
          background-color: var(--background);
          color: var(--text);
        }
      }
      #save_btn {
        width: 9vw;
        padding: 0.4vw;
      }
    }
  }
  .assign_modal {
    .modalAdjust_ {
      width: 876 * $desk + vw;
      height: 555 * $desk + vw;
      background-color: var(--background);
      padding: 32 * $desk + vw;
    }

    .assign_mfr_wrapper {
      .assign_label {
        font-size: 24 * $desk + vw;
        font-weight: 600;
        padding-bottom: 0.8vw;
      }

      .input_style {
        padding-left: 2.6vw;
      }

      .search_input_ {
        width: 89%;
      }

      .autocomplete_results {
        position: absolute;
        left: 1.7vw;
        width: 42.2vw;
        z-index: 99;
        font-size: 16 * $desk + vw;
        list-style: none;
        background: var(--background);
        border: 1px solid var(--color-border-default);
        border-radius: 6px;
        box-shadow: 0 3px 6px #010409;

        .options {
          padding: 0.5vw;
          cursor: pointer;

          &:hover {
            background-color: var(--primary);
            color: #fff;
          }

          &:nth-of-type(1) {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;

            &:hover {
              border-top-left-radius: 6px;
              border-top-right-radius: 6px;
            }
          }

          &:last-child {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;

            &:hover {
              border-bottom-left-radius: 6px;
            }
          }
        }

        .active_flag {
          background-color: var(--primary);
          color: #fff;
        }
      }

      .__input__ {
        svg {
          left: 2%;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .search_wrapper_ {
        margin-bottom: 0.2vw;
        border-radius: 6px;

        .autocomplete_results {
          box-shadow: rgb(0 0 0 / 15%) 0px 3px 3px 0px !important;
        }
      }

      .mfr_table {
        margin-top: 0.6vw;
        .input_style {
          padding-left: 1.2vw;
          border-radius: 4px;
        }

        #mfr {
          border: none;
          border-collapse: collapse;
          width: 100%;
          background: #fafafa;
          box-shadow: inset 0px 0px 6px rgb(0 0 0 / 15%);
          border-radius: 8px;
        }
        #mfr tr:nth-child(even) {
          box-shadow: inset 0px 0px 6px rgb(0 0 0 / 15%);
          background-color: #ffffff;
          // border-radius: 0 0 8px 8px;
        }
        #mfr td,
        #mfr th {
          padding: 8px;
          color: var(--text);
          font-weight: normal;
          opacity: 0.4;
          font-size: 14 * $desk + vw;
          text-align: center;
        }

        #mfr td {
          opacity: 1;
          color: #00000080;
          font-weight: 500;
          font-size: 18 * $desk + vw;
          margin-bottom: -1.2vw;
          text-transform: capitalize;
        }

        #mfr th {
          padding-top: 0 * $desk + vw;
          padding-bottom: 0 * $desk + vw;
        }

        .no_license_td {
          display: flex;
          justify-content: center;

          .no_license {
            width: 6vw;
          }
        }

        .mfr_toggle {
          display: flex;
          justify-content: center;
        }

        .del_mfr_icon {
          fill: red;
          cursor: pointer;
        }
      }

      .no_data_found {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20 * $desk + vw;
      }

      .remaining_count {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        left: 2%;
        font-weight: 600;
        font-size: 0.2vw;
        bottom: 2%;
      }

      .mfr_btns {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        right: 3%;
        bottom: 2%;

        .btn {
          padding: 0.2vw;
          width: 4vw;
          border: 1px solid #043398;
        }
      }
    }
  }
}

.empty_license_screen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .empty_license_para {
    font-size: 42 * $desk + vw;
    font-weight: 500;
    font-family: "Inter";
    font-style: normal;
    color: #367bc080;
  }
  .empty_license_subpara {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18 * $desk + vw;
    color: #367bc0b2;
    cursor: pointer;
    text-decoration: underline;
  }
}

.license_list_container {
  width: 100%;

  .license_list_wrapper {
    width: 98%;
    border: 3px solid #dae4f8;
    margin-bottom: 34 * $desk + vw;
    padding: 8 * $desk + vw 32 * $desk + vw 8 * $desk + vw 20 * $desk + vw;
    border-radius: 6px;
    display: flex;
    gap: 2vw;
    align-items: center;
    height: 12vw;

    .license_image {
      width: 12vw;
      height: 10vw;
      border-radius: 5px;
    }

    .license_info {
      display: flex;
      flex-direction: column;
      gap: 0.8vw;
      width: 20vw;
      .toggle_div {
        display: flex;
        gap: 1vw;
        .license_para {
          font-size: 18 * $desk + vw;
          color: var(--primary);
          font-weight: 500;
        }
        .license_toggle {
          display: flex;
          align-items: center;
          .toggle_container {
            width: 2.2vw;
            height: 1vw;
            .iconHolder {
              width: 1vw;
              height: 1vw;
              .close {
                width: 0.4vw;
                height: 0.4vw;
              }
              .tick {
                width: 0.491667vw;
                height: 0.5208333333vw;
              }
            }
          }
        }
      }

      .restrict_users_btn {
        margin: 0;
        cursor: pointer;
        border: 0;
        color: #464646;
        background-color: #eeeeee;
        border-radius: 3px;
        padding: 0.3vw;
        font-weight: 400;
        width: 14vw;
        // font-size: 1.1vw;
        font-size: 16 * $desk + vw;
      }

      .license_name {
        color: #043398;
        // font-size: 1.5vw;
        font-size: 26 * $desk + vw;
        font-weight: 600;
        text-transform: capitalize;
      }

      .edit_assign_btns {
        display: flex;
        // gap: 1vw;
      }
    }
    .license_table {
      align-self: flex-start !important;
      background-color: #fafafa;
      padding: 1vw;
      border-radius: 12px;
      height: 10vw;
      margin: 0.5vw;
      table {
        border-collapse: collapse;
        margin: 0 auto;
        width: 80%;
        tr {
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
        }
        td,
        th {
          text-transform: capitalize;
          text-align: left;
          padding: 8px;
          border-radius: 10px;
        }
        th {
          font-size: 12 * $desk + vw;
          width: 8vw;
          color: #0000004d;
          font-weight: 500;
        }
        td {
          font-size: 16 * $desk + vw;
          color: #323232b2;
          font-weight: 600;
        }
      }
    }
  }
}
