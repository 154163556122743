@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);
.VersionDt_sidebar {
//   display: flex;
//   width: 297 * $desk + vw;
//   height: 100%;
//   background-color: red;
//   flex-direction: column;

  .versionDt_upper_sidebar {
    align-items: center;
    text-align: center;
    width: 15.4375vw;
    // height: 13vw;
    // background-color: orange;

    .versiondt_upper_subwrapper{
    border-bottom: 1* $desk + vw solid #E7E7E7;

    padding: 35 * $desk + vw 45 * $desk + vw 25 * $desk + vw 45 * $desk + vw;


    }
    .versionDt_lower_sidebar{
      border: 1* $desk + vw solid #C7C7C7;
    }
  }
}
