@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);
.SpecialLicense_main_wrapper {
  width: 424 * $desk + vw;
  //   height: 122 * $desk + vw;
  padding: 16 * $desk + vw;
  box-shadow: 0 3 * $desk + vw 6 * $desk + vw rgba(0, 0, 0, 0.16),
    0 3 * $desk + vw 6 * $desk + vw rgba(0, 0, 0, 0.23);
}
.SpecialLicense_subwrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 6vw;
}
.SpecialLicense_name {
  font-weight: 600;
  font-size: 24 * $desk + vw;
  color: #003b9e;
}
.SpecialLicense_count_wrapper{
    font-weight: 600;
    display: flex;
  font-size: 16 * $desk + vw;
  gap: 1vw;
  .SpecialLicense_plan, .SpecialLicense_count{
    display: flex;
    gap: 0.2vw;
  }

    .count_header, .plan_header{
        color: #000;
    }
    .count_value, .plan_value{
        color: #003B9E;
        text-transform: capitalize;
    }
}
.SpecialLicense_button_wrapper{
    display: flex;
}

.btn {
  padding: 1vw;
  border: 1px solid #043398;
  color: #fff;
  
}
