@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.usecase_additionalimgs_wrapper {
  // margin-top: 1vw;

  .additional_images {
  font-size: 20* $desk + vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
  }
  .images_wrapper {
    background-color: #295ee6;
    margin-bottom: 1vw;
    width: 100%;

    // .images_{
    //   width: 100 * $desk + vw;
    //   height: 100 * $desk + vw;
    // }
  }
}

.viewappimage_main_wrapper {
 
  width: 200 * $desk + vw;
  .view_images {
    position: relative;
    width: 500 * $desk + vw;
    height: 300 * $desk + vw;
    border-radius: 8* $desk + vw;
    overflow: hidden;
  box-shadow: 0* $desk + vw 0* $desk + vw 6* $desk + vw 2* $desk + vw rgba(0, 0, 0, 0.35);

    .view_image {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      object-fit: cover;
      // background-color: #295ee6;
  box-shadow: 0* $desk + vw 0* $desk + vw 6* $desk + vw 2* $desk + vw rgba(0, 0, 0, 0.35);

    }
  }
}
