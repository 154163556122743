@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.assign_modal {
  .modalAdjust_ {
    width: 876 * $desk + vw;
    height: 530 * $desk + vw;
    background-color: var(--background);
    padding: 32 * $desk + vw;
  }

  .assign_mfr_wrapper {
    .close_otp_modal {
      width: 38 * $desk + vw;
      height: 38 * $desk + vw;
      border-bottom-left-radius: 0.46875vw;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .assign_label {
      font-size: 24 * $desk + vw;
      font-weight: 600;
      padding-bottom: 0.8vw;
    }
    .assign_slection_wrapper {
      display: flex;
      justify-content: space-between;
      .manufacturer_label,
      .user_label {
        // width: 374 * $desk + vw;
        width: 21.479167vw;
        text-align: center;
        font-size: 24 * $desk + vw;
        text-transform: capitalize;
        padding: 11 * $desk + vw 90 * $desk + vw 11 * $desk + vw 90 * $desk + vw;
        margin-bottom: 24 * $desk + vw;
        font-size: 24 * $desk + vw;
        box-shadow: 0 * $desk + vw 4 * $desk + vw 4 * $desk + vw #0000000a;
        transition: all 0.3s ease-in-out 0s;
        cursor: pointer;
      }
      .user_label {
        border-top-right-radius: 10 * $desk + vw;
        border-bottom-right-radius: 10 * $desk + vw;
      }
      .manufacturer_label {
        border-top-left-radius: 10 * $desk + vw;
        border-bottom-left-radius: 10 * $desk + vw;
      }
    }

    .input_style {
      // padding-left: 2.6vw;
    }

    .search_input_ {
      width: 89%;
    }

    .autocomplete_results {
      left: 3%;
      background: #fff;
      // width: 100%;
      width: 820 * $desk + vw;
      position: absolute;
      z-index: 99;
      font-size: 16 * $desk + vw;
      list-style: none;
      // background: var(--color-canvas-overlay);
      border: 1 * $desk + vw solid var(--color-border-default);
      border-radius: 6 * $desk + vw;
      box-shadow: 0 3 * $desk + vw 6 * $desk + vw #010409;

      .options {
        padding: 0.5vw;
        cursor: pointer;

        &:hover {
          background-color: var(--primary);
          color: #fff;
        }

        &:nth-of-type(1) {
          border-top-left-radius: 6 * $desk + vw;
          border-top-right-radius: 6 * $desk + vw;

          &:hover {
            border-top-left-radius: 6 * $desk + vw;
            border-top-right-radius: 6 * $desk + vw;
          }
        }

        &:last-child {
          border-bottom-left-radius: 6 * $desk + vw;
          border-bottom-right-radius: 6 * $desk + vw;

          &:hover {
            border-bottom-left-radius: 6 * $desk + vw;
          }
        }
      }

      .active_flag {
        background-color: var(--primary);
        color: #fff;
      }
    }

    .__input__ {
      svg {
        left: 2%;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .search_wrapper_ {
      margin-bottom: 16 * $desk + vw;
      border-radius: 6 * $desk + vw;

      .autocomplete_results {
        box-shadow: rgb(0 0 0 / 15%) 0 * $desk + vw 3 * $desk + vw 3 * $desk +
          vw 0 * $desk + vw !important;
      }
    }
    .no_data_found {
      position: absolute;
      top: 65%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 20 * $desk + vw;
    }
    .tr_color {
      padding: 20 * $desk + vw;
      background-color: #fafafa;
      opacity: 1;
      color: rgba(0, 0, 0, 0.5019607843);
      font-weight: 500;
      font-size: 18 * $desk + vw;
      // margin-bottom: -1.2vw;
      text-transform: capitalize;
      // box-shadow: inset 0 * $desk + vw 0 * $desk + vw 6 * $desk + vw
      // rgb(0 0 0 / 15%);
    }
    .change_color {
      background-color: #fff;
   
    }
    .mfr_table {

      #mfr {
        border: none;
        border-collapse: collapse;
        width: 100%;
        background: #fafafa;
        box-shadow: inset 0 * $desk + vw 0 * $desk + vw 6 * $desk + vw
          rgb(0 0 0 / 15%);
        border-radius: 8 * $desk + vw;
      }
      #mfr th {
        padding: 8 * $desk + vw;
        color: var(--text);
        font-weight: normal;
        opacity: 0.4;
        font-size: 14 * $desk + vw;
        text-align: center;
        padding-top: 0 * $desk + vw;
          padding-bottom: 0 * $desk + vw;
      }
      #mfr_data td {
     padding: 8 * $desk + vw
      }

      .no_license_td {
        display: flex;
        justify-content: center;

        .no_license {
          width: 6vw;
        }
      }

      .mfr_toggle {
        display: flex;
        justify-content: center;
      }

      .del_mfr_icon {
        fill: red;
        cursor: pointer;
      }
      .table_special_wrapper{
          box-shadow: inset 0 * $desk + vw 0 * $desk + vw 6 * $desk + vw
        rgb(0 0 0 / 15%);
        border-radius: 8 * $desk + vw;

      }
      #mfr_data {
        // margin: -2* $desk + vw;
      }
    }

    .remianing_count_ {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      left: 2%;
      bottom: 2%;
      font-size: 20 * $desk + vw;
      color: rgb(4, 51, 152);
    }

    .mfr_btns {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      right: 2%;
      bottom: 2%;

      .btn {
        padding: 0.2vw;
        width: 4vw;
        border: 1 * $desk + vw solid #043398;
      }
    }
  }
}
