@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.navbar_root {
  position: relative;
  width: 100%;
  .nav_header {
    position: relative;
    height: 105px;
    z-index: 12;
    .navbar_horizontal {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      left: 0;
      top: 0;
      background-color: var(--sidenav);
      width: 100%;
      height: 105px;
      z-index: 10;
      padding: 0px 12px 0px 8px;
      .navbar_logo {
        display: flex;
        align-items: center;
        .navbar_logo_img {
          height: 100%;
          width: 100%;
          cursor: pointer;
          width: 107px;
          height: 36px;
          object-fit: contain;
          vertical-align: middle;
          margin-left: 25 * $desk + vw;
        }
        .nav_search_wrapper {
          display: flex;
          align-items: center;
          width: 460 * $desk + vw;
          padding: 0 22 * $desk + vw;
          height: 52 * $deskHeight + vh;
          background-color: #fff;
          border-radius: 25px;
          margin-left: 44px;
          .nav_search_input {
            width: 100%;
            height: 70%;
            margin-left: 25 * $desk + vw;
            font-size: 18px;
            border: 0;
            outline: none;
            font-weight: 500;
          }
        }
      }
      .nav_userInfo {
        display: flex;
        align-items: center;
        margin-right: 35 * $desk + vw;
        .nav_cart_info,
        .nav_notification_info,
        .nav_request_info {
          position: relative;
          svg {
            vertical-align: bottom;
          }
          .cart-count {
            position: absolute;
            top: 0;
            right: 0;
            display: grid;
            place-items: center;
            min-width: 22px;
            min-height: 22px;
            position: absolute;
            background-color: var(--primary);
            font-size: 8px;
            color: #fff;
            border-radius: 50%;
            border: 2px solid #fff;
            box-shadow: 0px 3px 8px 0 rgba(34, 34, 34, 0.2);
          }
          .adjust {
            right: -10px;
          }
          .adjust2 {
            right: -10px;
            top: -6px;
          }
        }
        .nav_request_info {
          .notification_wrapper {
            top: 130%;
            &:before {
              top: -11px;
              border-color: transparent transparent transparent
                var(--background);
              border-width: 6px;
              transform: rotate(270deg);
              left: 80%;
            }
          }
        }

        .profile_wrapper {
          display: flex;
          align-items: center;
          .profile_holder {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            border: 2px solid rgba(69, 0, 255, 0.2);
            padding: 9 * $desk + vw;
            margin-left: 2vw;
            margin-right: 20 * $desk + vw;
            .no_profile {
              width: 100%;
              height: 100%;
              border: 2px solid white;
              border-radius: 50%;
              box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.26);
              object-fit: cover;
            }
          }
          .profile_text_holder {
            position: relative;
            font-size: 15px;
            padding-right: 60 * $desk + vw;
            z-index: 5;
            .nav_welcome {
            }
            .nav_username {
              color: var(--primaryInvert);
              font-weight: 600;
            }
            .nav_current_role {
              background-color: #ddd;
              color: black;
              padding: 0.3vw;
              text-align: center;
              display: inline-block;
              border-radius: 16px;
              font-size: 0.7vw;
              font-weight: 500;
              text-transform: capitalize;
            }
            .nav_drop {
              position: absolute;
              right: 0;
              top: 50%;
              cursor: pointer;
              transition: all 0.2s ease-in;
            }
            .navbar_invert {
              transform: rotate(180deg);
            }
            .profile_dropdown {
              position: absolute;
              width: 105%;
              background: #fff;
              border-radius: 8px;
              opacity: 0;
              visibility: hidden;
              transform: scale(0.01);
              transition: transform 0.3s ease, opacity 0.3s ease,
                visibility 0.3s;
              transform-origin: right top;
              padding-bottom: 5 * $desk + vw;
              &::before,
              &::after {
                content: "";
                display: block;
                position: absolute;
                left: 100%;
                width: 0;
                height: 0;
                border-style: solid;
              }
              &:after {
                top: -10px;
                border-color: transparent transparent transparent #fff;
                border-width: 5px;
                transform: rotate(270deg);
                left: 88.5%;
              }

              /* this border color controlls the outside, thin border */
              &:before {
                top: -17px;
                border-color: transparent transparent transparent #ddd;
                border-width: 8px;
                transform: rotate(270deg);
                left: 87%;
              }
              .dd_head {
                color: #2d62ed;
                font-size: 16px;
                padding-bottom: 5 * $desk + vw;
                border-bottom: 1px solid #f4f7fe;
                border-top: 1px solid #f4f7fe;
                padding: 10 * $desk + vw 30 * $desk + vw;
                text-transform: capitalize;
              }
              .dd_option {
                color: #3b3b3b;
                font-size: 15px;
                padding-bottom: 8 * $desk + vw;
                padding-top: 10 * $desk + vw;
                // border-bottom: 1px solid #f4f7fe;
                padding: 5 * $desk + vw 30 * $desk + vw;
                cursor: pointer;
                &:hover {
                  background-color: #f6f9ff;
                }
              }
              .manu_user {
                &:hover {
                  .accordion-content {
                    max-height: 300px;
                  }
                }
              }
              .acc-toggle {
                -webkit-transition: background 0.1s linear;
                -moz-transition: background 0.1s linear;
                -ms-transition: background 0.1s linear;
                -o-transition: background 0.1s linear;
                transition: background 0.1s linear;
                display: block;
                text-decoration: none;
                border-top: 1px solid #f4f7fe;
              }
              .accordion-content {
                -webkit-transition: max-height 300ms;
                -moz-transition: max-height 300ms;
                -ms-transition: max-height 300ms;
                -o-transition: max-height 300ms;
                transition: max-height 300ms;
                overflow: hidden;
                max-height: 0;
              }
            }
            .active__ {
              opacity: 1;
              visibility: visible;
              transform: scale(1);
              transform-origin: right top;
              border: 2px solid #dddddd;
              top: 105%;
              z-index: 5;
              // transform: translateY(0);
            }
            .active_option {
              background-color: var(--primary);
              color: #ffffff;
            }
          }
        }
      }
      .notification_wrapper {
        position: absolute;
        right: -2.29vw;
        width: 330 * $desk + vw;
        height: 330 * $desk + vw;
        background-color: var(--background);
        border-radius: 12px;
        top: 112%;
        box-shadow: var(--dd-box-shadow);
        .header {
          padding: 30 * $desk + vw;
          font-size: 18 * $desk + vw;
          font-weight: 500;
          border-bottom: 2px solid #dddddd;
        }
        .no_notification {
          font-size: 15 * $desk + vw;
          text-align: center;
        }
        &::before {
          content: "";
          display: block;
          position: absolute;
          left: 100%;
          width: 0;
          height: 0;
          border-style: solid;
        }
        // &:after {
        //   top: -10px;
        //   border-color: transparent transparent transparent #fff;
        //   border-width: 5px;
        //   transform: rotate(270deg);
        //   left: 90.5%;
        // }

        /* this border color controlls the outside, thin border */
        &:before {
          top: -17px;
          border-color: transparent transparent transparent var(--background);
          border-width: 10px;
          transform: rotate(270deg);
          left: 80%;
        }
        .notification__card {
          position: relative;
          display: flex;
          justify-content: space-between;
          padding: 0 20 * $desk + vw;
          font-size: 13 * $desk + vw;
          padding-bottom: 0 * $desk + vw;
          cursor: pointer;
          .msg {
            width: 80%;
            font-family: "Ubuntu";
          }
          .noti_data {
            margin-top: 0.4vw;
            min-height: 3vw;
            width: 100%;
          }
          svg {
            position: relative;
            width: 40 * $desk + vw;
            z-index: 100;
            height: 40 * $desk + vw;
            background-color: var(--background);
          }
          &::before {
            content: "";
            position: absolute;
            left: 2.5vw;
            width: 50px;
            border-left: 2px dashed rgba(0, 0, 0, 0.2);
            height: 100%;
            z-index: 0;
          }
        }
        .notification__card:last-child {
          &::before {
            content: "";
            border-left: 0px dashed rgba(0, 0, 0, 0.2);
          }
        }
        .btn {
          border-radius: 22px;
          width: 90 * $desk + vw;
          font-size: 12 * $desk + vw;
          padding: 5 * $desk + vw 0;
          margin: 0;
        }
        .btn:last-child {
          background: #b9bec9;
          margin-left: 1vw;
        }
        .message__ {
          // transition: height .5s;
          max-height: 0;
          overflow: hidden;
          background-color: var(--sidenav);
          // padding: 10 * $desk + vw;
          border-radius: 8px;
          margin-top: 10 * $desk + vw;
          font-size: 15 * $desk + vw;
          transition: all 500ms ease-in-out;
          p {
            margin: 10 * $desk + vw;
          }
        }
      }
    }
  }
  .__wrapper__ {
    display: flex;
    height: 100%;
    .side_nav_ {
      width: 120 * $desk + vw;
      height: calc(100vh - 105px);
    }
    .side_notification_ {
      width: 350 * $desk + vw;
      height: calc(100vh - 105px);
    }
    .body_section {
      flex: 1;
      background: var(--body-background);
      // margin-top: -0.5vw;
      // z-index: 12;
    }
  }
  .navbar_verticle {
    position: fixed;
    left: 0;
    top: 0;
    width: 120 * $desk + vw;
    padding-top: 75 * $desk + vw;
    top: 105px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--sidenav);
    height: 100%;
    z-index: 2;

    .navbar_verticle_tab {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 40 * $desk + vw;
      margin-bottom: 25 * $desk + vw;

      .verticleicon {
        // width: 30 * $desk + vw;
      }
      .navbar_verticle_name {
        font-size: 15 * $desk + vw;
        color: #a5a4bf;
        font-weight: 500;
        // background-color: #ffffff;
        margin-top: 10 * $desk + vw;
      }
    }
    .navbar_verticle_div {
      width: 80%;
      height: 2px;
      color: black;
    }
  }
  .privacy {
    position: absolute;
    left: 0;
    bottom: 5vw;
    font-size: 0.78125vw;
    text-align: center;
    // margin-top: 24vw;
    color: #2d62ed;
    text-decoration: underline;
    z-index: 5;
    width: 6.25vw;
  }
  .terms {
    bottom: 2vw;
  }
}
.manu_request_card_ {
  position: relative;
  z-index: 10;
  border-bottom: 1px solid #e7e7e7;
  padding: 14 * $desk + vw 25 * $desk + vw;
  ._flex {
    display: flex;
    justify-content: space-between;
    .name {
      // font-family: "Open Sans";
      font-size: 13 * $desk + vw;
      font-weight: 500;
    }
    .time {
      font-family: "Open Sans";
      font-size: 10 * $desk + vw;
      opacity: 0.7;
    }
  }
  .company {
    font-family: "Open Sans";
    font-size: 11 * $desk + vw;
    opacity: 0.7;
    margin-top: 6 * $desk + vw;
    margin-bottom: 14 * $desk + vw;
  }
  .btn {
    border-radius: 22px;
    width: 90 * $desk + vw;
    font-size: 12 * $desk + vw;
    padding: 5 * $desk + vw 0;
    margin: 0;
    &:last-child {
      background: #b9bec9;
      margin-left: 1vw;
    }
  }
  .message__ {
    // transition: height .5s;
    max-height: 0;
    overflow: hidden;
    background-color: var(--sidenav);
    // padding: 10 * $desk + vw;
    border-radius: 8px;
    margin-top: 10 * $desk + vw;
    font-size: 15 * $desk + vw;
    transition: all 500ms ease-in-out;
    p {
      margin: 10 * $desk + vw;
    }
  }
  &:last-child {
    border-bottom: 0;
  }
}
.activated_tab {
  width: 100% !important;
  .navbar_verticle_tab {
    position: relative;
    &::after {
      position: absolute;
      left: 0;
      top: 0;
      // transform: translateY(-50%);
      content: "";
      height: 60%;
      width: 4 * $desk + vw;
      background-color: var(--primary);
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
    .material-icons {
      color: white !important;
    }
    .navbar_verticle_name {
      color: var(--primaryInvert) !important;
    }
    svg path {
      fill: var(--primary) !important;
    }
  }
}

.request_modal_adjust__ {
  .modalAdjust_ {
    align-self: center;
    width: 800 * $desk + vw !important;
    max-height: 90vh;
    padding: 8 * $desk + vw 30 * $desk + vw;
    padding-bottom: 20 * $desk + vw;
    .continue_msg {
      text-align: center;
      margin-bottom: 20 * $desk + vw;
      font-size: 18 * $desk + vw;
    }
    .btn {
      padding: 0.4375vw 0.5208333333vw;
      display: block;
      margin: 0 auto;
      font-size: 16 * $desk + vw;
    }
    .msg_area {
      width: 100%;
      resize: none;
      margin-top: 5px;
      border: 2px solid #dddddd;
      border-radius: 0.6770833333vw;
      padding: 0.3vw;
      font-size: 0.7770833333vw;
      outline: none;
    }
    .bank_detail {
      display: flex;
      width: 100%;
      margin-top: 1vw;
      flex-wrap: wrap;
      gap: 1vw;
      .__input__ {
        width: 48%;
        text-transform: capitalize;
        margin-bottom: 0;
        .input_style {
          color: #000;
        }
      }
      // .left-section {
      //   width: 50%;
      //   margin-right: 0.625vw;
      // }
      // .right-section {
      //   width: 50%;
      //   margin-left: 0.625vw;
      .adjust_dd {
        text-transform: capitalize;
        width: 48%;
        .selected-text {
          background-color: white;
          color: #000;
          // height: 54.6%;
          border-radius: 0.6770833333vw;
        }
        button {
          height: 44 * $desk + vw !important;
        }
      }
      // }
    }
    .text-area-label {
      font-size: 0.78125vw;
      color: var(--primaryInvert);
      font-weight: 600;
      &::after {
        content: "*";
        color: red;
        margin-left: 0.5vw;
        font-weight: 400;
      }
    }
    .radio-header {
      // margin-top: 40 * $desk + vw;
      margin-bottom: 12 * $desk + vw;
      font-weight: 600;
      font-size: 18 * $desk + vw;
      color: var(--label);
    }
  }
}
