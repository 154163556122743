@use "sass:math";
$desk: math.div(100, 1920);

.__input__ {
  position: relative;
  margin-bottom: 18 * $desk + vw;
  display: flex;
  flex-direction: column;
  label {
    font-size: 15 * $desk + vw;
    color: var(--primaryInvert);
    font-weight: 600;
    margin-left: 0.21vw;
  }
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-35%);
    left: 4%;
    width: 22 * $desk + vw;
    height: 22 * $desk + vw;
    z-index: 1;
  }
  .input_style {
    // height: 40 * $desk + vw;
    width: 100%;
    outline-color: var(--secondary);
    margin-top: 5px;
    border-radius: 13 * $desk + vw;
    border: 2px solid #dddddd;
    padding: 10 * $desk + vw;
    font-size: 13 * $desk + vw;
    
    &:focus {
      outline: 2px solid var(--secondary);
    }
    // color: var(--text);
  }
  .input_style:disabled {
    background: #ddd;
    cursor: not-allowed;
  }

  .icon-toggle {
    position: absolute;
    width: 22 * $desk + vw;
    top: 72%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0.6;
    // @media only screen and (max-width: 900px) {
    //   top: 58%;
    //   left: 87%;
    // }
  }
  ._tooltip_container_ {
    position: absolute;
    top: 0;
    right: 0;
  }
  .helperText {
    color: red;
    font-size: 0.6291666667vw;
    /* margin-left: 10px; */
    /* margin-top: 5px; */
    position: absolute;
    right: 2%;
    top: 101%;
  }
}
