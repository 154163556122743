@use "sass:math";
$desk: math.div(100, 1920);

.btn {
  position: relative;
  width: 100%;
  // min-height: 40 * $desk + vw;
  background: var(--primary);
  border: 0;
  border-radius: 8px;
  font-family: Poppins;
  font-size: 18 * $desk + vw;
  color: #fff;
  margin: 0 3px;
  padding: 18 * $desk + vw 10 * $desk + vw;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  .btnBG {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.btn:active {
  animation: press 0.2s 1 linear;
}

.btn:disabled {
  opacity: 0.4 !important;
  cursor: auto;
  pointer-events: none;
}

.btn_gradient {
  background: rgb(1, 58, 162);
  background: linear-gradient(97deg, #013aa2, #2d62ed);
}
