@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.__device_wrapper__ {
  .label {
    color: var(--label);
    font-size: 15 * $desk + vw;
    font-weight: 600;
    text-transform: lowercase;
  }
  .label::first-letter {
    text-transform: uppercase;
  }
  .__details__ {
    position: relative;
    padding: 2.2041666667vw 1.5vw !important;
    padding-bottom: 1vw;
    .device_detail_title {
      display: inline-block;
      font-weight: 600;
      font-size: 20 * $desk + vw;
      margin-bottom: 40 * $desk + vw;
      // background: linear-gradient(
      //   270deg,
      //   rgba(0, 0, 0, 0) 0%,
      //   rgba(243, 246, 255, 1) 23%
      // );
      background: var(--background-header);
      margin-bottom: 20 * $desk + vw !important;
      padding: 4 * $desk + vw;
      padding-right: 27 * $desk + vw;
      padding-left: 8 * $desk + vw;
      border-radius: 6px 0 0 6px;
      color: var(--secondary);
    }
    .device_tile {
      display: inline-block;
      .label {
        color: var(--label);
        font-size: 15 * $desk + vw;
        font-weight: 600;
      }
      .value {
        margin-top: 10 * $desk + vw;
        font-size: 17 * $desk + vw;
        color: var(--label);
        font-weight: 500;
        display: inline-block;
      }
      #Serial_Number {
        cursor: default;
        max-width: 6vw;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .device_detail {
      display: grid;
      --auto-grid-min-size: 9vw;
      grid-template-columns: repeat(
        auto-fill,
        minmax(var(--auto-grid-min-size), 1fr)
      );
      padding-bottom: 30 * $desk + vw;
      border-bottom: 2px solid #e7e7e7;
      row-gap: 40 * $desk + vw;
      column-gap: 0.6875vw;
      margin-bottom: 30 * $desk + vw;
      // display: flex;
      // gap: 3.2vw;
      // flex-wrap: wrap;
      .copyIcon {
        margin-left: 1vw;
        margin-bottom: 0.3vw;
        width: 20 * $desk + vw;
        cursor: pointer;
        height: 20 * $desk + vw;
        * {
          fill: var(--text);
        }
        &:active {
          animation: press 0.2s 1 linear;
        }
      }
    }
    .device_detail2 {
      display: flex;
      gap: 3vw;
      padding-bottom: 30 * $desk + vw;
      border-bottom: 2px solid #e7e7e7;
      margin-bottom: 30 * $desk + vw;
      .device_tile {
        display: block;
        // margin-right: 2vw;
      }
    }
    .device_detail_adjust {
      .device_tile {
        margin-right: 3.5vw;
      }
      .device_details_license_header {
        display: flex;
      }
      .device_details_license_table {
        display: flex;
        justify-content: space-between;
        width: 6vw;
        text-transform: capitalize;
        padding-right: 1vw;
      }
      .device_details_license_tr {
        display: flex;
        flex-direction: column;
        margin-top: 0.5208333333vw;
        font-size: 0.8854166667vw;
        color: var(--label);
        font-weight: 500;
      }
    }
  }
  .profile_left {
    .profile_holder {
      // height: 375 * $desk + vw !important;
      .profile_image_container {
        position: relative;
        // margin-top: -3vw !important;
        height: 175 * $desk + vw;
        width: 175 * $desk + vw !important;
        border-radius: 50%;
        // border: 3px solid #e1e1e1;
        background-color: var(--sidenav);
        padding: 1.6vw;
        .deviceIcon {
          width: 100%;
          height: 100%;
        }
        .active_icon,
        .inactive_icon {
          position: absolute;
          bottom: 0.8vw;
          width: 55 * $desk + vw;
          height: 55 * $desk + vw;
          left: 0.5vw;
        }
      }
    }
  }
  .res_success_btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 40 * $desk + vw;
    .btn {
      width: 160 * $desk + vw;
      font-size: 14 * $desk + vw;
      padding: 12 * $desk + vw 10 * $desk + vw;
    }
  }
  .__serviceRunning__ {
    padding-right: 5.25vw !important;
    .device_title {
      margin-bottom: 20 * $desk + vw !important;
    }
    .register_data {
      padding-right: 1vw !important;
    }
    .input-tag {
      display: flex;
      flex-wrap: wrap;
      input {
        border: none;
        width: 100%;
      }
      .input-tag__tags {
        display: inline-flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        width: 100%;
      }
      .input-tag__tags li {
        align-items: center;
        background: #f0f3ff;
        border-radius: 2px;
        color: #000;
        display: flex;
        list-style: none;
        margin-right: 40 * $desk + vw;
        border-radius: 20 * $desk + vw;
        padding-left: 16 * $desk + vw;
        padding-top: 4 * $desk + vw;
        padding-bottom: 4 * $desk + vw;
        padding-right: 5 * $desk + vw;
        border: 1px solid #e7e7e7;
        margin-bottom: 10 * $desk + vw;
        p {
          font-size: 12 * $desk + vw;
          margin-right: 25 * $desk + vw;
        }
        svg {
          vertical-align: sub;
          width: 20 * $desk + vw;
          height: 20 * $desk + vw;
          cursor: pointer;
        }
      }

      .input-tag__tags li.input-tag__tags__input {
        background: none;
        flex-grow: 1;
        padding: 0;
        border: 0;
      }
    }
  }

  .__userinfo__,
  .__deployeduc {
    position: relative;
    padding: 45 * $desk + vw !important;
    padding-top: 10 * $desk + vw !important;
    .device_header {
      display: flex;
      width: 100%;
      height: 64 * $desk + vw;
      justify-content: space-between;
      padding-right: 1.5vw;
      align-items: center;
      .flex_ {
        display: flex;
        align-items: center;
        cursor: pointer;
        .device_count {
          font-family: Poppins;
          font-size: 16 * $desk + vw;
          font-weight: 600;
          padding: 0 8 * $desk + vw;
          background-color: var(--body-background);
          color: var(--primaryInvert);
          border-radius: 20px;
          line-height: 1.5;
        }
        .device_count_text {
          color: var(--primaryInvert);
          font-size: 14 * $desk + vw;
          margin-left: 4 * $desk + vw;
        }
        .btn {
          //   width: 4.9479166667vw;
          padding: 0.4441666667vw 18 * $desk + vw;
          margin-right: 1vw;
        }
      }
    }
    .loader__ {
      height: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      .noData {
        font-size: 32 * $desk + vw;
        font-weight: bold;
      }
    }

    // .btn {
    //   border: 0;
    //   width: 4.9479166667vw;
    //   padding: 0.1441666667vw 18 * $desk + vw;
    //   border-radius: 14px;
    //   background: rgb(138, 0, 0);
    //   font-size: 0.78125vw;
    //   background: linear-gradient(
    //     213deg,
    //     rgba(138, 0, 0, 1) 44%,
    //     rgba(209, 15, 15, 1) 73%
    //   );
    //   cursor: pointer;
    //   color: #fff;
    //   z-index: 2;
    // }
    .adjust_table {
      .usernameImage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .user_avatar {
          width: 36 * $desk + vw;
          height: 36 * $desk + vw;
          margin-right: 17 * $desk + vw;
          border-radius: 50%;
        }
      }
      td:first-child {
        padding-left: 2.5vw;
      }

      .usertype {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        .type {
          padding: 4 * $desk + vw 15 * $desk + vw;
          background-color: var(--sidenav);
          border-radius: 8px;
          padding-left: 25 * $desk + vw;
          margin-left: -15 * $desk + vw;
          color: var(--label);
        }
      }
      .tbl-content {
        padding-bottom: 0.7vw;
      }
      .adjust_mdd {
        position: relative;
        top: 0.2vw;
        width: 95 * $desk + vw;
        margin-bottom: 0;

        .selected-text {
          padding: 2 * $desk + vw 8 * $desk + vw;
          border-radius: 14px;
          background-color: #100db1;
          font-size: 15 * $desk + vw;
          color: #fff;
          margin-left: 3.5vw;
          ._text {
            width: 80%;
          }
        }
        .select-options {
          &::before {
            // display: none;
            left: 71.5%;
          }
          &::after {
            // display: none;
            left: 77.5%;
          }
        }
        .active__ {
          top: 100%;
          z-index: 4;
          left: 62%;
          // border-top: 0;
        }
        .custom-select-option {
          font-size: 13 * $desk + vw;
          padding: 6 * $desk + vw;
          .checkbox_ {
            position: relative;
            top: 3px;
          }
          .checkbox_ .styled-checkbox + label:before {
            margin-right: 0;
            width: 13px;
            height: 13px;
            border-radius: 4px;
            margin-top: 0px;
            vertical-align: unset;
          }
          .checkbox_ .styled-checkbox:checked + label:after {
            left: 2px;
            top: -8px;
          }
          .checkbox_ .styled-checkbox + label {
            font-size: 0;
          }
        }
        .drop_icon {
          top: 32%;
          width: 0.5208333333vw !important;
          height: 0.5208333333vw !important;
          margin-right: 0;
        }
        .drop_icon * {
          // fill: red;
          stroke: #fff;
        }
      }
      .data_table {
        td:last-child {
          position: relative;
          // overflow: hidden;
          // &::after {
          //   content: "";
          //   position: absolute;
          //   right: -1px;
          //   top: 0;
          //   width: 100%;
          //   height: 100%;
          //   // background: linear-gradient(97deg, #013aa2, #2d62ed);
          //   border-right-style: solid;
          //   border-bottom-right-radius: 35px;
          //   border-top-right-radius: 35px;
          //   border-color: #f0f0f0;
          //   border-width: 1px;
          // // }
          // justify-content: center;
          // display: flex;
        }
        tr {
          .deleteIcon {
            display: block;
            width: 1.5vw;
            height: 2vw;
            color: red;
          }
          &:hover {
            td:last-child {
              background: linear-gradient(
                278deg,
                rgba(1, 58, 162, 1) 23%,
                rgba(45, 98, 237, 1) 73%
              );
              color: #fff;
             

              .selected-text {
                background-color: transparent;
              }
            }
        
          }
        }
        .deleteIcon {
          position: absolute;
          display: none;
          right: 6vw;
          top: 53%;
          transform: translateY(-50%);
          margin-right: 0;
          cursor: pointer;
          z-index: 1;
        }
      }
    }

    .userinfo_bottom {
      position: absolute;
      bottom: 0.3vw;
      left: 0;
      width: 100%;

      display: flex;
      justify-content: space-between;
      // padding-top: 0.3vw;
    }
    .block:hover {
      td:last-child {
        background: var(--sidenav) !important;
      }
    }
  }

  .__deployeduc {
    td:nth-child(3) {
      padding-left: 3.5vw !important;
    }
  }
  .operator_modal_adjust {
    .modalAdjust_ {
      align-self: center;
      width: 520 * $desk + vw;
      max-height: 90vh;
      // min-height: 25vw;
      padding: 8 * $desk + vw 30 * $desk + vw;
      padding-bottom: 30 * $desk + vw;
      .continue_msg {
        text-align: center;
        margin-bottom: 40 * $desk + vw;
        font-size: 18 * $desk + vw;
      }
      .btn {
        padding: 0.4375vw 0.5208333333vw;
        // position: absolute;
        // width: 50%;
        display: block;
        margin: 0 auto;
        // margin-left: 0;
        font-size: 16 * $desk + vw;
        // left: 0;
        // width: 50%;
        // bottom: 1vw;
        // left: 50%;
        // transform: translateX(-50%);
      }
      .autocomplete-results {
        left: 0;
        z-index: 99;
        font-size: 16 * $desk + vw;
        list-style: none;
        background: var(--color-canvas-overlay);
        border: 1px solid var(--color-border-default);
        border-radius: 6px;
        box-shadow: 0 3px 6px #010409;
        .options {
          padding: 0.5vw;
          cursor: pointer;
          &:hover {
            background-color: var(--primary);
            color: #fff;
          }
          &:nth-of-type(1) {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            &:hover {
              border-top-left-radius: 6px;
              border-top-right-radius: 6px;
            }
          }
          &:last-child {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            &:hover {
              border-bottom-left-radius: 6px;
            }
          }
        }
        .active_flag {
          background-color: var(--primary);
          color: #fff;
        }
      }
      .selected_user {
        width: 100%;
        // background-color: red;
        border-radius: 12px;
        box-shadow: 0 3px 6px #010409;
        margin-top: 1vw;
        overflow: hidden;
        .selected_user_option {
          display: flex;
          padding: 0.5vw;
          align-items: center;
          background-color: var(--color-accent-subtle);
          border: 2px solid var(--color-accent-muted);
          margin-bottom: 0.3vw;
          &:first-child {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
          }
          &:last-child {
            margin-bottom: 0;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
          }
          .name {
            flex: 1;
            font-size: 16 * $desk + vw;
          }
          .permissions {
            position: relative;
            display: flex;
            font-size: 16 * $desk + vw;
            flex: 1;
            border-left: 2px solid var(--color-accent-muted);
            .closeicon {
              width: 1vw;
              height: 1vw;
              position: absolute;
              right: 0.3vw;
              fill: red;
              top: 50%;
              transform: translateY(-50%);
              cursor: pointer;
            }
            .permission_options {
              font-size: 14 * $desk + vw;
              margin: 0 0.6vw;
              align-items: center;
              display: flex;
              gap: 0.2vw;
            }
          }
        }
      }
    }
  }
}
