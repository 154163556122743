@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);
.createdlicense_main_wrapper_user {
  padding: 1.833333vw 1.93vw !important;
}
.createdlicense_main_wrapper {
  padding: 0.833333vw 1.25vw;
  background: #ffffff;
  box-shadow: 0vw 0.0520833333vw 0.5208333333vw 0.2083333333vw
    rgba(0, 0, 0, 0.15);
  border-radius: 0.4166666667vw;
  margin: 0.5208333333vw;
  display: flex;
  padding-right: 2.041667vw;
  justify-content: center;
  align-items: center;
  .createdlicense_subwrapper {
    .license_name {
      position: relative;
      display: inline-block;
      font-weight: 600;
      font-size: 32 * $desk + vw;
      color: #043398;
      text-transform: lowercase;
      max-width: 200 * $desk + vw;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .license_name_overflow {
      position: relative;
      display: inline-block;
      font-weight: 600;
      font-size: 32 * $desk + vw;
      color: #043398;
      text-transform: lowercase;
      max-width: 200 * $desk + vw;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .tooltiptext {
      visibility: hidden;
      min-width: 120 * $desk + vw;
      background-color: #dddd;
      color: #043398;
      text-align: center;
      border-radius: 6 * $desk + vw;
      // padding: 10 * $desk + vw ;
      position: absolute;
      z-index: 1;
      bottom: 90%;
      left: 25%;
      margin-left: -60 * $desk + vw;
      display: none;
      transition: opacity 0.3s;
      font-size: 18 * $desk + vw;
    }

    .license_name .tooltiptext::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5 * $desk + vw;
      border-width: 5 * $desk + vw;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }

    .license_name:hover .tooltiptext {
      visibility: visible;
      display: block;
      z-index: 1;
    }
    .license_name {
    }
    .license_name::first-letter {
      text-transform: uppercase;
    }
    .createdlicense_partition_wrapper_user {
      // gap: 4vw !important;
    }
    .createdlicense_partition_wrapper_superadmin {
      // gap: 4.5vw !important;
    }
    .createdlicense_partition_wrapper {
      display: flex;
      // justify-content: space-between;
      gap: 1vw;
      // height: 12vh;
      .createdlicense_lhs {
        .duration_wrapper {
          display: flex;
          justify-content: space-between;
          width: 250 * $desk + vw;
          gap: 1vw;
          width: 16vw;
          // flex-direction: column;
          // height: 65* $desk + vw;

          .duration_wrapper_lhs {
          }
          .duration_wrapper_rhs {
          }
          .duration {
            font-weight: 500;
            font-size: 18 * $desk + vw;
            color: #848484;
          }
          .plan_type::first-letter {
            text-transform: uppercase;
          }
          .durationPlan_type,
          .quantityPlan_type {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 * $desk + vw 10 * $desk + vw;
            background: rgba(4, 51, 152, 0.15);
            border-radius: 18 * $desk + vw;
            color: #043398;
            font-size: 20 * $desk + vw;
            text-transform: capitalize;
            min-width: 8.8125vw;
          }
          .quantityPlan_type {
            min-width: 3vw;
          }
        }
        .btn {
          height: 32 * $desk + vw;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 16 * $desk + vw 0 0 0;
          font-size: 18 * $desk + vw;
          border-radius: 3 * $desk + vw;
          width: 6vw;
        }
        .createdlicense_button_wrapper {
          display: flex;
          gap: 0.5vw;
          .device_button {
            .btn {
              width: 10vw;
            }
          }
        }
      }
      .createdlicense_rhs {
        background-color: #fafafa;
        width: 280 * $desk + vw;
        padding: 20 * $desk + vw 40 * $desk + vw 0 * $desk + vw 50 * $desk + vw;
        .license_table {
          width: 200 * $desk + vw;

          .table_row {
            // width: 92* $desk + vw;
            // display: flex;
            // justify-content: space-between;
          }
          .table_header {
            // width: 115 * $desk + vw;
            font-size: 18 * $desk + vw;
            color: #848484;
          }
          .table_data {
            font-size: 16 * $desk + vw;
            color: #343434;
            // text-align: center;
            // width: 183* $desk + vw;
            border-bottom: 10 * $desk + vw solid red;
          }
        }
        .table_data {
          font-size: 16 * $desk + vw;
          color: #343434;
          // text-align: center;
          width: 5vw;
          text-transform: capitalize;
        }
      }
    }
  }
}
