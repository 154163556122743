@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);
._AddedApps_wrapper_ {
  position: relative;
  .purchased_data_lower_subwrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50vh;
    font-size: 32 * $desk + vw;

    // display: flex;
    // // flex-wrap: wrap;
    // align-items: center;
    // justify-content: center;
    // // width: 1431 * $desk + vw;
    // width: 100%;
    // height: 200 * $desk + vw;
    // border-radius: 14 * $desk + vw;
    // // border: 2 * $desk + vw solid #f4f7fe;
    // padding: 27 * $desk + vw 27 * $desk + vw 18 * $desk + vw 27 *
    //   $desk + vw;
    //   text-align: center;
    //   font-size: 32* $desk + vw;
  }
  .apptype_tag{
    font-size: 0.8333333333vw;
    color: #000000;
    margin-bottom: 0.2vw;
  }
  .apps_data_wrapper {
    display: flex;
    justify-content: center;
  }
  .apps_data {
    .ownedapps_noapps_dropdown {
      display: flex;
      justify-content: end;
      .drop_icon {
        right: 1vw;
      }
      .list_open {
        top: 35%;
      }
      .selected-text {
        display: flex;
        align-items: center;
        height: 2.34375vw;
    }
    }
    width: 100%;
    background-color: var(--background);
    // height: 500 * $desk + vw;
    border-radius: 12 * $desk + vw;
    overflow: hidden;
    // border: 2* $desk + vw solid #e7e7e7;
    padding: 20.5 * $desk + vw 36.07 * $desk + vw 40.28 * $desk + vw 45.8 *
      $desk + vw;
    .apps_data_wrapper {
      // border: 1* $desk + vw solid #F4F7FE;
      padding: 5vw;
      border-radius: 14 * $desk + vw;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      .apps_data_subwrapper {
        display: flex;
        flex-direction: column;
        width: 389 * $desk + vw;
        align-items: center;
        text-align: center;
        justify-content: space-evenly;
        gap: 1vw;
        .addapp_img {
          width: 200 * $desk + vw;
        }

        .apps_data_error {
          font-size: 1.6666666667vw;
          display: flex;
          height: 19vw;
          align-items: center;
          justify-content: center;
          color: var(--card-label);
          font-weight: 400;
        }
        .apps_data_btninfo {
          color: #848484;
          font-size: 19 * $desk + vw;
        }
        .btn {
          font-size: 21 * $desk + vw;
        }
      }
    }
  }
  .AddedApps_body {
    position: relative;
    height: 100%;
    .profile_bg {
      width: 100%;
    }
    .body_content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1500 * $desk + vw;
      top: 75 * $desk + vw;
      h1 {
        color: #fff;
        margin-bottom: 15 * $desk + vw;
        font-size: 32 * $desk + vw;
        letter-spacing: 2 * $desk + vw;
      }
      .addedapps_header {
        color: #fff;
        display: flex;
        width: 100%;
        height: 3.3333333333vw;
        justify-content: space-between;
        padding-right: 1.5vw;
        align-items: center;
        font-size: 1.6666666667vw;
        font-weight: 600;
        text-transform: capitalize;
      }
      .goBack {
        color: #fff;
        font-family: Poppins;
        font-size: 18 * $desk + vw;
        text-decoration: underline;
        margin-right: 1.5vw;
        cursor: pointer;
      }
      .AddedApps_data {
        width: 100%;
        background-color: var(--background);
        // height: 750 * $deskHeight + vh;
        border-radius: 12 * $desk + vw;
        overflow: hidden;
        border: 2 * $desk + vw solid #e7e7e7;
        .AddedApps_data_wrapper {
          .selected-text {
            display: flex;
            text-transform: capitalize;
            align-items: center;
          }
          .AddedApps_data_subwrapper {
            padding: 42 * $desk + vw 27 * $desk + vw 41 * $desk + vw 46 * $desk +
              vw;
            height: 69.4444444444vh;

            .AddedApps_data_upper_subwrapper {
              padding: 20 * $desk + vw 16 * $desk + vw 20 * $desk + vw 16 *
                $desk + vw;

              align-items: center;
              display: flex;
              justify-content: space-between;
              .drop_icon {
                right: 0.6vw !important;
              }
              .list_open {
                top: 37% !important;
              }

              .searchbar_filterby_wrapper {
                display: flex;
                // justify-content: space-around;
                width: 40vw;
                gap: 2vw;
                height: 2.1875vw;
                align-items: center;
                .filterby_wrapper {
                  .selected-text {
                    font-size: 15 * $desk + vw;
                    color: #fff;
                    width: 170 * $desk + vw;
                    display: flex;
                    justify-content: space-between;
                    // width: 179 * $desk + vw;
                    height: 45 * $desk + vw;
                    align-items: center;
                    text-transform: none;
                    // background: rgb(1, 58, 162);
                    background: linear-gradient(97deg, #013aa2, #2d62ed);
                  }

                  .active__ {
                    width: 8.8541666667vw;
                    z-index: 100;
                    // left: 6%;
                  }
                }

                .search_input_ {
                  width: 100% !important;
                  height: 100% !important;
                  font-size: 16 * $desk + vw;
                  color: #848484;
                }
                .search_wrapper_ {
                  border-radius: 20 * $desk + vw;
                  height: 2.5vw;
                  border: 2px solid #dddd;
                  box-shadow: none !important;
                  // box-shadow: 0 3* $desk + vw 6* $desk + vw rgba(0, 0, 0, 0.16),
                  // 0 3* $desk + vw 6* $desk + vw rgba(0, 0, 0, 0.23) ;
                }
                .active__ {
                  // width: 180 * $desk + vw;
                }
                ._text {
                  color: #fff;
                }
                .drop_icon {
                  // filter: invert(1);
                }
                .active__:after {
                  left: 85%;
                }
                .adedapps_addapp_btn {
                  .plus_addapp {
                    // width: 20 * $desk + vw;
                    // height: 30 * $desk + vw;
                  }
                  .btn {
                    font-size: 15 * $desk + vw;
                    height: 2.23vw;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    // border-radius: 5* $desk + vw;
                    border: 2px solid #dddd;
                  }
                }
            
              }
              .drop_icon {
                // transform: rotate(180deg);
              }
              .active__:after {
                left: 82%;
              }
              .active__ {
                z-index: 100;
              }
            }
            // .AddedApps_data_lower_subwrapper {
            //   // display: flex;
            //   flex-wrap: wrap;
            //   // width: 1431 * $desk + vw;
            //   width: 100%;
            //   height: 580 * $desk + vw;
            //   border-radius: 14 * $desk + vw;
            //   border: 2 * $desk + vw solid #f4f7fe;
            //   padding: 27 * $desk + vw 27 * $desk + vw 18 * $desk + vw 27 *
            //     $desk + vw;
            // }
            .purchased_data_subwrapper {
              display: flex;
              justify-content: end;
              flex-wrap: wrap;
              width: 100%;
              height: 30.2083333333vw;
              border-radius: 0.7291666667vw;
              // border: 0.1041666667vw solid #f4f7fe;
              padding: 1.40625vw 1.40625vw 0.9375vw 1.40625vw;

              .custom-select-container .active__ {
                top: 45 * $desk + vw;
              }
              .selected-text {
                display: flex;
                align-items: center;
                height: 45 * $desk + vw;
                margin-top: -6* $desk + vw;
                margin-left: 0.6875vw;
              }
            }
          }
        }
      }
    }
  }
  .purchased_data_subwrapper {
    .purchased_data_upper_subwrapper {
      .drop_icon {
        right: 1vw;
      }
      .list_open {
        top: 35%;
      }
    }
    
  }

  .preview_modal {
    .v_email {
      align-self: center !important;
      top: 5%;
      position: absolute;
      font-size: 20* $desk + vw;
    }
    .v_email:after {
      position: unset;
    }
    .btn{
      width: 404* $desk + vw;
      margin-bottom: 98.23 *$desk + vw;
    }
    .otp_desc{
      font-family: 'Work Sans';
      width: 25.791667vw;
      font-size: 14* $desk + vw;;
      color: #285de4 !important;

    }
    ._counter{
      color: var(--card-label);
    }
  }

  // .otp_main_wrapper {
  //   width: 530 * $desk + vw;
  //   height: 600 * $desk + vw;
  //   background-color: #ffffff;
  //   .otp_wrapper {
  //     .otp_subwrapper {
  //       padding: 24 * $desk + vw 60 * $desk + vw 98 * $desk + vw 60 * $desk + vw;
  //       .otp_upper_subwrapper {
  //         text-align: center;
  //         // width: 284 * $desk + vw;
  //         height: 130 * $desk + vw;
  //         .verify_otp {
  //           margin-bottom: 82 * $desk + vw;
  //         }
  //         .otp_sent {
  //           // width: 284 * $desk + vw;
  //         }
  //       }
  //       .otp_lower_subwrapper {
  //       }
  //     }
  //   }
  // }
}
