@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.basicDetails_subwrapper {
  // padding: 62 * $desk + vw 55 * $desk + vw 0 * $desk + vw 30 * $desk + vw;
  .Basic_tag {
    color: var(--primaryInvert);
    font-size: 22 * $desk + vw;
  }
}
