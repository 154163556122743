@use "sass:math";
$desk: math.div(100, 1920);

.confirm_password_wrapper {
  .modalAdjust_ {
    position: relative;
    padding: 35 * $desk + vw 30 * $desk + vw;
    padding-top: 20 * $desk + vw;
    width: 400 * $desk + vw;
    .sub {
      font-size: 20 * $desk + vw;
      font-family: "Poppins";
      font-weight: 500;
      margin-bottom: 35 * $desk + vw;
      text-align: center;
    }
    .btn {
      padding: 8 * $desk + vw 16 * $desk + vw;
      margin-top: 10 * $desk + vw;
    }
  }
}
