@use "sass:math";
$desk: math.div(100, 1920);

.card-wrapper {
  background-color: var(--sidenav);
  //   width: 404 * $desk + vw;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  // flex-direction: column;
  position: relative;
  padding: 35 * $desk + vw 46 * $desk + vw 0 * $desk + vw 36 * $desk + vw;
  border-radius: 10 * $desk + vw;
  overflow: hidden;

  .content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    .number {
      font-size: 36 * $desk + vw;
      font-weight: 600;
    }
    .text {
      font-size: 21 * $desk + vw;
      color: var(--text);
      opacity: 0.7;
      font-family: "Open Sans";
      max-width: 200 * $desk + vw;
    }
  }
  .geometrical-shape {
    // background-color: rgb(235, 96, 119);
    position: absolute;
    top: -3.8vw;
    right: -3vw;
    width: 11.020833vw;
    height: 11.020833vw;
    border-radius: 100%;
  }
  .photo {
    width: 40 * $desk + vw;
    align-self: center;
    z-index: 1;
  }
  svg {
    width: 45 * $desk + vw;
    align-self: center;
    position: absolute;
    bottom: 1.5vw;
    left: 3vw;
  }
}
