@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.ContentCard_main_wrapper {
  display: flex;
  gap: 1vw;
  .ContentCard_main_subwrapper {
    width: 100%;
    text-align: start;
    // margin-right: 20* $desk + vw;
    margin-top: 36 * $desk + vw;
    .ContentCard_tag {
      color: #848484;
      font-size: 16 * $desk + vw;
      // text-transform: capitalize;
    }

    .ContentCard_subwrapper {
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      margin-top: 10 * $desk + vw;
      padding: 20 * $desk + vw;
      background-color: #f4f7fe;
      border-radius: 10 * $desk + vw;
      display: flex;
      // height: 115* $desk + vw; 
      // justify-content: space-between;
      gap: 1vw;
      flex-wrap: wrap;
      font-size: 16 * $desk + vw;
      text-transform: capitalize;
      background: var(--background);
      border: 1px solid #848484;

      color: var(--data-label);

      .item{
        padding: 0* $desk + vw 10* $desk + vw;
      }
    }
    // .ContentCard_subwrapper:first-child{
    //   text-transform: capitalize;
    // }
    .ContentCard_item {
      padding: 4 * $desk + vw;
      text-align: center;

      border-radius: 10 * $desk + vw;
      color: #fff;
      justify-content: space-between;
      margin: 3 * $desk + vw;
      width: 130 * $desk + vw;
      background: linear-gradient(97deg, #013aa2, #2d62ed);
    }
  }
}
