@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.widget_wrapper {
  cursor: pointer;
  display: inline-block;
  margin-left: 6 * $desk + vw;
  .dots_options {
    font-weight: 400;
    font-size: 14 * $desk + vw;
    color: var(--text);
    text-align: center;
    padding: 7 * $desk + vw 0 * $desk + vw;
    border-bottom: 1 * $desk + vw solid #848484;
    padding: 7 * $desk + vw 7 * $desk + vw;

    .dots_options_disable {
      display: block;
    }
  }
  .dots_options:hover {
    color: #2d62ed;
  }
  .device_ddown {
    position: absolute;
    top: 0.8vw;
    right: -14 * $desk + vw;
    border-radius: 8 * $desk + vw;
    z-index: 5;
    opacity: 0;
    width: 120 * $desk + vw;
    visibility: hidden;
    transform: scale(0.01);
    transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s;
    transform-origin: right top;
    padding: 7 * $desk + vw 7 * $desk + vw;
    padding-bottom: 0;
    background-color: var(--background);
    box-shadow: var(--dd-box-shadow);
    // .dots_options {
    //   font-weight: 400;
    //   font-size: 10* $desk + vw;
    //   color: var(--text);
    //   text-align: center;
    //   padding: 7 * $desk + vw 0 * $desk + vw;
    //   border-bottom: 1* $desk + vw solid #f1f5ff;
    // }
  }
  .open {
    padding: 7 * $desk + vw 7 * $desk + vw;
    font-size: 14 * $desk + vw;
    // transform: scale(0.01);
    // transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s;
    // transform-origin: right top;
    border-radius: 8 * $desk + vw;
    position: absolute;
    width: 0 * $desk + vw;
    padding: 0;
    // height: 80 * $desk + vw;
    background-color: #ffffff;
    right: 2.5%;
    top: 0%;
    overflow: hidden;
    cursor: pointer;
    font-size: 14 * $desk + vw;
    transition: all 500ms ease-in-out;
    white-space: nowrap;
  }
  .active {
    width: 120 * $desk + vw;
    box-shadow: var(--dd-box-shadow);
    background: var(--body-background);
    // opacity: 1;
    // visibility: visible;
    // transform: scale(1);
    // transform-origin: right top;
    // // border: 2* $desk + vw solid #dddddd;
    // top: 18* $desk + vw;
    // z-index: 5;
    // &:after {
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   width: 0;
    //   height: 0;
    //   border-style: solid;
    //   top: -12* $desk + vw;
    //   border-color: transparent transparent transparent var(--background);
    //   border-width: 6* $desk + vw;
    //   transform: rotate(270deg);
    //   left: 76.5%;
    // }
  }
  margin-right: 33 * $desk + vw;
  margin-top: 27 * $desk + vw;
  .widget_main_wrapper {
    box-shadow: 0 3 * $desk + vw 6 * $desk + vw rgba(0, 0, 0, 0.16),
      0 3 * $desk + vw 6 * $desk + vw rgba(0, 0, 0, 0.23);
    // box-shadow: var(--box-shadow);

    margin-bottom: 3 * $desk + vw;
    width: 300 * $desk + vw;
    height: 207 * $desk + vw;
    border: 1 * $desk + vw solid #f6f9ff;
    // background-color: #ffffff;
    --background: #29313a;

    border-radius: 14 * $desk + vw;
    border: 1 * $desk + vw #f6f9ff;
    padding: 32 * $desk + vw 25 * $desk + vw 17 * $desk + vw 25 * $desk + vw;

    .widget_subwrapper {
      position: relative;
      .dots_svg {
        position: absolute;
        left: 88%;
        top: -3%;
        width: 5 * $desk + vw;
        height: 5 * $desk + vw;
      }
      .widget_upper_subwrapper {
        // .dot_icon_active{
        //   top: 50%;
        //     position: absolute;
        //     cursor: pointer;
        //     filter: contrast(0.5);
        //     z-index: 10 ;
        // }

        .widget_upper_subwrapper_content {
          width: 13vw;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 8 * $desk + vw;
          //   text-align: center;

          .widget_image {
            width: 91 * $desk + vw;
            height: 91 * $desk + vw;
            background-color: #f4f7fe;
            object-fit: cover;
            border-radius: 50%;
          }
          .appname_version {
            justify-content: center;
            display: flex;
            flex-direction: column;

            .widget_appname {
              width: 100 * $desk + vw;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 18 * $desk + vw;
              --primaryInvert: #f2f4f8;
              text-transform: lowercase;
            }
            .widget_appname::first-letter {
              text-transform: uppercase;
            }

            .version {
              font-size: 14 * $desk + vw;
              width: 136 * $desk + vw;
              color: #848484;

              // color: #343434d9;
              // white-space: nowrap;
              // overflow: hidden;
              // text-overflow: ellipsis;
              word-break: break-word;
            }
          }
        }
      }
      .widget_lower_subwrapper {
        .widget_lower_subwrapper_content {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          //   width: 266 * $desk + vw;
          //   margin-right: 13 * $desk + vw;
          border-right: 1px solid #70707033;
          border-left: 1px solid #70707033;
          .status_main_wrapper {
            width: 128 * $desk + vw;
            display: flex;
            flex-direction: column;
            text-align: center;
            font-size: 18 * $desk + vw;
            border-right: 1px solid #70707033;
            &::after {
              // content: "";
              background-color: #70707033;
              // border: 1* $desk + vw #70707033;
              position: relative;
              width: 1 * $desk + vw;
              height: 46 * $desk + vw;
              bottom: 56%;
              left: 111%;
              // display: block;
            }
            .status {
              text-transform: capitalize;

              // &::before {
              //     content: "";
              //     background-color: #70707033;
              //     // border: 1* $desk + vw #70707033;
              //     position: relative;
              //     width: 1 * $desk + vw;
              //     height: 46 * $desk + vw;
              //     top: 56%;
              //     left: -10%;
              //     display: block;
              //   }
            }
          }

          .status::first-letter {
            text-transform: uppercase;
            // color: red;
          }
          .recentstatus {
            font-size: 14 * $desk + vw;
            color: #848484;
            // text-transform: lowercase;
          }

          .downloads {
            font-size: 18 * $desk + vw;
            display: flex;
            flex-direction: column;
            text-align: center;
            width: 115 * $desk + vw;
            .downloads_content {
              color:#848484;
              font-size: 0.7291666666666666vw;
            }
          }
        }
      }
    }
  }
}
