@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);
._app_v_wrapper_ {
  position: relative;
  .app_v_body {
    position: relative;
    height: 100%;
    .profile_bg {
      width: 100%;
    }
    .body_content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1500 * $desk + vw;
      top: 75 * $desk + vw;
      padding-bottom: 1vw;
      // h1 {
      //   color: #fff;
      //   margin-bottom: 15 * $desk + vw;
      //   font-size: 32 * $desk + vw;
      //   letter-spacing: 2px;
      // }
      // .goBack {
      //   color: #fff;
      //   font-family: Poppins;
      //   font-size: 18 * $desk + vw;
      //   text-decoration: underline;
      //   margin-right: 1.5vw;
      //   cursor: pointer;
      // }
      .app_v_data {
        width: 100%;
        background-color: var(--background);
        min-height: 750 * $deskHeight + vh;
        border-radius: 12px;
        // overflow: hidden;
        border: 2px solid #e7e7e7;
        padding: 25 * $desk + vw;
        .sticky_wrapper {
          // position: sticky;
          // top: 25 * $desk + vw;
          // position: -webkit-sticky;
        }
        .app_header {
          font-size: 26 * $desk + vw;
          margin-bottom: 25 * $desk + vw;
          span {
            color: var(--primaryInvert);
          }
        }
        .steps {
          height: 150 * $desk + vw;
          background-color: var(--sidenav);
          border-radius: 8px 8px 0 0;
          background-color: red;
        }
        .element_type_wrapper {
          position: sticky;
          top: 0 * $desk + vw;
          position: -webkit-sticky;
          padding: 25 * $desk + vw;
          border: 2px solid var(--sidenav);
          background-color: var(--background);
          z-index: 2;
          border-bottom: 0;

          .element_types {
            border: 1px solid #ddd;
            border-radius: 8px;
            padding: 20 * $desk + vw;
            h2 {
              font-size: 18 * $desk + vw;
            }
            .element_Container {
              display: flex;
              flex-wrap: wrap;
              gap: 1vw;
              margin-top: 20 * $desk + vw;
              // margin-bottom: 20 * $desk + vw;
              .elements {
                border: 1px solid var(--primaryInvert);
                border-radius: 20px;
                font-size: 14 * $desk + vw;
                padding: 0.4vw 0.8vw;
                color: var(--primaryInvert);
                cursor: pointer;
                &:active {
                  animation: press 0.2s 1 linear;
                }
              }
            }
          }
        }
        .noData_wrapper {
          border: 2px solid var(--sidenav);
          background-color: var(--background);
          border-top: 0;
          padding: 1.3020833333vw;
          padding-top: 0;
          .noData-block {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border: 1px solid #ddd;
            border-radius: 8px;
            padding: 18 * $desk + vw;

            p {
              margin-top: 20 * $desk + vw;
              font-size: 18 * $desk + vw;
            }
          }
        }

        .element_preview_setting_container {
          padding: 1.3020833333vw;
          padding-top: 0;
          border: 2px solid var(--sidenav);
          border-top: 0;
          background-color: var(--background);

          .element_setting {
            display: inline-block;
            width: 49%;
            margin-right: 1%;
            border-radius: 12px;
            border: 1px solid #ddd;
            padding-top: 0.8vw;
            .dynamic_element_wrapper {
              border: 1px solid #ddd;
              padding: 15 * $desk + vw;
              border-radius: 12px;
              margin: 1vw;
              margin-bottom: 1.5vw;
              .d_label {
                font-size: 0.78125vw;
                color: var(--primaryInvert);
                font-weight: 600;
                margin-left: 0.21vw;
                margin-bottom: 0.21vw;
              }
              .tpggle_value {
                font-size: 0.78125vw;
                color: var(--primaryInvert);
                margin-left: 0.8vw;
              }
              .element_header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-bottom: 1.3020833333vw;
                background: var(--background);
                margin-top: -1.6vw;
                padding: 0 0.5vw;
                // display: flex;
                // justify-content: space-between;
                // align-items: center;
                // margin-bottom: 25 * $desk + vw;
                .element_type {
                  font-size: 0.85125vw;
                  color: var(--primaryInvert);
                  font-weight: 600;
                  span {
                    margin-left: 0.8vw;
                  }
                }
                .deleteIcon {
                  cursor: pointer;
                  width: 30 * $desk + vw;
                  height: 30 * $desk + vw;
                  &:active {
                    animation: press 0.2s 1 linear;
                  }
                }
                .line {
                  // width: 100%;
                  height: 2px;
                  background-color: #ddd;
                  flex-grow: 1;
                  margin: 0 13 * $desk + vw;
                }
                .eyeCloseIcon,
                .eyeOpenIcon {
                  width: 1.5vw;
                  height: 1.5vw;
                  fill: var(--text);
                  cursor: pointer;
                }
              }
              .element_static_info {
                padding: 0 20 * $desk + vw;
              }
              .textbox_wrapper {
                padding: 0 20 * $desk + vw;
              }
              .radiobtn_wrapper {
                .radio_options_wrapper {
                  .deleteIcon {
                    position: absolute;
                    left: unset;
                    right: 8 * $desk + vw;
                    cursor: pointer;
                    &:active {
                      animation: press 0.2s 1 linear;
                    }
                  }
                  .input_style {
                    padding-right: 2vw;
                  }
                }
              }
              ._flex {
                display: flex;
                .__input__ {
                  flex: 1;
                  &:first-child {
                    margin-right: 1vw;
                  }
                }
              }
            }
          }
          .element_preview {
            display: inline-block;
            position: sticky;
            width: 50%;
            // height: 200px;
            top: 8.71875vw;
            vertical-align: top;
            padding-bottom: 2vw;
            .canvas {
              width: 95%;
              height: 400 * $desk + vw;
              background-color: #ddd;
              margin: 0 auto;
              margin-top: 0.8vw;
              box-shadow: var(--box-shadow);
              border-radius: 9px;
              display: grid;
              place-content: center;
              .canvas_image {
                width: 90%;
                margin: 0 auto;
              }
            }
            .element_preview_block {
              .adjust_cbb {
                label:before {
                  background: #eef4fe;
                }

                .styled-checkbox:checked + label:before {
                  background: #4136f1;
                  margin-top: -1px;
                }
                .styled-checkbox:checked + label::after {
                  left: 4px;
                  top: 8px;
                }
              }
              .radio_group {
                display: flex;
                gap: 1vw;
              }
            }
          }
        }
      }
    }
  }
}
