@use "sass:math";
$desk: math.div(100, 1920);

.pagination_wrapper {
  // width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: var(--background);
  padding: 8px;
  border-radius: 50px;
  box-shadow: 0px 10px 15px rgb(0 0 0 / 10%);
  border-radius: 50px;
  border: 1px solid #f0f0f0;
  .active {
    background: linear-gradient(
      225deg,
      rgba(1, 58, 162, 1) 68%,
      rgba(45, 98, 237, 1) 88%
    );
    color: #fff;
  }
  li {
    color: var(--text);
    list-style: none;
    line-height: 3 * $desk + vw;
    text-align: center;
    font-size: 14 * $desk + vw;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s ease;
  }
  .num {
    list-style: none;
    height: 30 * $desk + vw;
    width: 30 * $desk + vw;
    margin: 0 3 * $desk + vw;
    line-height: 30 * $desk + vw;
    border-radius: 50%;
  }
  .next,
  .prev {
    // padding: 0 20 * $desk + vw;
    border-radius: 100%;
    border: 1px solid #f0f0f0;
    svg {
      /* transform: scale(1.2); */
      width: 0.4vw;
      vertical-align: middle;
    }
  }
  .prev {
    margin-right: 10px;
  }
  .next {
    margin-left: 10px;
  }
  #prev {
    path {
      stroke: var(--text);
    }
  }
  .dots {
    font-size: 22 * $desk + vw;
    height: 0.8vw;
    width: 0.8vw;
    cursor: default;
  }
  #next {
    transform: rotate(180deg) scale(1.2);
    path {
      stroke: var(--text);
    }
  }

  .previous {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 100;
    width: 100%;
    color: gray;
    text-align: center;
    height: 100%;
  }
  .__input_wrapper__ {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    .page_search {
      height: 70%;
      width: 4vw;
      margin: 0 0.5vw;
      outline: none;
      padding: 0.3vw;
      border-radius: 4px;
      border: 1.6px solid gray;
      font-weight: 600;
    }
    .input-label {
      font-size: 14 * $desk + vw;
    }

    .__arrow__ {
      position: absolute;
      width: 0.6vw;
      filter: brightness(0) invert(0);
      right: 0.8vw;
      transform: rotate(270deg);
    }
  }
  .__select_wrapper__ {
    position: relative;
    display: flex;
    align-items: center;

    .input-label {
      font-size: 14 * $desk + vw;
      margin: 0 5px;
    }
    select {
      font-size: 1rem;
      font-weight: normal;
      max-width: 100%;
      padding: 1px 24px 1px 10px;
      border: none;
      // background-color: transparent;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 2px solid gray;
      appearance: none;
      border-radius: 12%;
    }
    select:active,
    select:focus {
      outline: none;
      box-shadow: none;
    }
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 8px;
      width: 0;
      height: 0;
      margin-top: -2px;
      border-top: 5px solid #aaa;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
    }
  }
}
