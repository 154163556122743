@use "sass:math";
$desk: math.div(100, 1920);
.search_wrapper_ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //   width: 460 * $desk + vw;
  padding: 0 22 * $desk + vw;
  padding-left: 0vw;
  border-radius: 22px;
  box-shadow: var(--box-shadow);
  padding: 0.7vw 0;
  
  .search_input_ {
    width: 80%;
    height: 70%;
    margin-left: 25 * $desk + vw;
    font-size: 18 * $desk + vw;
    border: 0;
    outline: none;
    font-weight: 500;
    background-color: transparent;
    color: var(--text);

    &:disabled{
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
  .search_svg {
    width: 24 * $desk + vw;
    height: 24 * $desk + vw;
    margin-right: 1vw;
    #Ellipse_18 {
      stroke: var(--text);
    }
    #Rectangle_668 {
      fill: var(--text);
    }
  }
}

.search_loader {
  width: 25 * $desk + vw;
  height: 25 * $desk + vw;
  border-radius: 50%;
  margin-right: 1vw;
  display: inline-block;
  position: relative;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.5) 33%,
    var(--primary) 100%
  );
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.search_loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20 * $desk + vw;
  height: 20 * $desk + vw;
  border-radius: 50%;
  background: var(--background);
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
