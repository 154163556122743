@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.dot_icon_active{
    top: 50%;
      position: absolute;
      cursor: pointer;
      filter: contrast(0.5);
      z-index: 10 ;
  }

  .description_next_icon{
    // left: 15%;
    // // transform: translate(-25%);
    // position: absolute;
    // top: 0%;
    cursor: pointer;
    display: flex;
    filter: invert(1);

  }
  .ratings_next_icon{
    // position: absolute;
    // left: 19%;
    // transform: translateY(-50%);
    // top: 50%;
    cursor: pointer;
    display: flex;

    
  }
  .addedver_doubletick{
    position: absolute;
    left: 5%;
    top: 49%;
    transform: translateY(-50%);
    filter: invert(1);
    background-color: magenta;
    border-radius: 50%;
    width: 32* $desk + vw;
    height: 32* $desk + vw;
  }

  .verdt_rightAngle{
    left: 85%;
    position: absolute;
    top: 26%;
  }
  .suugestions_sad{
    width: 50px;
  }