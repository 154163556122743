@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);
._cart_wrapper_ {
  position: relative;
  .cart_body {
    position: relative;
    height: 100%;
    .profile_bg {
      width: 100%;
    }
    .body_content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1500 * $desk + vw;
      top: 75 * $desk + vw;
      h1 {
        color: #fff;
        margin-bottom: 15 * $desk + vw;
        font-size: 32 * $desk + vw;
        letter-spacing: 2px;
      }
      .goBack {
        color: #fff;
        font-family: Poppins;
        font-size: 18 * $desk + vw;
        text-decoration: underline;
        margin-right: 1.5vw;
        cursor: pointer;
      }
      .cart_data {
        width: 100%;
        background-color: var(--background);
        height: 750 * $deskHeight + vh;
        border-radius: 12px;
        overflow: hidden;
        border: 2px solid #e7e7e7;
        display: flex;
        padding: 30 * $desk + vw;
        .cart_item {
          flex: 7;
          background: var(--sidenav);
          border-radius: 22px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border: 1px solid #f0f0f0;
          overflow: hidden;
          .cart_adjust_table {
            .table_header {
              background: var(--sidenav);
              color: var(--primaryInvert);
              font-weight: normal;
              padding-top: 28 * $desk + vw;
              padding-bottom: 28 * $desk + vw;
              border-radius: 12px 0 0 0;
              font-size: 24 * $desk + vw;
              th {
                padding: 0 0.78125vw;
              }
            }
            .userInfo {
              display: flex;
              .user_avatar_img {
                // display: inline-block;
                width: 60 * $desk + vw;
                height: 60 * $desk + vw;
                border-radius: 12px;
                object-fit: cover;
                border: 1px solid #e1e1e1;
              }
              .meta {
                text-align: left;
                display: inline-block;
                margin-left: 10 * $desk + vw;
                p {
                  font-weight: 600;
                  font-size: 18 * $desk + vw;
                }
                p:first-child {
                  font-weight: 400;
                  font-size: 14 * $desk + vw;
                  opacity: 0.7;
                }
              }
            }

            td:first-child {
              // padding-left: 1.5vw;
            }
            td:nth-child(2){
              font-weight: bold;
            }
            td:nth-child(3) {
              // padding-left: 3.5vw;
            }
            .usertype {
              display: flex;
              align-items: center;
              justify-content: center;
              .type {
                padding: 4 * $desk + vw 15 * $desk + vw;
                background-color: var(--sidenav);
                border-radius: 8px;
                padding-left: 25 * $desk + vw;
                margin-left: -15 * $desk + vw;
              }
            }
            .remove {
              border: 2px solid #f0f0f0;
              padding: 8 * $desk + vw 15 * $desk + vw;
              // width: 50%;
              display: block;
              margin: 0 auto;
              cursor: pointer;
              border-radius: 8px;
              &:active {
                animation: press 0.2s 1 linear;
              }
            }
            .tbl-content {
              padding-bottom: 0.7vw;
              margin-top: 0;
              width: 100%;
            }
            .data_table {
              border-spacing: 0;
              background-color: var(--background);
              td {
                border-radius: 0;
                &:last-child {
                  &::after {
                    display: none;
                  }
                }
              }
              tr {
                &:hover {
                  td {
                    border-color: transparent;
                  }
                
                }
              }
            }
            .profile_image_container {
              position: relative;
              width: 3.427083vw !important;
              // height: 3.427083vw;
              border-radius: 50%;
              border: 1px solid #e1e1e1;
              background-color: var(--sidenav);
              padding: 0.6vw;
              .deviceIcon {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .cart_total {
          // flex: 2;
          border-top-right-radius: 22px;
          border-bottom-right-radius: 22px;
          overflow: hidden;
          border: 1px solid #f0f0f0;
          border-left: 0px solid #f0f0f0;
          h1 {
            font-size: 25 * $desk + vw;
            text-align: center;
            background: rgb(1, 58, 162);
            background: linear-gradient(97deg, #2d62ed, #013aa2);
            padding-top: 22 * $desk + vw;
            padding-bottom: 22 * $desk + vw;
            font-weight: 600;
            margin-bottom: 1vw;
          }
          .subtotal {
            display: flex;
            justify-content: space-between;
            font-size: 20 * $desk + vw;
            padding: 0 25 * $desk + vw;
            color: var(--primaryInvert);
          }
          .coupon {
            padding: 0 1vw;
            margin-top: 0.8vw;
            margin-bottom: 0.8vw;
            .__input__ {
              margin-bottom: 0.2375vw;
              label {
                font-size: 15 * $desk + vw;
                font-weight: unset;
              }
            }
            .btn {
              margin: 0;
              width: unset;
              font-size: 16 * $desk + vw;
              padding: 0 1vw;
              margin-left: 0.6vw;
              margin-top: 0.4vw;
            }
          }
          .total {
            background-color: var(--sidenav);
            margin-bottom: 2vw;
            .subtotal {
              padding: 0.5vw 1vw;
            }
          }
          .btn {
            margin: 0;
            margin-top: 0.8vw;
            border-radius: 0;
          }
        }
        .noData {
          display: grid;
          place-content: center;
          height: 100%;
          .emptyCartIcon {
            width: 375px;
            height: 375px;
            fill: var(--primaryInvert);
            stroke: var(--primaryInvert);
          }
          .cart_text {
            font-size: 22 * $desk + vw;
            text-align: center;
            margin: 1vw 0;
            color: var(--primaryInvert);
          }
          p {
            font-size: 18 * $desk + vw;
            text-align: center;
            color: #8f8f8f;
          }
        }
      }
    }
  }
}
