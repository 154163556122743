@use "sass:math";
$desk: math.div(100, 1920);

.device_card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 2 * $desk + vw solid #dae4f8;
  padding-top: 30 * $desk + vw;
  padding-bottom: 15 * $desk + vw;
  background-color: var(--background);
  .deviceIcon_wrapper {
    position: relative;
    display: grid;
    place-items: center;
    width: 98px;
    height: 98px;
    background-color: var(--device-card);
    border: 4 * $desk + vw solid #e1e1e1;
    border-radius: 60%;
    margin-bottom: 15 * $desk + vw;
  }
  .deviceIcon {
    width: 58px;
    height: 58px;
    fill: var(--text);
    line {
      stroke: var(--text);
    }
  }
  .d_name {
    color: var(--primaryInvert);
    font-family: "Poppins";
    font-weight: 600;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    // margin-top: 5 * $desk + vw;
  }
  .sname {
    font-size: 12px;
    font-weight: 400;
  }
  ._flex {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .infoIcon {
    width: 22px;
    height: 22px;
    margin-left: 13 * $desk + vw;
  }
  .dot_wrapper {
    position: absolute;
    top: 16 * $desk + vw;
    right: 16 * $desk + vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 16 * $desk + vw;
    cursor: pointer;
    background: var(--sidenav);
    width: 22px;
    height: 16px;
    padding: 0.2vw;
    border-radius: 6px;
    .dots {
      width: 4px;
      height: 4px;
      background-color: var(--text);
      border-radius: 50%;
    }
  }
  .customTheme {
    background-color: var(--sidenav) !important;
    color: var(--text) !important;
    padding: 6 * $desk + vw !important;
    max-width: 300 * $desk + vw !important;
    opacity: 1 !important;
    &.place-top {
      &:after {
        background-color: var(--sidenav) !important;
        width: 10 * $desk + vw !important;
        height: 10 * $desk + vw !important;
      }
      &:before {
        opacity: 0 !important;
      }
    }
    .ul_list {
      font-size: 14 * $desk + vw;
      text-transform: capitalize;
      li {
        border-bottom: 2px solid #ffffff;
        word-break: break-all;
      }
      li:last-child {
        border-bottom: 0;
      }
    }
  }

  .active_icon,
  .inactive_icon {
    position: absolute;
    bottom: 10px;
    width: 30px;
    height: 30px;
    left: 2px;
  }
  .device_ddown {
    position: absolute;
    top: 0.8vw;
    right: -14 * $desk + vw;
    border-radius: 8px;
    z-index: 5;
    opacity: 0;
    width: 120px;
    visibility: hidden;
    transform: scale(0.01);
    transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s;
    transform-origin: right top;
    padding: 7 * $desk + vw 7 * $desk + vw;
    padding-bottom: 0;
    background-color: var(--background);
    box-shadow: var(--dd-box-shadow);
    .dots_options {
      font-weight: 400;
      font-size: 10px;
      color: var(--text);
      text-align: center;
      padding: 7 * $desk + vw 0 * $desk + vw;
      border-bottom: 1px solid #f1f5ff;
    }
  }
  .active__ {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transform-origin: right top;
    // border: 2px solid #dddddd;
    top: 18px;
    z-index: 5;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      top: -12px;
      border-color: transparent transparent transparent var(--background);
      border-width: 6px;
      transform: rotate(270deg);
      left: 76.5%;
    }
  }
  .beta_test_wrapper {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin-top: 0.5vw;
    border-top: 2px solid var(--sidenav);
    // padding: 0 15 * $desk + vw;
    padding-top: 10 * $desk + vw;
    .label {
      color: var(--text);
      opacity: 0.7;
    }
    .beta-toggle,
    .renew {
      font-size: 12px;
      vertical-align: top;
      .btn {
        padding: 5 * $desk + vw 0;
        margin: 0;
        font-size: 14px;
        margin-top: 5px;
        width: 70px;
      }
    }
    .beta-toggle {
      .value {
        font-size: 14px;
        margin-left: 8px;
      }
      .toggle_container {
        width: 55px;
        height: 25px;
        .iconHolder {
          width: 26px;
          height: 26px;
          .tick {
            width: 14px;
            height: 10px;
          }
          .close {
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
}
