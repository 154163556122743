@use "sass:math";
$desk: math.div(100, 1920);

.checkbox_ {
  display: inline-block;
  .styled-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
      position: relative;
      cursor: pointer;
      font-size: 16 * $desk + vw;
      padding: 0;
    }

    // Box.
    & + label:before {
      content: "";
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 17px;
      height: 17px;
      // background: rgba(65, 54, 241, 0.5);
      background: #d8d6fc;

      border-radius: 7px;
      margin-top: 2px;
    }

    // Box hover
    &:hover + label:before {
      // background: #2d62ed;
    }

    // Box focus
    // &:focus + label:before {
    //   box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    // }

    // Box checked
    &:checked + label:before {
      background: #2d62ed;
    }

    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: "";
      position: absolute;
      left: 4px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
        4px -4px 0 white, 4px -6px 0 white, 0px 0px 0 white;
      transform: rotate(45deg);
    }
  }
}

.checkbox2_ {
  display: flex;
  align-items: center;
  cursor: pointer;
  .radio_wrapper {
    display: grid;
    place-items: center;
    width: 20 * $desk + vw;
    height: 20 * $desk + vw;
    background-color: #d8d6fc;
    border-radius: 100%;
    .circle_ {
      border-radius: 100%;
      width: 60%;
      height: 60%;
      border: 2px solid var(--primary);
    }
  }
  .radio_name {
    margin-left: 5 * $desk + vw;
    font-size: 16 * $desk + vw;
    max-width: 5vw;
    word-break: break-word;
  }
}
