@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.aboutdetails_main_wrapper {
  // width: 100px;
  // display: flex;
  // background-color: red;
  margin-bottom: 5 * $desk + vw;
  #content_3 {
    color: blue;
    cursor: pointer;
  }
  .aboutdetails_content {
    text-transform: lowercase;
  }
  .aboutdetails_content::first-letter{
    text-transform: uppercase;
  }
  .aboutdetails_subcontent{
    font-size: 20* $desk + vw;;
    text-transform: capitalize;

  }
  
}
