@use "sass:math";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600;700&family=Rubik&family=Source+Sans+Pro&family=Open+Sans:wght@400;600&family=Ubuntu&family=Work+Sans:wght@400;600;700&display=swap");
// https://fonts.googleapis.com/css?family=Roboto|Open+Sans:400,400i,600"  https://developers.google.com/fonts/docs/css2

$desk: math.div(100, 1920);

*,
body,
html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", "Segoe UI", -apple-system, BlinkMacSystemFont,
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  list-style-type: none;
  //   user-select: none;
}
body::-webkit-scrollbar {
  display: none;
}

.light-theme {
  --background: #fff;
  --primary: #013aa2;
  --secondary: #2e81ff;
  --text: #202020;
  --sidenav: #f6f9ff;
  --box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.35);
  --device-card: #f0f3ff;
  --body-background: #f0f3ff;
  --dd-box-shadow: 3px 0px 6px 2px rgba(0, 0, 0, 0.35);
  --device-filter: #f9f9f9;
  --label: #111b60;
  --primaryInvert: #013aa2;
  --color-accent-muted: rgba(56, 139, 253, 0.4);
  --color-accent-subtle: rgba(56, 139, 253, 0.15);
  --card-bg: #f6f7f8;
  --card-bg2: #edeef1;
  --card-label: #343434d9;
  --tr-shadow-greenbg: #e6ffe8;
  --tr-shadow-green: linear-gradient(
    94deg,
    rgba(78, 231, 142, 0) 0%,
    rgb(230, 255, 240) 39%
  );
  --tr-shadow-redbg: #ffe6e6;
  --tr-shadow-red: linear-gradient(
    94deg,
    rgba(231, 78, 78, 0) 0%,
    rgb(255, 230, 230) 39%
  );
  --background-header: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(243, 246, 255, 1) 23%
  );
}

.dark-theme {
  --background: #29313a;
  --sidenav: #242c35;
  --secondary: #2e81ff;
  --primary: #013aa2;
  --box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.35);
  // --device-card: #f0f3ff;
  --device-card: #181d23;
  --text: #f2f4f8;
  --body-background: #181d23;
  --dd-box-shadow: -8px 10px 28px -2px rgba(0, 0, 0, 0.55);
  --device-filter: #29313a;
  --label: #f2f4f8;
  --primaryInvert: #f2f4f8;
  --color-accent-muted: rgba(56, 139, 253, 0.4);
  --color-accent-subtle: rgba(56, 139, 253, 0.15);
  --card-bg: #242c35;
  --card-bg2: #181d23;
  --tr-hover: linear-gradient(
    94deg,
    rgba(48, 155, 219, 0) 0%,
    rgb(24, 29, 35) 39%
  );
  --tr-hover-shadow: #181d23;
  --card-label: #f2f4f8;
  --tr-shadow-redbg: rgb(81, 33, 33);
  --tr-shadow-red: linear-gradient(
    94deg,
    rgba(69, 42, 42, 0) 0%,
    rgb(81, 33, 33) 39%
  );
  --tr-shadow-greenbg: rgb(33, 81, 36);
  --tr-shadow-green: linear-gradient(
    94deg,
    rgba(42, 69, 54, 0) 0%,
    rgb(33, 81, 36) 39%
  );
  --background-header: linear-gradient(270deg, #1b232d 100% #181d23 19%);
}
body {
  background: var(--body-background);
  color: var(--text);
}
.input__error {
  text-align: left;
  border: 2px solid rgba(248, 78, 78, 0.7) !important;
  margin-bottom: 0;
}

.low_resolution_container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  color: #fff;
  font-size: 18px;
  width: 100vw;
  height: 100vh;
  user-select: none;
  background-color: #282828;
  .small_window {
    filter: brightness(0) invert(1);
    max-width: 100px;
    max-height: 100px;
    margin: 25px;
  }
}

.error_shake {
  position: relative;
  animation: shake 0.1s linear;
  animation-iteration-count: 3;
}

.routeModal {
  display: flex;
  width: 100%;
  align-items: center;
  .closeIcon {
    position: absolute;
    right: 1vw;
    top: 1vw;
    width: 12px;
    height: 12px;
    opacity: 0.5;
    cursor: pointer;
    path {
      fill: var(--text);
    }
  }
}
.toast_parent_container {
  // color: var(--notify);
  color: #6fcf97;
}

.toast_container {
  box-shadow: 0 2px 12px currentColor;
  // background-color: #{"rgba(var(--primary), 0.5)"};
  border: 1px solid currentColor;
  // color: currentColor;
  .notify_text {
    color: var(--text);
    margin: 0 1vw;
    margin-right: 3vw;
    word-break: break-all;
  }
}
@keyframes shake {
  0% {
    left: -5px;
  }
  100% {
    right: -5px;
  }
}

.slideInRight {
  animation-name: slideInRight;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

@keyframes slideInRight {
  0% {
    transform: translateX(400%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.slideOutLeft {
  animation-name: slideOutLeft;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    transform: translateX(-400%);
  }
}

.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes press {
  0% {
    scale: 1;
    // transform: scale(1);
  }
  50% {
    // transform: scale(0.92);
    scale: 0.92;
  }
  to {
    scale: 1;
    // transform: scale(1);
  }
}

.pass_container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 20vw;
  background: var(--body-background);
  align-self: center;
  // padding: 1rem 1.5rem;
  border-radius: 18px;
  .v_email {
    position: relative;
    align-self: baseline;
    margin: 0.5vw 0;
    margin-top: 0;
    font-size: 20 * $desk + vw;
    font-weight: normal;
    font-family: "Work Sans";
    &:after {
      position: absolute;
      left: 0;
      bottom: -3px;
      content: "";
      width: 40 * $desk + vw;
      height: 3px;
      background-color: #0779e4;
      border-radius: 5px;
    }
  }
  .otp_desc {
    color: #002465;
    font-size: 14 * $desk + vw;
    padding: 0 90 * $desk + vw;
    padding-top: 90 * $desk + vw;
    text-align: center;
  }
  .close_icon {
    position: absolute;
    right: 5px;
    top: 5px;
    color: var(--text);
    cursor: pointer;
  }
  .close_icon:active {
    animation: press 0.2s 1 linear;
  }
  .dropdown {
    margin-top: 0.4vw;
    position: relative;
    align-self: normal;
    p:first-child {
      padding-bottom: 0.5vw;
      padding-left: 0.5vw;
    }
    .dropdown-content {
      background-color: lightyellow;
      width: 100%;
      overflow: auto;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      padding: 0.5vw;
      border-radius: 1vw;
      font-size: 1vw;
      position: absolute;
      top: -0.4vw;
      z-index: 1;

      /* Add a red text color and an "x" when the requirements are wrong */
      .invalid {
        color: red;
      }
      .invalid:before {
        margin: 0.2vw 1vw;
        content: "✖";
      }

      /* Add a green text color and a checkmark when the requirements are right */
      .valid {
        color: green;
      }
      .valid:before {
        margin: 0.2vw 1vw;
        content: "✔";
      }
    }
  }
  .resend__ {
    text-align: center;
    margin: 45 * $desk + vw 0;
    font-size: 15 * $desk + vw;
    text-decoration: underline;
    span {
      cursor: pointer;
      color: #309bdb;
    }
  }
  .disabled {
    color: #aaaaaa !important;
    text-decoration: none !important;
    cursor: auto !important;
  }
  ._counter {
    font-size: 15 * $desk + vw;
    color: #000;
    text-align: center;
    // margin-top: 45 * $desk + vw;
    text-decoration: underline;
  }
}
.otpContainer {
  display: flex;
  justify-content: center;
  gap: 32 * $desk + vw;
  margin: 25 * $desk + vw 0 * $desk + vw;
  flex-wrap: wrap;
  .otpInput {
    width: 35 * $desk + vw;
    height: 35 * $desk + vw;
    border: 1px solid #309bdb;
    border-radius: 5px;
    outline-color: var(--primary);
    font-size: 16px;
    text-align: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
}

.close_otp_modal {
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 38px;
  height: 38px;
  padding: 10 * $desk + vw;
  background-color: #007dc6;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 18px;
  cursor: pointer;
  svg {
    width: 17px;
  }
}

$col-sky-top: #bbcfe1;
$col-sky-bottom: #e8f2f6;
$col-fg: #5d7399;
$col-blood: #dd4040;
$col-ground: #f6f9fa;

@mixin trees($direction, $size) {
  $shadow: ();

  @for $i from 1 through 16 {
    $space: $size * 1.2;
    $rand: (random(20) / 10 - 1) * 50px;
    $shadow: append(
      $shadow,
      ($direction * $i * $space + $rand)
        (($direction * -$i * $space) + $rand)
        15px
        lighten($col-fg, random(20) + 10%),
      comma
    );
  }

  box-shadow: $shadow;
}

.page404-content {
  height: 100vh;
  position: relative;
  z-index: 1;
  background-color: mix($col-sky-top, $col-sky-bottom);
  background-image: linear-gradient(
    to bottom,
    $col-sky-top 0%,
    $col-sky-bottom 80%
  );
  overflow: hidden;

  .page404-snow {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 20;
  }

  .page404-main-text {
    padding: 20vh 20px 0 20px;

    text-align: center;
    line-height: 2em;
    font-size: 5vh;
  }

  .page404-home-link {
    font-size: 0.6em;
    font-weight: 400;
    color: inherit;
    text-decoration: none;
    display: inline;
    cursor: pointer;
    opacity: 0.6;
    border-bottom: 1px dashed transparentize($col-fg, 0.5);

    &:hover {
      opacity: 1;
    }
  }

  .page404-ground {
    height: 160px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

    background: $col-ground;
    box-shadow: 0 0 10px 10px $col-ground;

    $treeSize: 250px;
    &:before,
    &:after {
      // Trees
      content: "";
      display: block;
      width: $treeSize;
      height: $treeSize;
      position: absolute;
      top: -$treeSize/4;

      z-index: -1;
      background: transparent;
      transform: scaleX(0.2) rotate(45deg);
    }

    &:after {
      left: 50%;
      margin-left: -$treeSize / 1.5;
      @include trees(-1, $treeSize);
    }

    &:before {
      right: 50%;
      margin-right: -$treeSize / 1.5;
      @include trees(1, $treeSize);
    }
  }

  .page404-mound {
    margin-top: -80px;

    font-weight: 800;
    font-size: 180px;
    text-align: center;
    color: $col-blood;
    pointer-events: none;

    $from-top: 50px;

    &:before {
      $w: 600px;
      $h: 200px;

      content: "";
      display: block;
      width: $w;
      height: $h;
      position: absolute;
      left: 50%;
      margin-left: -$w/2;
      top: $from-top;
      z-index: 1;

      border-radius: 100%;
      background-color: $col-sky-bottom;
      background-image: linear-gradient(
        to bottom,
        lighten($col-sky-top, 10%),
        $col-ground 60px
      );
    }

    &:after {
      // Blood
      $w: 28px;
      $h: 6px;
      content: "";
      display: block;
      width: $w;
      height: $h;
      position: absolute;
      left: 50%;
      margin-left: -150px;
      top: $from-top + 18px;

      z-index: 2;
      background: $col-blood;
      border-radius: 100%;
      transform: rotate(-15deg);
      box-shadow: -($w * 2) ($h * 2) 0 1px $col-blood,
        -($w * 4.5) ($h) 0 2px $col-blood, -($w * 7) ($h * 4) 0 3px $col-blood;
    }
  }

  .page404-mound_text {
    transform: rotate(6deg) translateY(-55px);
  }

  .page404-mound_spade {
    $handle-height: 30px;

    display: block;
    width: 35px;
    height: 30px;
    position: absolute;
    right: 50%;
    top: 42%;
    margin-right: -250px;

    z-index: 0;
    transform: rotate(35deg);
    background: $col-blood;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
    }

    &:before {
      width: 40%;
      height: $handle-height;
      bottom: 98%;
      left: 50%;
      margin-left: -20%;

      background: $col-blood;
    }

    &:after {
      width: 100%;
      height: 30px;
      top: -$handle-height - 25px;
      left: 0%;
      box-sizing: border-box;

      border: 10px solid $col-blood;
      border-radius: 4px 4px 20px 20px;
    }
  }
}

.loading_text {
  .loading {
    font-size: 20 * $desk + vw;
    font-weight: 800;
    text-align: center;
    margin: 0.6em 0.2em;
    ._text_ {
      display: inline-block;
      margin: 0 -0.05em;
    }
  }
  .loading03 {
    ._text_ {
      margin: 0 0.2em;
      animation: loading03 0.7s infinite alternate;
      @for $i from 1 through 6 {
        &:nth-child(#{$i + 1}) {
          animation-delay: #{$i * 0.1}s;
        }
      }
    }
  }
  @keyframes loading03 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
}

.Toastify_adjust {
  top: 7em !important;
  font-size: 14px !important;
  .Toastify__toast-icon {
    width: 25px !important;
  }
  .Toastify__toast--success {
    background: var(--background) !important;
    box-shadow: 0 2px 12px #6fcf97;
  }
  .Toastify__toast--error {
    background: var(--background) !important;
    box-shadow: 0 2px 12px #d73e3e;
  }
  .Toastify__close-button {
    svg {
      path {
        fill: var(--text);
      }
    }
  }
}
