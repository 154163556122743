@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.VersionAppDetails_wrapper{
    // width: 162 * $desk + vw;
    .ver_appimage{
        width: 101 * $desk + vw;
        height: 101 * $desk + vw;
        border-radius: 50%;
    }
    .ver_aapname{
        text-transform: capitalize;
        font-size: 22 * $desk + vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .ver_status{
        text-transform: capitalize;
        font-size: 18* $desk + vw;
    }
}