@use "sass:math";
$desk: math.div(100, 1920);

.__auth_reg_developer__ {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #f8faff;
  overflow: hidden;
  .d_data {
    padding: 1.0416666667vw 115 * $desk + vw;
    padding-right: 2.989583vw;
    padding-top: 50 * $desk + vw;
  }
  .sub {
    font-size: 14 * $desk + vw;
    font-family: "Poppins";
    margin-bottom: 35 * $desk + vw;
  }
  .radio-header {
    // margin-top: 40 * $desk + vw;
    margin-bottom: 12 * $desk + vw;
    font-weight: 600;
    font-size: 15 * $desk + vw;
    color: #111b60;
  }
  ._star {
    &::after {
      content: "*";
      color: red;
      margin-left: 0.5vw;
      font-weight: 400;
    }
  }
  .social_links_wrapper {
    ._flex {
      display: flex;
      align-items: center;
    }
    .mainopShadow {
      width: 64px;
      height: 64px;
      border-radius: 32px;
      position: absolute;
      right: 50px;
      bottom: 50px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
    .fab {
      background-color: transparent;
      height: 26 * $desk + vw;
      width: 26 * $desk + vw;
      border-radius: 32px;
      transition: width 400ms;
      transition-timing-function: ease;
      position: relative;
      margin-left: 1vw;
      // right: 50px;
      // bottom: 50px;
      text-align: center;
      overflow: hidden;
      // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5), 0 1px 2px rgba(0, 0, 0, 0.64);
      .mainop {
        display: grid;
        place-items: center;
        width: 26 * $desk + vw;
        height: 26 * $desk + vw;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: transform 400ms;
        background-color: var(--primary);
        cursor: pointer;
        border-radius: 32px;
        z-index: 6;
        svg {
          width: 18 * $desk + vw;
          height: 18 * $desk + vw;
        }
        i {
          margin-top: 16px;
          font-size: 32px;
          color: #fff;
        }
      }
      &:hover {
        width: 210px;
      }
      &:hover .mainop {
        transform: rotate(135deg);
      }
      .minifab {
        position: relative;
        display: grid;
        place-items: center;
        width: 24 * $desk + vw;
        height: 24 * $desk + vw;
        border-radius: 24px;
        z-index: 5;
        float: left;
        margin-top: 2px;
        cursor: pointer;
        margin-right: 8px;
        background-color: white;
        transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5), 0 1px 2px rgba(0, 0, 0, 0.64);
        &:hover {
          transform: scale(1.2);
          // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
          //   0 3px 6px rgba(0, 0, 0, 0.23);
        }
        .minifabIcon {
          height: 24px;
          width: 24px;
          margin-top: 12px;
        }
        svg {
          width: 80%;
          height: 80%;
        }
      }
    }
    .social-input {
      position: relative;
      width: 100%;
      display: flex;
      border: 2px solid #ddd;
      border-radius: 12px;
      overflow: hidden;
      margin-bottom: 20 * $desk + vw;
      .social_icon {
        padding: 9 * $desk + vw;
        border-right: 2px solid #ddd;
        width: 2.09375vw;
        svg {
          width: 100%;
          height: 100%;
          vertical-align: middle;
        }
      }
      .input_style {
        border: 0;
        width: 100%;
        outline: none;
        padding-left: 13 * $desk + vw;
        padding-right: 45 * $desk + vw;
        font-size: 14 * $desk + vw;
        font-family: Work Sans;
      }
      .close_ {
        position: absolute;
        right: 10 * $desk + vw;
        cursor: pointer;
        top: 55%;
        transform: translateY(-50%) rotate(45deg);
        svg {
          width: 20 * $desk + vw;
          height: 20 * $desk + vw;
          path {
            stroke: var(--primary);
          }
        }
      }
    }
    .social-input:last-child {
      margin-bottom: 0;
    }
  }
  .recaptcha {
    margin-top: 20 * $desk + vw;
    margin-bottom: 15 * $desk + vw;
  }
  ._tos {
    display: flex;
    margin-left: 1vw;
    font-family: Work Sans;
    font-size: 13 * $desk + vw;
    width: 90%;
    margin-bottom: 1vw;
    margin-top: 1vw;

    .adjust_cb {
      label:before {
        background: #eef4fe;
        border: 2px solid #72778f;
        width: 13px;
        height: 13px;
        margin-top: 0;
      }

      .styled-checkbox:checked + label:before {
        background: #2d62ed;
        width: 13px;
        height: 13px;
      }
      .styled-checkbox:checked + label::after {
        left: 4px;
        top: 7px;
      }
    }
    p {
      font-family: "Work Sans";
      color: #72778f;
    }
    span {
      cursor: pointer;
      font-family: "Work Sans";
      color: #0779e4;
    }
  }

  .adjust_cbb {
    label:before {
      background: #eef4fe;
    }

    .styled-checkbox:checked + label:before {
      background: #4136f1;
    }
    .styled-checkbox:checked + label::after {
      left: 4px;
      top: 9px;
    }
  }
  .noBank {
    color: red;
    font-size: 16 * $desk + vw;
    margin: 0.8vw 0;
  }
  .fc-header {
    font-size: 20 * $desk + vw;
    color: #111b60;
    font-weight: 600;
  }
  .contact_person {
    // margin-bottom: 1.5vw;
    .__input__ {
      width: 48%;
      text-transform: capitalize;
      margin-bottom: 0;
    }
  }
  .country_section {
    position: relative;
    width: 100%;
    margin-top: 1vw;
    .suggestions {
      position: absolute;
      width: 47%;
      top: 92%;
      border-radius: 5px;
      border: 2px solid #dddddd;
      background-color: #fff;
      z-index: 5;
      overflow: hidden;
      margin-left: 1%;
      .suggestion_item {
        // margin: 0 5 * $desk + vw;
        border-bottom: 1px solid #ddd;
        padding: 8 * $desk + vw;
        padding-left: 5 * $desk + vw;
        padding-right: 5 * $desk + vw;
        font-size: 14 * $desk + vw;
        font-weight: 400;
        cursor: pointer;
      }
      .suggestion_item:last-child {
        border-bottom: 0;
      }
    }
    .active_flag {
      background-color: var(--primary);
      color: #fff;
    }
  }
  .bank_detail {
    display: flex;
    width: 100%;
    margin-top: 1vw;
    flex-wrap: wrap;
    gap: 1vw;
    .phone-ib {
      width: 48%;
    }
    .__input__ {
      width: 48%;
      text-transform: capitalize;
      margin-bottom: 0;
    }

    // .left-section {
    //   width: 50%;
    //   margin-right: 0.625vw;
    // }
    // .right-section {
    //   width: 50%;
    //   margin-left: 0.625vw;
    .adjust_dd {
      text-transform: capitalize;
      width: 48%;
      margin-left: 0.25vw;
      .selected-text {
        background-color: white;
        // height: 54.6%;
        border-radius: 0.6770833333vw;
      }
      button {
        height: 44 * $desk + vw !important;
      }
    }
    // }
  }
}
