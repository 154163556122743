@use "sass:math";
$desk: math.div(100, 1920);

.toggle_container {
  position: relative;
  background-color: var(--device-card);
  width: 55 * $desk + vw;
  height: 25 * $desk + vw;
  border-radius: 12px;
  cursor: pointer;

  .iconHolder {
    position: absolute;
    display: grid;
    place-content: center;
    width: 26 * $desk + vw;
    height: 26 * $desk + vw;
    border-radius: 100%;
    border: 2px solid #fff;
    // top: -1 * $desk + vw;
    top: 0 * $desk + vw;
    box-shadow: var(--dd-box-shadow);
    // left: 0;
    .tick {
      width: 14 * $desk + vw;
      height: 10 * $desk + vw;
    }
    .close {
      width: 10 * $desk + vw;
      height: 10 * $desk + vw;
    }
  }
}

[aria-disabled="true"] {
  opacity: 0.5;
  cursor: not-allowed;
}
