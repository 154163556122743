@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);
.assigncard_main_wrapper {
  width: 300 * $desk + vw;
  height: 6vw;
  background-color: #fff;
  padding: 20 * $desk + vw;
  border-radius: 10 * $desk + vw;
  margin: 5 * $desk + vw;
  .center {
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
      -3px 2px 6px rgba(0, 0, 0, 0.08);
    width: 1vw;
    height: 1vw;
    border-radius: 50%;
    margin-left: 102%;
    margin-top: -10%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    font-size: 0.6vw;
  }

  .assigncard_subwrapper {
    .assigncard_partition {
      width: 13vw;
      justify-content: space-between;
      display: flex;
      font-size: 18 * $desk + vw;
      .assigncard_lhs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 3vw;
        .attName {
          font-size: 18 * $desk + vw;
          color: #848484;
        }
        .__input__ {
          margin-bottom: 0;
        }
        .input_style {
          width: 80 * $desk + vw;
          border: none;
          padding: 3 * $desk + vw;
          font-size: 18 * $desk + vw;
          font-weight: 400;
          border-radius: 5 * $desk + vw;
          margin-top: 0;
          margin-left: -3* $desk + vw;
          border: 1* $desk + vw solid #043398;
        }
        .attUnderName {
          font-size: 16 * $desk + vw;
          font-weight: 600;
          color: #043398;
          text-transform: capitalize;
        }
        .attUnderName::first-letter {
          text-transform: uppercase;
        }
      }
      .assigncard_rhs {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .attQuantity {
          font-size: 18 * $desk + vw;
          color: #848484;
        }
        .attUnderQuantity {
          font-size: 16 * $desk + vw;
          font-weight: 600;
          color: #043398;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 40 * $desk + vw;
        }
        .editpen {
          cursor: pointer;
        }
        .assigncard_rhs_content {
          display: flex;
          width: 50 * $desk + vw;
          justify-content: space-between;
          .edit_wrapper {
            display: flex;
            align-items: center;
            width: 300 * $desk + vw;
            justify-content: space-between;
            margin-left: 4 * $desk + vw;
          }
          .edit_quantity {
            .input_style {
              margin-top: 2 * $desk + vw;
              width: 70 * $desk + vw;
              height: 35 * $desk + vw;
              margin-bottom: 0 * $desk + vw;
              margin-left: -2 * $desk + vw;
              position: relative;
            }
            .create_license_tick {
              position: absolute;
              width: 35 * $desk + vw;
              left: 69%;
            }
          }
        }
      }
    }
  }
}
