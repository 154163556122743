@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.mfr_count_wrapper {
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: -80* $desk + vw;
  .td_edit_count {
    display: flex;
    // width: 130* $desk + vw;
    align-items: center;
    justify-content: space-between;
    .edit_count_tick {
      width: 25 * $desk + vw;
      margin-left: 0.5vw;
    }
    .__input__ {
      margin-bottom: 10 * $desk + vw;
      margin-right: 2 * $desk + vw;
    }
    .__input__ .input_style {
      // margin-left: 6 * $desk + vw;
      // margin-right: 6 * $desk + vw;
      width: 2.604167vw;
    }
    .total {
      margin-left: 6 * $desk + vw;

      // width: 50* $desk + vw;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }
  .mfr_count {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 2vw;
    margin-left: 1.729167vw;
    text-align: left;
  }
}
