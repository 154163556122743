@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.usecase_description_wrapper {
  font-size: 20* $desk + vw;
  position: relative;

  .about {
    // margin-top: 3vw;
    // margin-bottom: 1vw;
  }
  // .next_icon{
  //     position: absolute;
  //     left: 16%;
  // }
  .description:first-letter {
    text-transform: capitalize !important;
    // color: red !important;
  }
  .decription {
    text-transform: capitalize;
    text-align: start;
    margin-bottom: 1vw;
    word-break: break-all;
  }
  .usecase_about_wrapper {
    position: relative;
    margin-top: 1vw;
    margin-bottom: 0.5vw;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 200* $desk + vw;
    font-size: 20* $desk + vw;
    align-items: center;
    // .description_next_icon{
    //   position: absolute;
    //   top: 0;
    //   left: 170 * $desk + vw;
    // }
  }
}

.usecase_updated_wrapper {
  font-size: 20* $desk + vw;
  
}
.usecase_targetinds_wrapper {
  text-transform: capitalize;
  display: flex;
  gap: 1vw;
  margin-bottom: 1vw;
  font-size: 20* $desk + vw;

  .industries_tag {
    width: 9.770833vw;
    // height: 32 * $desk + vw;
    padding: 5 * $desk + vw;
    color: #fff;
    background: linear-gradient(97deg, #013aa2, #2d62ed);
    border-radius: 10 * $desk + vw;
    text-align: center;
  }
}
