@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);
.appname_error {
  text-align: center;
  font-size: 12 * $desk + vw;
  color: red;
  margin-left: 48.5%;
  text-align: center;
  width: 17.020833vw;
  display: flex;
  justify-content: center;
  margin-top: -15 * $desk + vw;
}
.pricing_error {
  text-align: center;
  font-size: 12 * $desk + vw;
  color: red;
  margin-left: 22.5%;
  text-align: center;
  width: 17.020833vw;
  display: flex;
  justify-content: center;
}
.addapp_label {
  // display: inline-block;
  text-transform: lowercase;
  // color: #fff;
  // display: flex;
  // width: 100%;
  // // height: 3.3333333333vw;
  // // justify-content: space-between;
  // padding-right: 1.5vw;
  // align-items: center;
  // font-size: 1.6666666667vw;
  // text-transform: capitalize;
}
.addapp_label:first-letter {
  text-transform: uppercase;
  // color: red;
}
._addapp_wrapper_ {
  .asterisk {
    font-size: 13 * $desk + vw;
    position: absolute;
    color: red;
    left: 15%;
    top: 14.9%;
  }
  position: relative;
  .addapp_body {
    position: relative;
    height: 100%;
    .profile_bg {
      width: 100%;
    }
    .body_content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1500 * $desk + vw;
      top: 75 * $desk + vw;
      .basic_details_text {
        text-transform: capitalize;
        color: #fff;
        margin-bottom: 0.5208333333vw;
        font-size: 1.6666666667vw;
      }
      h1 {
        color: #fff;
        margin-bottom: 15 * $desk + vw;
        font-size: 32 * $desk + vw;
        letter-spacing: 2 * $desk + vw;
      }
      .goBack {
        color: #fff;
        font-family: Poppins;
        font-size: 18 * $desk + vw;
        text-decoration: underline;
          margin-left: 73.5vw;
        cursor: pointer;
        position: absolute;
        top: 15 * $desk + vw;
        left: -10 * $desk + vw;
      }
      .addapp_data {
        padding: 50 * $desk + vw 95 * $desk + vw 42 * $desk + vw 95 * $desk + vw;
        width: 100%;
        background-color: var(--background);
        height: 750 * $deskHeight + vh;

        border-radius: 12 * $desk + vw;
        overflow: hidden;
        border: 2 * $desk + vw solid #e7e7e7;

        .addapp_data_main_wrapper {
          align-items: center;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100vh;

          .addapp_data_subwrappers {
            display: flex;
            // flex-direction: row;
            width: 100%;
            height: 100%;
            gap: 7vw;
            // justify-content: space-between;
            // .__input__ {
            //   label {
            //     &::after {
            //       content: "*";
            //       color: red;
            //       margin-left: 0.5vw;
            //       font-weight: 400;
            //     }
            //   }
            // }

            .input_style {
              // margin-bottom: 31 * $desk + vw;
            }

            .__input__ label {
              margin-bottom: 5 * $desk + vw;
              // color: rgba(52, 52, 52, 0.8509803922);
              color: #848484;
              font-weight: normal;
            }
            .addapp_data_subwrapper1 {
              display: flex;
              // gap: 63* $desk + vw;
              width: 569 * $desk + vw;
              height: 100%;
              // flex: 1;
              justify-content: space-evenly;
              flex-direction: column;

              .addapp_appname_subwrapper {
                label {
                  &::after {
                    content: "*";
                    color: red;
                    margin-left: 0.5vw;
                    font-weight: 400;
                  }
                }
                .input_style {
                  border: 1 * $desk + vw solid #dddddd;
                  margin-bottom: 8 * $desk + vw;
                  border-radius: 10 * $desk + vw;
                  color: var(--data-label);
                  background: var(--background);
                }
              //   .input__error {
              //     // text-align: left;
              //     border: 2* $desk + vw solid rgba(248, 78, 78, 0.7) !important;
              //     margin-bottom: 0;
              // }
              }

              .addapp_description_wrapper {
                font-size: 0.78125vw;
                margin-bottom: 21 * $desk + vw;
                .addapp_description_label{
                  color: #848484;
                }
                label {
                  &::after {
                    content: "*";
                    color: red;
                    margin-left: 0.5vw;
                    font-weight: 400;
                  }
                }

                textarea {
                  resize: none;
                }
                .empty_error {
                  height: 6vw;
                  width: 100%;
                  border-radius: 10 * $desk + vw;
                  text-align: left;
                  border: 2px solid rgba(248, 78, 78, 0.7) !important;
                  margin-bottom: 0;
                }
                .addapp_description_content {
                  background: var(--background);
                  margin-top: 5 * $desk + vw;
                  width: 100%;
                  height: 110 * $desk + vw;
                  font-size: 0.6770833333vw;
                  padding: 12 * $desk + vw;
                  border: 1 * $desk + vw solid #dddddd;
                  border-radius: 10 * $desk + vw;
                  color: var(--data-label);
                }
              }
              .addapp_targetindustries_wrapper {
                font-size: 16 * $desk + vw;
                // margin-bottom: 25 * $desk + vw;

                .target_label_name {
                  color: #848484;
                  label {
                    &::after {
                      content: "*";
                      color: red;
                      margin-left: 0.5vw;
                      font-weight: 400;
                    }
                  }
                }

                .addapp_targetindustries_content {
                  // height: 8.302083vw;
                  // font-size: 16 * $desk + vw;
                  // padding: 12 * $desk + vw;
                  margin-top: 10 * $desk + vw;
                  // border: 1 * $desk + vw solid #dddddd;
                  // border-radius: 10 * $desk + vw;

                  .marquee {
                    animation: none;
                  }

                  .custom-multi-select-container {
                    bottom: 2%;
                    left: 0%;
                    width: 100% !important;
                    height: 50%;

                    .select-options {
                      bottom: 108%;
                    }
                  }
                  .select-options:after {
                    top: -11% !important;
                  }
                  .select-options:before {
                    top: 0% !important;
                  }

                  .selected_filter {
                    margin-top: 0.000001vw;
                    // margin-top: 40 * $desk + vw;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 15 * $desk + vw;
                    width: 25vw;
                    .filter_tab {
                      align-items: center;
                      background: var(--device-filter);
                      border-radius: 2 * $desk + vw;
                      color: var(--text);
                      display: flex;
                      list-style: none;
                      // margin-right: 40 * $desk + vw;
                      border-radius: 20 * $desk + vw;
                      padding-left: 9 * $desk + vw;
                      padding-top: 4 * $desk + vw;
                      padding-bottom: 4 * $desk + vw;
                      padding-right: 8 * $desk + vw;
                      border: 1 * $desk + vw solid #e7e7e7;
                      // margin-bottom: 10 * $desk + vw;
                      p {
                        font-size: 12 * $desk + vw;
                        margin-right: 20 * $desk + vw;
                        font-weight: 600;
                      }
                      svg {
                        width: 20 * $desk + vw;
                        height: 20 * $desk + vw;
                        cursor: pointer;
                      }

                      .closeIcon {
                        display: none;
                        background: linear-gradient(
                          321deg,
                          rgba(221, 30, 66, 1) 5%,
                          rgba(162, 0, 0, 1) 73%
                        );
                        padding: 0.17vw;
                        border-radius: 100%;
                        width: 1vw;
                        height: 1vw;
                        border: 1 * $desk + vw solid var(--text);
                      }
                      &:hover {
                        .tickIcon2 {
                          display: none;
                        }
                        .closeIcon {
                          display: block;
                        }
                      }
                    }
                  }
                //   .input__error {
                //     border: 2 * $desk + vw  solid rgba(248, 78, 78, 0.7) !important;
                //     margin-bottom: 0;
                // }
                }
              }
            }

            .addapp_data_subwrapper2 {
              // flex: 1;
              width: 569 * $desk + vw;
              height: 100%;

              .addapp_selector {
                font-size: 16 * $desk + vw;
             
                label {
                  &::after {
                    content: "*";
                    color: red;
                    margin-left: 0.5vw;
                    font-weight: 400;
                  }
                }

                .addapp_selector_wrapper {
                  display: flex;
                }

                .selector_mainwrapper {
                  margin-top: 0.2vw;
                  margin-bottom: 1.9vw;
                }
                  .addapp_selector_sent{
                    color: #848484;
                  font-size: 0.8vw;

                  }
                // .addapp_selector_sent:first-letter {
                //   text-transform: capitalize
                // }
              }
              // .typeofservice_content_wrapper {
              //   font-size: 18 * $desk + vw !important;
              //   // width: 500 * $desk + vw;
              //   // height: 19 * $desk + vw;
              //   margin-top: 26 * $desk + vw;
              //   margin-bottom: 2.354167vw;
              //   display: flex;
              //   justify-content: center;
              //   gap: 115 * $desk + vw;

              //   .usecase_wrapper {
              //     font-size: 18 * $desk + vw;
              //     gap: 5 * $desk + vw;
              //     align-items: center;
              //     display: flex;
              //     // width: 141 * $desk + vw;
              //     // justify-content: space-between;
              //   }
              //   .usecase_selector {
              //     border: 4 * $desk + vw solid #d8d6fc;
              //     width: 14 * $desk + vw;
              //     height: 14 * $desk + vw;
              //     border-radius: 10 * $desk + vw;
              //     background-color: #fff;
              //   }
              //   .AImodel_wrapper {
              //     gap: 5 * $desk + vw;
              //     align-items: center;
              //     flex-direction: row;
              //     display: flex;
              //     // width: 7.020833vw;
              //     // justify-content: space-between;
              //   }
              //   .AImodel_selector {
              //     border: 4 * $desk + vw solid #d8d6fc;
              //     width: 14 * $desk + vw;
              //     height: 14 * $desk + vw;
              //     border-radius: 10 * $desk + vw;
              //     background-color: #fff;
              //   }
              //   .IOT_wrapper {
              //     gap: 5 * $desk + vw;
              //     align-items: center;
              //     display: flex;
              //     // width: 020833vw;
              //     flex-direction: row;

              //     // justify-content: space-between;
              //   }
              //   .IOT_selector {
              //     border: 4 * $desk + vw solid #d8d6fc;

              //     width: 14 * $desk + vw;
              //     height: 14 * $desk + vw;
              //     border-radius: 10 * $desk + vw;
              //     background-color: #fff;
              //   }
              // }

              .addapp_pricing_wrapper {
                label {
                  &::after {
                    content: "*";
                    color: red;
                    margin-left: 0.5vw;
                    font-weight: 400;
                  }
                }
                .addapp_pricing_sent {
                  color:#848484;
                  font-size: 16 * $desk + vw;
                  margin-top: -3 * $desk + vw;
                }
                .btn {
                  margin-top: 0.5208333333vw;
                  height: 2.35vw;
                  align-items: center;
                  display: flex;
                  justify-content: center;
                  .empty_error {
                    height: 6vw;
                    width: 100%;
                    border-radius: 10 * $desk + vw;
                    text-align: left;
                    border: 0.15vw solid rgba(248, 78, 78, 0.7) !important;
                    margin-bottom: 0;
                  }
                }
              }
            }
          }

          .addapp_btn {
            width: 450 * $desk + vw;
            height: 68 * $desk + vw;
            margin-bottom: 42 * $desk + vw;

            .btn {
              margin-top: 42 * $desk + vw;
            }
          }
        }
      }
    }
  }

  .category_appimg_card_wrapper {
    width: 21.166667vw !important;
    height: 11.34375vw;
    align-items: center;
    z-index: 1;
    top: -119%;
    display: flex;
    justify-content: center;
    left: 21.5%;
  }

  .category_mediaimg_card_wrapper {
    width: 29.375vw;
    height: 19vw;
    align-items: center;
    z-index: 1;
    top: 13%;
    display: flex;
    justify-content: center;
    left: 9.5%;
  }
  .close_otp_modal {
    right: -1 * $desk + vw !important;
    border-top-right-radius: 15 * $desk + vw !important;
  }

  .preview_modal {
    .pricing_main_wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      .reset_submit_wrapper {
        width: 25vw;
        display: flex;
        justify-content: space-evenly;
        .btn {
          width: 121 * $desk + vw;
          height: 2vw;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .pricing_table_subwrapper {
        .tr_tr {
          // background-color: red;
        }
        .setpricing_wrapper {
          position: absolute;
          top: -10%;
          left: 33%;
          font-size: 20 * $desk + vw;
          // color:  #013aa2;
        }
        position: relative;
        .__input__ {
          margin-bottom: 1.5vw;
          // margin-top: 3%;
          .input_style {
            text-align: center;
          }
          .input_style:disabled {
            background: #dae4f8 !important;
            cursor: not-allowed;
        }
        }
        table {
          // width: 36.5vw;
          // height: 10vw;
          // border-collapse: collapse;
          border-spacing: 0;
          margin: 4.052083vw 4.052083vw 1.5vw 4.052083vw;
          border-radius: 17 * $desk + vw;
        }
        .tableloader {
          position: absolute;
          top: 60%;
          left: 24%;
        }
        th {
          width: 38.385416666666664vw;
          height: 2.34375vw;
          background-color: #283e92;
          color: #fff;
          border-spacing: 0;
          margin-bottom: 10* $desk + vw;
        }
        td {
          text-align: center;
          text-transform: capitalize;
          .table_name {
            margin-top: 1%;
            margin-bottom: 25%;
          }
        }
        tr {
          font-size: 16 * $desk + vw;
          border: 1 * $desk + vw solid #ececec;
          border-radius: 6 * $desk + vw;
        }
      }
    }

    .mediaImg_prev_single_btn {
      bottom: 6%;
      width: 50%;
      position: absolute;
      left: 25%;
    }
    .preview_word {
      font-size: 23* $desk + vw;
      text-align: center;
      align-items: center;
      margin-bottom: 44%;
    }

    .preview_sentence {
      text-align: center;
      font-size: 15 * $desk + vw;
      left: 20%;
      position: absolute;
      top: 9%;
    }
    .preview_multipleimg_word {
      font-size: 23 * $desk + vw;

      text-align: center;
      align-items: center;
      margin-top: 5%;
    }
    .preview_multipleimg_sentence {
      text-align: center;
      font-size: 15 * $desk + vw;
      // margin-top: 4%;
      // margin-bottom: 4%;
    }

    .preview_singleimg_word {
      text-align: center;
      align-items: center;
      margin-top: 5%;
    }
    .preview_singleimg_sentence {
      text-align: center;
      font-size: 15 * $desk + vw;
      margin-top: 4%;
      margin-bottom: 4%;
    }

    .sss {
      width: 100%;
      height: 89%;
      display: flex !important;
      justify-content: space-evenly;
      flex-direction: column;

      .cardimg {
        width: 66%;
        height: 64%;
        right: 17%;
        top: 16%;
        position: absolute;
      }

      .card_app_name {
        font-size: 20 * $desk + vw;
        position: absolute;
        top: 51.5%;
        left: 22%;
        text-overflow: ellipsis;
        max-width: 200 * $desk + vw;
        overflow: hidden;
        white-space: nowrap;
        height: 100* $desk + vw;
        color: #848484;
      }
      .card_app_description {
        position: absolute;
        top: 58%;
        left: 22%;
        color: #72778f;
        font-size: 14 * $desk + vw;
        width: 400 * $desk + vw;
        height: 100 * $desk + vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // word-wrap: break-word;
        text-align: start;
      }
      .appImg_prev_btn {
        position: absolute;
        bottom: 6%;
        width: 50%;
        left: 25%;
      }

      .mediaImg_prev_multiple_btn {
        position: absolute;
        bottom: 6%;
        width: 50%;
        left: 25%;
      }
    }
    .slick-prev {
      display: flex;
      top: 45%;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 10%;
      opacity: 100%;
      left: 8.5%;
      width: 30 * $desk + vw;
      height: 30 * $desk + vw;
      position: absolute;
      z-index: 1;
      border-radius: 100%;
      box-shadow: 0 * $desk + vw 0 * $desk + vw 6 * $desk + vw 2 * $desk + vw
        rgba(0, 0, 0, 0.35);
    }
    .slick-next {
      top: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      opacity: 100%;
      width: 30 * $desk + vw;
      height: 30 * $desk + vw;
      right: 7%;
      border-radius: 100%;
      box-shadow: 0 * $desk + vw 0 * $desk + vw 6 * $desk + vw 2 * $desk + vw
        rgba(0, 0, 0, 0.35);
    }
    .slick-prev {
      &:disabled {
        cursor: not-allowed;
        svg {
          path {
            fill: #6a6a6d;
          }
        }
      }
    }
    .slick-next {
      &:disabled {
        cursor: not-allowed;
        svg {
          path {
            fill: #6a6a6d;
          }
        }
      }
    }

    .slick-next:before {
      color: #f6f9ff;
    }
    .slick-next:before {
      content: none !important;
    }
    .slick-prev:before {
      content: none !important;
    }
    .slick-track {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      // width: 100% !important;
      // height: 419 * $desk + vw !important;
    }
    .slick-slide {
      // width: 193* $desk + vw !important;
      width: 100% !important;
      margin: 10 * $desk + vw;
      height: 419 * $desk + vw !important;
    }
    .modalAdjust_ {
      // background-color: red;
      width: 700 * $desk + vw;
      height: 700 * $desk + vw;
      .category_image {
        // width: 100% !important;
        // height: 419 * $desk + vw;
      }
    }
  
  }

}
