@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);
._VersionDetails_wrapper_ {
  position: relative;
  .VersionDetails_body {
    position: relative;
    height: 100%;
    .profile_bg {
      width: 100%;
    }
    .body_content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1500 * $desk + vw;
      top: 75 * $desk + vw;
      h1 {
        color: #fff;
        margin-bottom: 15 * $desk + vw;
        font-size: 32 * $desk + vw;
        letter-spacing: 2* $desk + vw;
      }
      .goBack {
        color: #fff;
        font-family: Poppins;
        font-size: 18 * $desk + vw;
        text-decoration: underline;
        margin-right: 1.5vw;
        cursor: pointer;
      }
      .VersionDetails_data {
        width: 100%;
        background-color: var(--background);
        height: 750 * $deskHeight + vh;
        border-radius: 12* $desk + vw;
        overflow: hidden;
        border: 2* $desk + vw solid #e7e7e7;
        .VersionDetails_partition_wrapper {
          display: flex;
          .VersionDt_sidebar {
            border-right: 1* $desk + vw solid #e7e7e7;
            // flex: 1;
            // display: flex;
            width: 297 * $desk + vw;
            height: 750 * $deskHeight + vh;

            // background-color: red;
            flex-direction: column;
            // display: flex;
          }
          .VersionDt_rhs_wrapper {
            flex: 1;
            padding: 42 * $desk + vw; 
          }
        }
      }
    }
  }
}
