

@use "sass:math";
$desk: math.div(100, 1920);

.usecase_card_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  height: 325 * $desk + vw;
  width: 395 * $desk + vw;
  padding: 6 * $desk + vw;
  padding-bottom: 14 * $desk + vw;
  .uc_cover {
    width: 100%;
    height: 250 * $desk + vw;
    object-fit: cover;
    border-radius: 12px;
  }
  .btn {
    width: 98 * $desk + vw;
    font-size: 14 * $desk + vw;
    padding: 6 * $desk + vw 0;
    // background: var(--sidenav);
    background: #eff3ff;
    color: var(--primary);
    margin: 0;
  }
  ._flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .uc_price {
      font-size: 17 * $desk + vw;
      margin-right: 5 * $desk + vw;
    }
  }
  .uc_name_image_wrapper {
    position: relative;
    .uc_name_wrapper {
      width: 100%;
      position: absolute;
      top: 0;
      .uc_name {
        color: var(--primary);
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 6px;
        padding: 4 * $desk + vw 10 * $desk + vw;
        margin-right: 18 * $desk + vw;
        margin-top: 13 * $desk + vw;
        font-size: 16 * $desk + vw;

        // max-width: 5vw;
        // word-break: break-all;
      }
    }
  }
}

.category_card_wrapper {
  position: relative;
  width: 91.2%;
  height: 140 * $desk + vw;
  border-radius: 8px;
  overflow: hidden;
  .category_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .category_name {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: Segoe UI;
    font-size: 25 * $desk + vw;
    font-weight: bold;
    color: #fff;
  }
}
