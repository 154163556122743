@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.usecase_upper_subwrapper {
  // background: linear-gradient(to left,rgb(32,33,36) 0,rgba(0,0,0,0) 56%),linear-gradient(to top,rgb(32,33,36) 0,rgba(0,0,0,0) 56%);

  // display: flex;
  // justify-content: space-between;
  // flex-direction: row-reverse;
  .appimage {
    height: 200 * $desk + vw;
    width: 12.416667vw;
    border-radius: 15 * $desk + vw;
    position: absolute;
    left: 79%;
    top: 6%;
    box-shadow: 0 * $desk + vw 0 * $desk + vw 6 * $desk + vw 2 * $desk + vw
      rgba(0, 0, 0, 0.35);
  }
  .app_trailer_style {
    height: 262 * $desk + vw;
    // width: 40%;
    // top: 2%;
    position: absolute;
    right: 2%;
    border-radius: 3%;

    box-shadow: 0 * $desk + vw 0 * $desk + vw 6 * $desk + vw 2 * $desk + vw
      rgba(0, 0, 0, 0.35);
    // display: flex;
    // justify-content: space-between;
    // flex-direction: row-reverse;
    // width: 50%;
    // float: right;
    // position: absolute;
  }
  .after_trailer_image {
    width: 6.416667vw;
    border-radius: 10%;

    box-shadow: 0 * $desk + vw 0 * $desk + vw 6 * $desk + vw 2 * $desk + vw
      rgba(0, 0, 0, 0.35);
  }
  .usecase_app_details {
    position: relative;
    text-transform: capitalize;

    margin-top: 2%;
    .usecase_developer_name {
      margin-top: 0.041667vw;
      margin-bottom: 20 * $desk + vw;
      color: #848484;
      font-size: 20 * $desk + vw; ;
    }
    .usecase_app_name {
      width: 800 * $desk + vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 28 * $desk + vw;
    }
    .rr_wrapper {
      // font-size: 1.0416666667vw;

      color: #848484;

      margin-left: 1.0416666667vw;
      .no_of_reviews {
        margin-left: 0.041667vw;
        width: 5.770833vw;
        border-right: 0.052083vw solid #848484;
        text-transform: capitalize;
        display: flex;
        gap: 0.5vw;
      }
    }
    .downloads_wrapper{
      color: #848484;

      // font-size: 20 * $desk + vw;
    }
    .downloads_tag {
      margin-left: 30 * $desk + vw;
      // border-left: 1 * $desk + vw solid #70707033;
    }
    .no_of_downloads {
      margin-left: 3.5vw;
      // color: #00000042;
    }
    .star_ratings {
      margin-left: 1.0416666667vw;
      // color: #00000042;
    }

    .usecase_video_count_wrapper {
      text-align: center;
      margin-left: 0;
      width: 21.020833vw;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &::after {
        content: "";
        background-color: #70707033;
        // border: 1* $desk + vw #70707033;
        position: relative;
        width: 1 * $desk + vw;
        height: 46 * $desk + vw;
        bottom: 56%;
        right: 32%;
        // display: block;
      }
    }
    .usecase_count_wrapper {
      font-size: 15 * $desk + vw;
      position: absolute;
      // left: 1 * $desk + vw;
      // text-align: center;
      margin-left: 0;
      width: 14.020833vw;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      font-family: 'wor';

      // &::after {
      //   content: "";
      //   background-color: #70707033;
      //   // border: 1* $desk + vw #70707033;
      //   position: relative;
      //   width: 1 * $desk + vw;
      //   height: 46 * $desk + vw;
      //   bottom: 56%;
      //   right: 52%;
      //   // display: block;
      // }
    }
  }
  .btn {
    width: 9.1145833333vw;
    margin-top: 1.5vw;
    align-items: center;
    height: 1vw;
    display: flex;
    justify-content: center;
  }
}
