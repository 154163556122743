@use "sass:math";
$desk: math.div(100, 1920);
.login_counter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2vw;
  font-size: 1vw;
margin-bottom: 1vw;
}
.counter_login_number {
  color: var(--primary);
}
.__auth_login__ {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #f8faff;
  .recaptcha_login {
    // margin-top: 20 * $desk + vw;
    margin-bottom: 15 * $desk + vw;
  }
  .card_holder {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 117 * $desk + vw;
  }
  .sub {
    font-size: 14 * $desk + vw;
    font-family: "Poppins";
    margin-bottom: 35 * $desk + vw;
    color: #000;
  }
  .input_style {
    font-size: 15 * $desk + vw;
    padding: 13 * $desk + vw;
  }
  ._form__header {
    font-family: "Ubuntu";
    p {
      font-family: "Ubuntu";
    }
  }
  .new_account {
    font-size: 14 * $desk + vw;
    font-family: "Ubuntu";
    margin-bottom: 30 * $desk + vw;
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;
  }
  .forgot {
    font-size: 12 * $desk + vw;
    color: #6c7ffe;
    cursor: pointer;
    margin-right: 1vw;
    text-decoration: underline;
  }
  .follow_us_wrapper {
    .follow_title {
      position: relative;
      //   width: 100%;
      font-size: 14 * $desk + vw;
      text-align: center;
      font-family: Ubuntu;
      color: #aaaaaa;
      &::before {
        content: "";
        position: absolute;
        transform: translateX(-120%);
        top: 50%;
        width: 80 * $desk + vw;
        height: 2px;
        background-color: #ddd;
      }
      &::after {
        content: "";
        position: absolute;
        transform: translateX(20%);
        top: 50%;
        width: 80 * $desk + vw;
        height: 2px;
        background-color: #ddd;
      }
    }
  }
  .modalAdjust_ {
    position: relative;
    width: 29.1666666667vw;
    padding: 1.0416666667vw 4.7916666667vw;
    padding-bottom: 1.0416666667vw;
    .modal_head {
      font-size: 20 * $desk + vw;
      font-weight: normal;
      text-align: center;
      padding-top: 20 * $desk + vw;
      padding-bottom: 45 * $desk + vw;
      color: #000;
    }
    .modal_sub {
      font-size: 18 * $desk + vw;
      font-weight: normal;
      text-align: center;
      padding-bottom: 45 * $desk + vw;
      color: #002465;
    }
    .res_success_btn {
      margin: 45 * $desk + vw 0;
      text-align: center;
    }
    .btn {
      width: 90%;
    }
  }
}

.social_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 40 * $desk + vw;
  .social_icon {
    border: 2px solid #ddd;
    display: flex;
    padding: 11 * $desk + vw;
    border-radius: 12px;
    margin: 0 6 * $desk + vw;
    cursor: pointer;
    &:hover {
      svg {
        transform: scale(1);
        path {
          //   fill: #fff;
        }
      }
    }
  }
  svg {
    transform: scale(0.8);
    width: 24 * $desk + vw;
    height: 24 * $desk + vw;
    transition: all 0.35s;
    transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
  }
}
