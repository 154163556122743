@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);
._order_wrapper_ {
  position: relative;
  .order_body {
    position: relative;
    height: 100%;
    .profile_bg {
      width: 100%;
    }
    .body_content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1500 * $desk + vw;
      top: 75 * $desk + vw;
      h1 {
        color: #fff;
        margin-bottom: 15 * $desk + vw;
        font-size: 32 * $desk + vw;
        letter-spacing: 2px;
      }
      .goBack {
        color: #fff;
        font-family: Poppins;
        font-size: 18 * $desk + vw;
        text-decoration: underline;
        margin-right: 1.5vw;
        cursor: pointer;
      }
      .order_data {
        width: 100%;
        background-color: var(--background);
        height: 750 * $deskHeight + vh;
        border-radius: 12px;
        overflow: hidden;
        border: 2px solid #e7e7e7;
        .order_bottom {
          position: absolute;
          bottom: 0;
          left: 52%;
          transform: translateX(-50%);
          width: 94%;
          // background: rgb(255, 255, 255);
          // background: linear-gradient(
          //   0deg,
          //   rgba(255, 255, 255, 1) 0%,
          //   rgba(255, 255, 255, 0.6783088235294117) 100%
          // );
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.7vw 0;
          .btn {
            width: 200px;
            font-size: 15px;
            padding-top: 13px;
            padding-bottom: 13px;
            margin: 0;
          }
        }
        .filter_items {
          padding: 1vw;
          display: flex;
          align-items: center;
          gap: 2vw;
          .search_container {
            width: 400 * $desk + vw;
            align-self: flex-end;
            .__input__,
            .input_style {
              margin: 0;
              svg {
                left: unset;
                right: 18 * $desk + vw;
                transform: scaleX(-1) translateY(-50%);
                #Rectangle_668 {
                  fill: var(--text);
                  width: 5px;
                  height: 6px;
                }
                #Ellipse_18,
                #Vector_7 {
                  stroke-width: 2px;
                  stroke: var(--text);
                }
              }
            }
            .input_style {
              padding-right: 2.5vw;
              background-color: var(--device-filter);
              color: var(--text);
              font-size: 0.7291666667vw;
              // background-color: #f9f9f9;
            }
          }
        }
        .order_item {
          width: 100%;
          height: 100%;
          .order_list {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .noData {
            display: grid;
            place-content: center;
            height: 100%;
            .noOrderIcon {
              width: 375px;
              height: 375px;
            }

            p {
              font-size: 18 * $desk + vw;
              text-align: center;
              color: #8f8f8f;
            }
          }
        }
      }
    }
  }
}

.date__filter_container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: max-content;
  label {
    color: var(--primaryInvert);
    font-size: 0.78125vw;
    font-weight: 600;
    // margin-bottom: 5px;
  }
  #date_container {
    position: relative;
    min-width: 7.6875vw;
    border: 2px solid #ddd;
    cursor: pointer;
    color: var(--text);
    background-color: var(--device-filter);
    border-radius: 8px;
    font-size: 0.7291666667vw;
    padding: 0 0.5vw;
    line-height: 2vw;
    padding-right: 2vw;
    .dateIcon {
      position: absolute;
      right: 0.5vw;
      top: 50%;
      transform: translateY(-50%);
      height: 1vw;
    }
  }
  .select-options {
    position: absolute;
    width: 100%;
    // max-height: 9vw;
    // overflow: auto;
    opacity: 0;
    top: 90%;
    visibility: hidden;
    border-radius: 9px;
    transform: scale(0.01);
    transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s;
    transform-origin: center top;
    box-shadow: var(--box-shadow);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    z-index: 5;
    padding-bottom: 0.8vw;
    background-color: var(--device-filter);
    .select-option {
      color: var(--text);
      width: 100%;
      border: 0;
      padding: 6px 16 * $desk + vw;
      text-align: left;
      background-color: var(--device-filter);
      border-bottom: 1px solid gray;
      cursor: pointer;
      word-break: break-all;
      overflow: hidden;
      display: block;
      text-transform: capitalize;
      font-size: 14 * $desk + vw;
      &:hover {
        background-color: var(--primary);
        color: #ffffff;
      }
    }
    .dates {
      border-top: 1px solid gray;
      padding: 0 0.5vw;
      padding-top: 0.5vw;
      .date_time {
        display: flex;
        flex-direction: column;
      }
      .time-label {
        color: var(--primaryInvert);
        font-size: 0.58125vw;
        font-weight: 600;
      }
      #startDate {
        font-size: 0.65vw;
      }
      #endDate {
        font-size: 0.65vw;
      }
      .btn {
        display: block;
        padding: 0.3vw 0;
        width: 80%;
        margin: 0 auto;
        margin-top: 0.5vw;
        font-size: 14 * $desk + vw;
      }
    }
  }
  .active__ {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transform-origin: center top;
    // transform: translateY(0);
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      top: -12px;
      border-color: transparent transparent transparent var(--device-filter);
      border-width: 6px;
      transform: rotate(270deg);
      left: 85.5%;
    }
  }
}

.order_list_wrapper {
  width: 98%;
  border: 3px solid #dae4f8;
  margin-bottom: 25 * $desk + vw;
  padding: 8 * $desk + vw 32 * $desk + vw 8 * $desk + vw 20 * $desk + vw;
  border-radius: 6px;
  display: flex;
  align-items: center;
  .o_leftwrapper {
    width: 17vw;
    .icon_name_block {
      display: flex;
      .deviceIcon_wrapper {
        position: relative;
        display: grid;
        place-items: center;
        background-color: var(--device-card);
        border: 3 * $desk + vw solid #e1e1e1;
        border-radius: 60%;
        padding: 0.7vw;
        .deviceIcon {
          width: 50 * $desk + vw;
          height: 50 * $desk + vw;
          fill: var(--text);
          line {
            stroke: var(--text);
          }
        }
      }
      .name_block {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-left: 1vw;
        .name {
          font-size: 16 * $desk + vw;
          font-weight: 700;
        }
        .status {
          font-size: 16 * $desk + vw;
          font-weight: 500;
          text-transform: capitalize;
        }
      }
    }
  }
  .order_info_wrapper {
    display: flex;
    // flex-wrap: wrap;
    gap: 40 * $desk + vw;
    margin-left: 40 * $desk + vw;
    width: 100%;
    .info_wrapper {
      display: flex;
      flex-direction: column;
      // flex: 1;
      align-items: baseline;
      .device_info {
        margin-bottom: 18 * $desk + vw;
        .d_label {
          font-size: 14 * $desk + vw;
          color: #848484;
          margin-bottom: 4 * $desk + vw;
        }
        .value_wrapper {
          font-size: 14 * $desk + vw;
          background-color: var(--sidenav);
          padding: 4 * $desk + vw 15 * $desk + vw;
          border-radius: 4px;
          position: relative;
          text-transform: capitalize;
        }
      }
      .beta_labels {
        display: flex;
        align-items: center;
        // width: 124 * $desk + vw;
        background-color: var(--sidenav);
        justify-content: space-evenly;
        margin: 1.4vw 0.4vw 0 0;
        font-size: 10.8 * $desk + vw;
        padding: 4 * $desk + vw 15 * $desk + vw;
        border-radius: 6px;
        user-select: none;
        cursor: pointer;
        .log_icon {
          margin-right: 0.2vw;
          margin-top: 0.1vw;
        }
        &:active {
          animation: press 0.2s 1 linear;
        }
      }
    }
  }
}
