@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.__auth_register__ {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #f8faff;
  color: #111b60;
  overflow: hidden;
  .d_data {
    padding: 1.0416666667vw 115 * $desk + vw;
    .text-area-label {
      font-size: 0.78125vw;
      color: var(--primaryInvert);
      font-weight: 600;
      &::after {
        content: "*";
        color: red;
        margin-left: 0.5vw;
        font-weight: 400;
      }
    }
    .__input__ {
      label {
        &::after {
          content: "*";
          color: red;
          margin-left: 0.5vw;
          font-weight: 400;
        }
      }
    }
    .msg_area {
      width: 100%;
      resize: none;
      margin-top: 5px;
      border: 2px solid #dddddd;
      border-radius: 0.6770833333vw;
      padding: 0.3vw;
      font-size: 0.7770833333vw;
      outline: none;
    }
    .bank_detail {
      display: flex;
      width: 100%;
      margin-top: 1vw;
      flex-wrap: wrap;
      gap: 1vw;
      .__input__ {
        width: 48%;
        text-transform: capitalize;
        margin-bottom: 0;
        .input_style {
          color: #000;
        }
      }
      .phone-ib {
        width: 48%;
      }
      // .left-section {
      //   width: 50%;
      //   margin-right: 0.625vw;
      // }
      // .right-section {
      //   width: 50%;
      //   margin-left: 0.625vw;
      .adjust_dd {
        text-transform: capitalize;
        width: 48%;
        .selected-text {
          background-color: white;
          color: #000;
          // height: 54.6%;
          border-radius: 0.6770833333vw;
        }
        button {
          height: 44 * $desk + vw !important;
        }
      }
      .custom-select-container .active__ {
        left: 1%;
      }
    }
  }
  .sub {
    font-size: 14 * $desk + vw;
    font-family: "Poppins";
    margin-bottom: 35 * $desk + vw;
    color: #000;
  }
  .already_account {
    font-size: 14 * $desk + vw;
    font-family: "Ubuntu";
    margin-bottom: 30 * $desk + vw;
    cursor: pointer;
    span {
      color: var(--primary);
      text-decoration: underline;
    }
  }
  .register_data {
    display: flex;
    width: 100%;
    padding-right: 3vw;
    .input_style {
      padding: 10 * $desk + vw;
    }
    .left-section {
      width: 50%;
      margin-right: 12 * $desk + vw;
      .__input__ label,
      .phone-ib label {
        &::after {
          content: "*";
          color: red;
          margin-left: 0.5vw;
          font-weight: 400;
        }
      }
    }
    .right-section {
      margin-left: 12 * $desk + vw;
      width: 50%;
      .custom-multi-select-container {
        // width: 292 * $desk + vw;
        margin-bottom: 0.8375vw;
        .dd-label {
          margin-bottom: 4px;
          color: var(--primary);
        }
        .marquee,
        .marquee2 {
          font-size: 0.6770833333vw;
        }
      }
      .__input__ label {
        &::after {
          content: "*";
          color: red;
          margin-left: 0.5vw;
          font-weight: 400;
        }
      }
    }
    #_country_ {
      position: relative;
    }
    .suggestions {
      position: absolute;
      width: 99%;
      top: 92%;
      border-radius: 5px;
      border: 2px solid #dddddd;
      background-color: #fff;
      z-index: 5;
      overflow: hidden;
      margin-left: 1%;
      .suggestion_item {
        // margin: 0 5 * $desk + vw;
        border-bottom: 1px solid #ddd;
        padding: 8 * $desk + vw;
        padding-left: 5 * $desk + vw;
        padding-right: 5 * $desk + vw;
        font-size: 14 * $desk + vw;
        font-weight: 400;
        cursor: pointer;
      }
      .suggestion_item:last-child {
        border-bottom: 0;
      }
    }
    .active_flag {
      background-color: var(--primary);
      color: #fff;
    }

    .dropdown {
      margin-top: 0.4vw;
      position: relative;
      p:first-child {
        padding-bottom: 0.5vw;
        padding-left: 0.5vw;
      }
      .dropdown-content {
        background-color: lightyellow;
        width: 100%;
        overflow: auto;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        padding: 0.5vw;
        border-radius: 1vw;
        font-size: 12 * $desk + vw;
        position: absolute;
        bottom: 3.5vw;
        z-index: 8;

        /* Add a red text color and an "x" when the requirements are wrong */
        .invalid {
          color: red;
        }
        .invalid:before {
          margin: 0.2vw 1vw;
          content: "✖";
        }

        /* Add a green text color and a checkmark when the requirements are right */
        .valid {
          color: green;
        }
        .valid:before {
          margin: 0.2vw 1vw;
          content: "✔";
        }
      }
    }
  }
  .profile_pic_uploader_wrapper {
    display: flex;
    width: 91.7%;
    border: 2px solid #dddddd;
    border-radius: 10px;
    padding: 10 * $desk + vw 25 * $desk + vw;
    justify-content: space-between;
    align-items: center;
    .profile_holder {
      width: 95 * $desk + vw;
      height: 95 * $desk + vw;
      border-radius: 50%;
      border: 2px solid rgba(69, 0, 255, 0.2);
      // border: solid 1px #4500ff;
      padding: 9 * $desk + vw;
    }
    .no_profile {
      width: 100%;
      height: 100%;
      border: 2px solid white;
      border-radius: 50%;
      // box-shadow: var(--box-shadow);
      box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.26);
      object-fit: cover;
    }
    .dropzone {
      display: flex;
      border: 2px dashed #dddddd;
      // height: 6vh;
      width: 280 * $desk + vw;
      font-size: 15 * $desk + vw;
      border-radius: 50px;
      align-items: center;
      justify-content: center;
      align-self: stretch;
      cursor: pointer;
      margin: 0.8vw 0;
      .file_name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 80%;
        text-align: center;
        font-family: "Work Sans";
        font-weight: 600;
      }
    }
    .no_file {
      font-family: "Work Sans";
      font-size: 14 * $desk + vw;
      width: 6vw;
    }
  }
  .recaptcha {
    display: inline-block;
    margin-top: 20 * $desk + vw;
    margin-bottom: 35 * $desk + vw;
  }
  ._tos {
    display: flex;
    margin-left: 1vw;
    font-family: Work Sans;
    font-size: 13 * $desk + vw;
    width: 90%;
    // cursor: pointer;
    margin-bottom: 1vw;

    .adjust_cb {
      label:before {
        background: #eef4fe;
        border: 2px solid #72778f;
        width: 13px;
        height: 13px;
        margin-top: 0;
      }

      .styled-checkbox:checked + label:before {
        background: #2d62ed;
        width: 13px;
        height: 13px;
      }
      .styled-checkbox:checked + label:after {
        top: 7px;
      }
    }
    p {
      font-family: "Work Sans";
      color: #72778f;
    }
    span {
      cursor: pointer;
      font-family: "Work Sans";
      color: #0779e4;
    }
  }
  .reg_otp_modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    z-index: 100;
    .pass_container {
      width: 530 * $desk + vw;
      height: 690 * $desk + vw;
      padding: 63 * $desk + vw;
      padding-top: 30 * $desk + vw !important;
      background: #fff;
    }
    .reg_success_container {
      position: relative;
      overflow: hidden;
      width: 530 * $desk + vw;
      height: 365 * $desk + vw;
      min-height: 365 * $desk + vw;
      padding: 63 * $desk + vw;
      padding-top: 73 * $desk + vw !important;
      padding-bottom: 0 * $desk + vw !important;
      padding-bottom: 20 * $desk + vw !important;
      background: #fff;
      .reg_head {
        text-align: center;
        font-size: 17 * $desk + vw;
        color: #013aa2;
        margin-top: 12 * $desk + vw;
        font-family: "Rubik";
      }
      .otp_desc {
        padding: 0;
        padding-top: 50 * $desk + vw;
        // padding: 0 90 * $desk + vw;
      }
      svg {
        position: relative;
        z-index: 5;
      }
      .firework,
      .firework::before,
      .firework::after {
        --top: 60vh;
        content: "";
        position: absolute;
        top: 33%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0.5vmin;
        aspect-ratio: 1;
        background:
    /* random backgrounds */ radial-gradient(
              circle,
              #e0e0e0 0.2vmin,
              #0000 0
            )
            50% 00%,
          radial-gradient(circle, #013aa2 0.3vmin, #0000 0) 00% 50%,
          radial-gradient(circle, #e0e0e0 0.5vmin, #0000 0) 50% 99%,
          radial-gradient(circle, #013aa2 0.2vmin, #0000 0) 99% 50%,
          radial-gradient(circle, #e0e0e0 0.3vmin, #0000 0) 80% 90%,
          radial-gradient(circle, #013aa2 0.5vmin, #0000 0) 95% 90%,
          radial-gradient(circle, #e0e0e0 0.5vmin, #0000 0) 10% 60%,
          radial-gradient(circle, #013aa2 0.2vmin, #0000 0) 31% 80%,
          radial-gradient(circle, #013aa2 0.3vmin, #0000 0) 80% 10%,
          radial-gradient(circle, #e0e0e0 0.2vmin, #0000 0) 90% 23%,
          radial-gradient(circle, #013aa2 0.3vmin, #0000 0) 45% 20%,
          radial-gradient(circle, #e0e0e0 0.5vmin, #0000 0) 13% 24%;
        background-size: 0.5vmin 0.5vmin;
        background-repeat: no-repeat;
        animation: firework 2s infinite;
      }
      .firework::before {
        transform: translate(-50%, -50%) rotate(25deg) !important;
      }

      .firework::after {
        transform: translate(-50%, -50%) rotate(-37deg) !important;
      }

      @keyframes firework {
        0% {
          transform: translate(-50%, 0vh);
          width: 0.5vmin;
          opacity: 1;
        }
        50% {
          width: 0.5vmin;
          opacity: 1;
        }
        100% {
          width: 45vmin;
          opacity: 0;
        }
      }
      .res_success_btn {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 40 * $desk + vw;
        .btn {
          width: 160 * $desk + vw;
          font-size: 14 * $desk + vw;
          padding: 12 * $desk + vw 10 * $desk + vw;
        }
      }
    }
  }

  // .modalAdjust_ {
  //   width: 530 * $desk + vw;
  //   height: 690 * $deskHeight + vh;
  //   padding: 63 * $desk + vw;
  //   padding-top: 30 * $desk + vw !important;
  // }
}

.manu_register {
  .noBank {
    color: red;
    font-size: 16 * $desk + vw;
    margin: 0.8vw 0;
  }
  .country_section {
    position: relative;
    width: 100%;
    margin-top: 1vw;
    .suggestions {
      position: absolute;
      width: 99%;
      top: 92%;
      border-radius: 5px;
      border: 2px solid #dddddd;
      background-color: #fff;
      z-index: 5;
      overflow: hidden;
      margin-left: 1%;
      .suggestion_item {
        border-bottom: 1px solid #ddd;
        padding: 8 * $desk + vw;
        padding-left: 5 * $desk + vw;
        padding-right: 5 * $desk + vw;
        font-size: 14 * $desk + vw;
        font-weight: 400;
        cursor: pointer;
        color: #000;
      }
      .suggestion_item:last-child {
        border-bottom: 0;
      }
    }
    .active_flag {
      background-color: var(--primary);
      color: #fff;
    }
  }
  .phone-ib {
    .phone_input_style {
      color: black;
    }
    label {
      color: var(--primaryInvert);
    }
    .country_dial_code {
      color: #000;
    }
    .phone_no_wrapper {
      background-color: #fff;
    }
  }
  .adjust_dd {
    margin-left: 0.21vw;
  }
}
