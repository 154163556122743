@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.upgrade_licence_modal {
  .select-options {
    margin-top: 5px;
  }
  .data_table {
    .__card_wrapper___ {
      display: flex;
      flex-wrap: wrap;
      padding: 4vw;
      padding-bottom: 0;
      gap: 4vw;
      justify-content: space-between;
      //   background-color: lightcyan;
      .__each_card__ {
        width: 23vw;
        // margin-bottom: 3.5vw;
        height: auto;
        padding: 0.5vw;
        border: 3px solid #dae4f8;
        border-radius: 6px;
        .__sub_flex__ {
          display: flex;
          justify-content: space-between;
          //   margin-top: 5px;
          .__attribute_name__ {
            font-size: 1vw;
            text-transform: capitalize;
            color: var(--primary);
          }
          .btn {
            text-transform: capitalize;
            width: 35%;
            padding: 0;
            height: 1.5vw;
            border-radius: 4px;
            cursor: auto;
            animation: none;
          }
        }
        .__counter_wrapper__ {
          display: flex;
          margin-top: 10px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .counter_wrapper_ .counter_label {
            font-size: 0.78125vw;
            display: flex;
            margin-bottom: 4px;
            justify-content: center;
          }
        }
        .__sub_flex2___ {
          display: flex;
          gap: 5px;
          .__checkbox__ {
            // font-size: 0.7vw;
          }
          ._checkbox_label {
            font-size: 0.7vw;
          }
        }
      }
    }
  }
}

.upgrade_licence_modal .modalAdjust_ .adjust_dd {
  // text-transform: capitalize;
  width: 26%;
  height: unset;
  margin-left: 2vw;
}

.create_license_modal {
  .modalAdjust_ {
    width: 700 * $desk + vw;
    height: 480 * $desk + vw;
    padding: 40 * $desk + vw 25 * $desk + vw 40 * $desk + vw 25 * $desk + vw;
    .adjust_dd {
      .list_open {
        top: 38%;
        .selected {
        }
      }
      .dd-label {
        font-size: 12* $desk + vw ;
        margin-bottom: 8 * $desk + vw;
        margin-top: -22 * $desk + vw;
        // width: 100* $desk + vw;
        color: #00000080;
      }
      .selected-text{
        width: 110* $desk + vw;
      font-size: 15 * $desk + vw;
      margin-bottom: 10* $desk + vw;
          background: #0000000D;
          border: none;
          text-transform: capitalize;
      }
    }

    .close_otp_modal {
      width: 35 * $desk + vw;
      height: 35 * $desk + vw;
      border-bottom-left-radius: 9 * $desk + vw;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .create_special_upper_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2vw;
.__input__ label{
  font-size: 18 * $desk + vw;
}
    .input_style {
      width: 500 * $desk + vw;
      height: 50 * $desk + vw;
      font-size: 18 * $desk + vw;
      margin-top: 0;
    }
  
   
  }

  .create_special_lower_wrapper {
    width: 100%;
    min-height: 300 * $desk + vw;
    border: 5 * $desk + vw solid #fafafa;
    border-radius: 10 * $desk + vw;
    // background-color: #fafafa;
    background: rgba(0, 0, 0, 0.0509803922);
    padding: 10 * $desk + vw;
    display: flex;
    flex-wrap: wrap;
    .addattribute_card{
      width: 15.625vw;
      height: 6vw;
      background-color: #fff;
      padding: 1.0416666667vw;
      border-radius: 0.5208333333vw;
      margin: 0.2604166667vw;
      display: flex;
      justify-content: center;
      align-items:center;
      cursor: pointer;
    }
  }
  .btn_wrapper{
    display: flex;
    justify-content: end;
    .btn {
      width: 137* $desk + vw;
      height: 40* $desk + vw;
      align-items: center;
      text-align: center;
      font-size: 0.78125vw;
      display: flex;
      justify-content: center;
      margin-top: 10* $desk + vw;

    }
  }

}
