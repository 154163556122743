@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.__users_wrapper__,
.__inventory__ {
  position: relative;

  .users_body {
    position: relative;
    height: 100%;

    .profile_bg {
      width: 100%;
    }

    .body_content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1500 * $desk + vw;
      top: 75 * $desk + vw;
      .special_plan_tag {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 32 * $desk + vw;
        color: #fff;
        margin-bottom: 10 * $desk + vw;
      }

      .inventory_data {
        width: 100%;
        background-color: var(--background);
        height: 69.4444444444vh;
        border-radius: 12px;
        overflow: hidden;
        border: 2px solid #e7e7e7;
        padding: 1vw;
        overflow: hidden;
        .no_license_found {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 500 * $desk + vw;
        }
        .inventory_nosearch_data {
          align-items: center;
          display: flex;
          justify-content: flex-end;
          .btn {
            width: 204 * $desk + vw;
            font-weight: 600;
            margin-bottom: 0.5vw;
            padding: 0.6vw;
            font-size: 16 * $desk + vw;
            border-radius: 3 * $desk + vw;
            height: 36 * $desk + vw;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        
        }
        .inventory_upper_data {
          align-items: center;
          display: flex;
          justify-content: space-between;
          .input_style {
            margin-left: 0.24vw;
            width: 12vw;
          }
          .__input__ svg {
            left: 88%;
            top: 45%;
          }
          .add_plan_btn {
            // display: flex;
            // justify-content: flex-end;

            .btn {
              width: 204 * $desk + vw;
              font-weight: 600;
              margin-bottom: 0.5vw;
              padding: 0.6vw;
              font-size: 16 * $desk + vw;
              border-radius: 3 * $desk + vw;
              height: 36 * $desk + vw;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 14* $desk + vw;
            }
          }
        }
        .no_plan {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          align-items: center;
          text-align: center;
          .no_plan_label {
            color: #367bc080;
            font-size: 42 * $desk + vw;
          }
          .create_plan_label {
            font-size: 18 * $desk + vw;
            font-size: 18 * $desk + vw;
            margin-top: -5 * $desk + vw;
            color: #367bc0b2;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .added_license_wrapper_single {
          justify-content: flex-start;
        }
        .added_license_wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          padding: 1.1875vw 1.395833vw;
          // width: 74vw;
          overflow: hidden;
          // gap: 1vw;
        }
      }
    }
  }
  .upgrade_licence_modal {
    .modalAdjust_ {
      width: 876 * $desk + vw;
      height: 530 * $desk + vw;
      background-color: var(--background);
      padding: 32 * $desk + vw;
    }
  }
}
