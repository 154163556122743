@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);
._AddedVersion_wrapper_ {
  position: relative;
  .AddedVersion_body {
    position: relative;
    height: 100%;
    .profile_bg {
      width: 100%;
    }
    .addedeversion_header {
      font-size: 32 * $desk + vw;
      text-transform: capitalize;
      color: #fff;
      margin-bottom: 10 * $desk + vw;
    }
    .body_content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1500 * $desk + vw;
      top: 75 * $desk + vw;
      // h1 {
      //   color: #fff;
      //   margin-bottom: 15 * $desk + vw;
      //   font-size: 32 * $desk + vw;
      //   letter-spacing: 2* $desk + vw  ;
      // }
      .goBack {
        color: #fff;
        font-family: Poppins;
        font-size: 18 * $desk + vw;
        text-decoration: underline;
        margin-left: 73.5vw;
        cursor: pointer;
        position: absolute;
        top: 15 * $desk + vw;
        left: -10 * $desk + vw;
      }
      .AddedVersion_data {
        width: 100%;
        background-color: var(--background);
        // height: 750 * $deskHeight + vh;
        height: 69.4444444444vh;

        border-radius: 12 * $desk + vw;
        overflow: hidden;
        border: 2 * $desk + vw solid #e7e7e7;

        .AddedVersion_data_wrapper {
          .AddedVersion_data_subwrapper {
            padding: 42 * $desk + vw 27 * $desk + vw 41 * $desk + vw 46 * $desk +
              vw;
            .AddedVersion_data_upper_subwrapper {
              // padding: 0 0 44 * $desk + vw 16 * $desk + vw;

              display: flex;
              justify-content: space-between;
            }
            .AddedVersion_data_lower_subwrapper {
              width: 100%;
              border-top-right-radius: 10 * $desk + vw;
              border-top-left-radius: 10 * $desk + vw;
              border: 2 * $desk + vw solid #f4f7fe;
              padding: 27 * $desk + vw 27 * $desk + vw 18 * $desk + vw 27 *
                $desk + vw;

              .addver_header_wrapper {
                display: flex;
                justify-content: space-between;
                // width: 364 * $desk + vw;
                align-items: center;
                .addver_header_wrapper_lhs {
                  display: flex;
                  gap: 2.5vw;
                  // justify-content: space-between;
                  min-width: 364 * $desk + vw;
                  max-width: 700 * $desk + vw;
                  align-items: center;

                  .appver_appname {
                    font-size: 22 * $desk + vw;
                    text-transform: lowercase;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                  .appver_appname::first-letter {
                    text-transform: uppercase;
                  }

                  .addedver_appimg {
                    width: 59 * $desk + vw;
                    height: 59 * $desk + vw;
                    border-radius: 50%;
                  }
                  .addver_apptype {
                    padding: 8 * $desk + vw;
                    border: 1 * $desk + vw solid #2d62ed;
                    border-radius: 20 * $desk + vw;
                    color: #2d62ed;
                    background-color: #fff;
                    font-size: 14 * $desk + vw;
                    text-align: center;
                    min-width: 80 * $desk + vw;
                  }
                }

                .searchbar_filterby_wrapper {
                  display: flex;
                  justify-content: end;
                  width: 650 * $desk + vw;
                  height: 45 * $desk + vw;
                  .search_wrapper_ {
                    width: 350 * $desk + vw;
                    border-radius: 20 * $desk + vw;
                    height: 2.5vw;
                    border: 2px solid #dddd;
                    box-shadow: none !important;
                    // border-radius: 5 * $desk + vw;
                    // padding: 0;
                    // box-shadow: 0 3* $desk + vw 6* $desk + vw rgba(0, 0, 0, 0.16),
                    //   0 3* $desk + vw 6* $desk + vw rgba(0, 0, 0, 0.23) !important;
                    .search_input_ {
                      font-size: 16 * $desk + vw;
                    }
                  }
                }
              }
            }
            .addver_table_wrapper {
              cursor: pointer;
              overflow: hidden;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              // width: 1431 * $desk + vw;
              width: 100%;
              // height: 665.41 * $desk + vw;
              // border: 2 * $desk + vw solid #f4f7fe;
              padding: 27 * $desk + vw 27 * $desk + vw 18 * $desk + vw 0.40625vw;
              position: relative;
              // .delete_info {
              //   align-items: center;
              //   display: flex;
              //   justify-content: center;
              // }
              .dots_options {
                font-weight: 400;
                font-size: 14 * $desk + vw;
                color: var(--text);
                text-align: center;
                padding: 7 * $desk + vw 0 * $desk + vw;
                border-bottom: 1 * $desk + vw solid #848484;
                padding: 7 * $desk + vw 7 * $desk + vw;

                .dots_options_disable {
                  display: block;
                }
              }
              .dots_options:hover {
                color: #2d62ed;
              }
              .open {
                padding: 7 * $desk + vw 7 * $desk + vw;
                font-size: 14 * $desk + vw;
                border-radius: 8 * $desk + vw;
                position: absolute;
                width: 0 * $desk + vw;
                padding: 0;
                background-color: #ffffff;
                right: 2.5%;
                top: 0%;
                overflow: hidden;
                cursor: pointer;
                font-size: 14 * $desk + vw;
                transition: all 500ms ease-in-out;
                white-space: nowrap;
              }
              .active {
                z-index: 1;
                width: 120 * $desk + vw;
                box-shadow: var(--dd-box-shadow);
                background: var(--body-background);
                // transition: all 500ms ease-in-out;
              }
              .dots_svg {
                position: absolute;
                left: 99%;
                top: 6%;
                width: 0.2604166667vw;
                height: 0.2604166667vw;
              }
              .dot_icon_active {
                top: -10%;
                // position: absolute;
                cursor: pointer;
                filter: contrast(0.5);
                z-index: 10;
                right: 1%;
              }
              .hoverData {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                width: 9.03125vw;
                z-index: 5;
                right: -37vw;
                height: 4vh;
                transition: all 0.2s ease-in-out;
                top: -2%;
                .shadow {
                  opacity: 0;
                  position: absolute;
                  width: 80%;
                  height: 100%;
                  right: 100%;
                  // background:  linear-gradient(
                  //   94deg,
                  //   rgba(78, 231, 142, 0) 0%,
                  //   rgb(230, 255, 240) 39%
                  // );
                }
                .upload_delete_icon {
                  // position: relative;
                  // cursor: pointer;
                  // font-size: 28 * $desk + vw;
                  // user-select: none;
                  // margin-left: 8.5vw;
                  // display: none;
                  // align-items: center;
                  // margin-bottom: 2vw;
                  position: relative;
                  cursor: pointer;
                  // font-size: 1vw;
                  -webkit-user-select: none;
                  user-select: none;
                  margin-left: 8.5vw;
                  display: none;
                  align-items: center;
                  margin-top: 0.3vw;

                  // margin-top: 1* $desk + vw;
                  // margin-top: 10* $desk + vw;
                }
                .upload_delete_icon:active {
                  animation: press 0.2s 1 linear;
                }
                .failed_delete_icon {
                  position: relative;
                  cursor: pointer;
                  -webkit-user-select: none;
                  user-select: none;
                  margin-left: -8.5vw;
                  display: none;
                  align-items: center;
                  margin-bottom: -0.4vw;
                }
                .failed_delete_icon:active {
                  animation: press 0.2s 1 linear;
                }
                .infoIcon {
                  color: red;
                  filter: var(--icon-color-invert);
                  cursor: pointer;
                  font-size: 28 * $desk + vw;
                  user-select: none;
                  margin-left: -5vw;
                  display: none;
                  margin-bottom: 0vw;
                  // position: relative;
                  // cursor: pointer;
                  // font-size: 1.5625vw;
                  // user-select: none;
                  // margin-left: -4.5vw;
                  // display: none;
                  // align-items: center;
                  // margin-bottom: 0vw;
                  .infoIcon:active {
                    right: 0;
                    animation: press 0.2s 1 linear;
                  }
                }
              }
              .addver_table:hover {
                box-shadow: 0 3 * $desk + vw 6 * $desk + vw rgba(0, 0, 0, 0.16),
                  0 3 * $desk + vw 6 * $desk + vw rgba(0, 0, 0, 0.23);

                .onhover_td {
                  display: block;
                  align-items: center;
                }
                .hoverData {
                  right: 0;
                  .shadow {
                    opacity: 1;
                    // cursor: pointer;
                  }
                }
                .failed_delete_icon,
                .upload_delete_icon {
                  display: block;
                  cursor: pointer;
                }
                .addedver_doubletick {
                  // display: none
                }
                .infoIcon {
                  display: block;
                }
                .addver_td_hover {
                  transition: all 0.2s ease-in-out;
                  display: block;
                }
                .addver_td {
                  transition: all 0.2s ease-in-out;
                }
                // .hoverDataGreen {
                //   background-color: #e6ffe8;
                // }
                // .shadowGreen {
                //   background: linear-gradient(
                //     94deg,
                //     rgba(78, 231, 142, 0) 0%,
                //     rgb(230, 255, 240) 39%
                //   );
                // }

                .hoverDataGreen {
                  background-color: var(--tr-shadow-greenbg);
                }
                .shadowGreen {
                  background: var(--tr-shadow-green);
                }
                .hoverDataRed {
                  background-color: var(--tr-shadow-redbg);
                }
                .shadowRed {
                  background: var(--tr-shadow-red);
                }
              }
              .onhover_td {
                display: none;
                position: absolute;
                // background: #fff;
                background: var(--background);
                text-transform: capitalize;
              }
              .addver_td_hover:active {
                transition: all 0.2s ease-in-out;
              }
              .addver_table {
                position: relative;
                transition: 300ms ease-in-out;
                width: 72vw;
                padding: 10 * $desk + vw;
                display: flex;
                // gap: 9vw;
                justify-content: space-between;
                // border-collapse: collapse;
                // border-spacing: 0;
                // border: 1 * $desk + vw solid #fff;
                border-radius: 6 * $desk + vw;
              }

              .addver_td_hover {
                display: none;
                transition: all 0.2s ease-in-out;
              }

              .tableloader {
                position: absolute;
                top: 60%;
                left: 24%;
              }
              // th {
              //   width: 38.385416666666664vw;
              //   height: 2.34375vw;
              //   background-color: #283e92;
              //   color: #fff;
              //   border-spacing: 0;
              // }
              .addver_td {
                text-transform: lowercase;
                position: relative;
                width: 9vw;
                text-align: start;
                transition: all 0.2s ease-in-out;

                .status_style {
                  text-align: end;
                  padding: 5 * $desk + vw;
                  border-radius: 7 * $desk + vw;
                  margin-top: 10 * $desk + vw;
                  z-index: 0;
                  height: 4vh;
                  padding: 2 * $desk + vw;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-transform: capitalize;
                }
                .addver_td_header {
                  font-size: 18 * $desk + vw;
                  transition: all 0.2s ease-in-out;
                }

                .addver_td_values {
                  // background-color: #F4F7FE;
                  color: #848484;
                  // border-radius: 5%;
                  // padding: 5 * $desk + vw;
                  // text-transform: lowercase;
                  position: relative;
                  // align-items: center;
                  // width: 10vw;
                  font-size: 18 * $desk + vw;
                }
                .approval_wrapper {
                  display: flex;
                  gap: 1vw;
                  width: 15vw;
                  .btn {
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    height: 1vh;
                    align-items: center;
                  }
                  .approve_icon {
                    margin-left: 11 * $desk + vw;
                  }
                  .reject_icon {
                    margin-left: 11 * $desk + vw;
                  }
                }
                .header_para {
                  // background-color: red;
                  transition: all 0.2s ease-in-out;
                }
              }
              .addver_td::first-letter {
                text-transform: uppercase;
              }
              .addver_td_values::first-letter {
                text-transform: uppercase;
              }

              tr {
                text-align: center;
                border: 1 * $desk + vw solid #ececec;
                border-radius: 6 * $desk + vw;
              }
            }
            .error_text {
              // font-size: 24 * $desk + vw;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 50vh;
            }
          }
        }
      }
    }
  }

  .info_card {
    padding: 35 * $desk + vw 32 * $desk + vw 28 * $desk + vw 32 * $desk + vw;
    width: 900 * $desk + vw;
    .info_upper_wrapper {
    }
  }
  .preview_modal {
    .v_email {
      align-self: center !important;
      top: 5%;
      position: absolute;
      font-size: 20 * $desk + vw;
    }

    .v_email:after {
      position: unset;
    }

    .btn {
      width: 404 * $desk + vw;
      margin-bottom: 98.23 * $desk + vw;
    }

    .otp_desc {
      font-family: "Work Sans";
      width: 25.791667vw;
      font-size: 14 * $desk + vw;
      color: #285de4 !important;
    }

    ._counter {
      color: var(--card-label);
    }
  }
}
