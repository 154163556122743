@use "sass:math";
$desk: math.div(100, 1920);

.__home_wrapper__ {
  position: relative;
  .home_body {
    padding: 27 * $desk + vw 36 * $desk + vw;
    background-color: var(--background);
    .home_slider {
      position: relative;
      height: 550 * $desk + vw;
      width: 100%;
      background-color: var(--body-background);
      border-radius: 6px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 26 * $desk + vw;
      margin-bottom: 20 * $desk + vw;
      .homeHeader {
        position: absolute;
        width: 560 * $desk + vw;
        height: 500 * $desk + vw;
        top: 1vw;
        user-select: none;
        right: 240 * $desk + vw;
      }
      .mask1 {
        position: absolute;
        width: 48.1875vw;
        right: -2.0833333333vw;
        height: 165%;
        user-select: none;
        top: -9.335417vw;
      }
      .mask2 {
        position: absolute;
        width: 29.4270833333vw;
        height: 12.5vw;
        right: 4.916667vw;
        user-select: none;
        bottom: -10px;
      }
      .slide_head {
        font-size: 36 * $desk + vw;
        width: 44%;
      }
      .slide_subhead {
        font-size: 16 * $desk + vw;
        width: 44%;
        font-family: Ubuntu;
        margin: 25 * $desk + vw 0;
      }
      .role_container {
        display: flex;
        .role_btn {
          padding: 15 * $desk + vw 40 * $desk + vw;
          background: linear-gradient(97deg, #2d62ed, #013aa2);
          border-radius: 22px;
          cursor: pointer;
          user-select: none;
          .icon {
            width: 30 * $desk + vw;
            height: 30 * $desk + vw;
            vertical-align: text-bottom;
            filter: grayscale(1) brightness(5);
          }
          span {
            font-family: "Open Sans";
            font-weight: 600;
            margin-left: 9 * $desk + vw;
            color: #fff;
          }
        }

        .role_btn:active {
          animation: press 0.2s 1 linear;
        }
        [aria-disabled="true"] {
          opacity: 0.5;
          background: gray;
          &:active {
            animation: none;
          }
        }
      }
    }
    .home_header {
      font-size: 25 * $desk + vw;
      font-family: Segoe UI;
      font-weight: bold;
      margin-left: 10 * $desk + vw;
    }
    .recently_added_wrapper {
      .recently_added_btn_container {
        position: relative;
        display: flex;
        gap: 22 * $desk + vw;
        margin-top: 22 * $desk + vw;
        .btn {
          width: 110 * $desk + vw !important;
          padding: 8 * $desk + vw 0;
          border-radius: 14px;
          font-size: 16 * $desk + vw;
        }
        .view_all {
          position: absolute;
          right: 2.5vw;
          font-weight: 600;
          top: 50%;
          font-size: 17 * $desk + vw;
          transform: translateY(-50%);
          color: var(--primary);
          cursor: pointer;
        }
      }
      .recently_added_cards {
        display: grid;
        --auto-grid-min-size: 12.0208333333vw;
        grid-template-columns: repeat(
          auto-fill,
          minmax(var(--auto-grid-min-size), 1fr)
        );
        grid-gap: 30 * $desk + vw;
        margin-top: 20 * $desk + vw;
        margin-bottom: 30 * $desk + vw;
        padding-right: 1vw;
        .adjust_uc {
          width: 100%;
          height: 290 * $desk + vw;
          padding: 0;
          background-color: transparent;
          .uc_cover {
            height: 250 * $desk + vw;
          }
        }
      }
    }

    .categories_cards {
      width: 90vw;
      // display: flex;
      gap: 30 * $desk + vw;
      margin-top: 20 * $desk + vw;
      .slick-prev {
        right: 80 * $desk + vw;
        left: unset;
        top: -50 * $desk + vw;
        // transform: rotate(180deg) ;
        // transform-origin: s;
        svg {
          width: 1.833333vw;
          height: 1.5vw;
        }
        &:disabled {
          cursor: default;
          svg {
            path {
              fill: #6a6a6d;
            }
          }
        }
      }
      .slick-next {
        right: 55 * $desk + vw;
        left: unset;
        top: -50 * $desk + vw;
        svg {
          width: 1.833333vw;
          height: 1.5vw;
        }
        &:disabled {
          cursor: default;
          svg {
            path {
              fill: #6a6a6d;
            }
          }
        }
      }
    }
  }
}
