@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);
.__tos_wrapper__ {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #f8faff;
  .tos_body {
    width: 86.5740740741vw;
    height: 86.5740740741vh;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.72);
    font-family: Poppins;
    background-color: #fff;
    padding: 20px;
    .data_block {
      padding-right: 1.2vw;
    }
    .backIcon {
      width: 18px;
      vertical-align: middle;
      margin-right: 10px;
      cursor: pointer;
    }
    h1 {
      margin-bottom: 15 * $desk + vw;
      font-size: clamp(1em, 2vw, 1.25em);
      letter-spacing: 2px;
    }
    h2 {
      font-size: clamp(1em, 1.8vw, 1.25em);
      margin: 0.5vw 0;
      text-decoration: underline;
    }
    p {
      font-size: clamp(0.6em, 1.2vw, 0.9em);
      margin: 0.5vw 0;
    }
    .sub {
      font-weight: bold;
    }
    ol {
      list-style-type: none;
      counter-reset: item;
      margin: 0;
      padding: 0;
    }
    .parent {
      display: table;
      counter-increment: item;
      margin-bottom: 0.6em;
      &:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;
        font-size: clamp(1em, 1.8vw, 1.25em);
        font-weight: bold;
      }
    }

    .child {
      li {
        display: table;
        counter-increment: item;
        //   margin-bottom: 0.6em;
        margin: 5px 0;
        font-size: clamp(0.6em, 1.2vw, 0.9em);
      }
      li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;
      }
      li:before {
        content: counters(item, ".") " ";
      }
    }
    li li {
      margin: 0;
    }
  }
}
