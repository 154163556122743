@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.rhs_suggestions_wrapper {
  margin-top: 10%;

  display: flex;
  flex-direction: column;
  .suggestions_header {
    margin-bottom: 10%;
    font-size: 20* $desk + vw;
  }
  .nosuggestions {
    margin-top: 18 * $desk + vw;
    text-align: center;
  }
}

.suggest_main_wrapper {
  text-transform: capitalize;
  margin-bottom: 10%;

  .suggest_image {
    width: 5.604167vw;
    height: 5.041667vw;
    border-radius: 1.40625vw;
    margin-right: 5%;
    align-items: center;
  }
  display: flex;
  .suggest_side_wrapper {
    .suggestion_appname {
      font-size: 18* $desk + vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 150 * $desk + vw;
    }
    .suggest_content {
      font-size: 18* $desk + vw;

      width: 150 * $desk + vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #848484;
      font-weight: 600;
    }
  }
}
