@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.basicDetails_upper_wrapper {
  width:100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .content_main_wrapper {
    width: 320 * $desk + vw;
    text-align: start;
    // margin-right: 200* $desk + vw;
    margin-top: 36 * $desk + vw;
    .content_tag {
      color: #848484;
      font-size: 16 * $desk + vw;
      text-transform: lowercase;
      

    }
    .content_tag::first-letter{
text-transform: uppercase;
    }
  }
  .content_subwrapper {
    margin-top: 10 * $desk + vw;
    color: var(--data-label);
    font-size: 18 * $desk + vw;
    padding: 10 * $desk + vw;
    background: var(--background);
    border-radius: 10 * $desk + vw;
    text-transform: lowercase;
    word-wrap: break-word;
    border: 1px solid #848484;
  }
  .content_subwrapper::first-letter{
    text-transform: uppercase;
  }
}
