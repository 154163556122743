@use "sass:math";
$desk: math.div(100, 1920);
$deskHeight: math.div(100, 1080);

.__users_wrapper__ {
  position: relative;
  .users_body {
    position: relative;
    height: 100%;
    .profile_bg {
      width: 100%;
    }
    .body_content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1500 * $desk + vw;
      top: 75 * $desk + vw;
      h1 {
        color: #fff;
        margin-bottom: 15 * $desk + vw;
        font-size: 32 * $desk + vw;
        letter-spacing: 2px;
      }
      .goBack {
        color: #fff;
        font-family: Poppins;
        font-size: 18 * $desk + vw;
        text-decoration: underline;
        margin-right: 1.5vw;
        cursor: pointer;
      }
      .users_data {
        width: 100%;
        background-color: var(--background);
        height: 750 * $deskHeight + vh;
        border-radius: 12px;
        overflow: hidden;
        border: 2px solid #e7e7e7;

        .search_result_wrapper {
          width: 100%;
          height: 100%;
          padding: 20 * $desk + vw 32 * $desk + vw;

          .content {
            border: 2px solid #f4f7fe;
            border-radius: 12px;
            padding: 15 * $desk + vw 32 * $desk + vw;
            width: 100%;
            height: 100%;
            h2 {
              font-size: 24 * $desk + vw;
              font-weight: 600;
            }
            .input {
              position: relative;
            }
            .result_table_container {
              border: 2px solid #f4f7fe;
              width: 102%;
              border-radius: 12px;
              // height: 560 * $deskHeight + vh;
              height: 45.851852vh;
              margin-top: 20 * $desk + vw;
              margin-left: -14 * $desk + vw;
              .noData {
                width: 100%;
                height: 100%;
                display: grid;
                place-content: center;
                font-weight: 600;
                font-size: 28 * $desk + vw;
              }
            }
            .filter_wrapper {
              display: flex;
              align-items: center;
              .search_wrapper {
                position: relative;
                display: flex;
                height: 2.125vw;
                border-radius: 16px;
                border: 2px solid #eff1fa;
                align-self: flex-end;
                box-shadow: 9px -8px 126px -35px rgb(0 0 0 / 17%);
                z-index: 2;
                width: 16vw;
                padding-right: 0.5vw;
                // overflow: hidden;
                .search_icon {
                  position: relative;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 25 * $desk + vw;
                  left: 30 * $desk + vw;
                }
                .search_input {
                  margin-left: 50 * $desk + vw;
                  align-self: center;
                  outline: none;
                  border: 0;
                  width: 90%;
                  font-size: 18 * $desk + vw;
                  background-color: var(--background);
                  color: var(--text);
                  padding-right: 0.5vw;
                  &::placeholder {
                    color: var(--primaryInvert);
                    opacity: 0.5;
                    font-size: 16 * $desk + vw;
                  }
                }

                .search_result_wrapper {
                  position: absolute;
                  width: 100%;
                  top: 104%;
                  left: -0.08vw;
                  // padding: 20 * $desk + vw;
                  padding: 0;
                  height: unset !important;
                  // min-height: 200 * $desk + vw;
                  // max-height: 600 * $desk + vw;

                  border-left: 2px solid #eff1fa;
                  border-bottom: 2px solid #eff1fa;
                  border-right: 2px solid #eff1fa;
                  background-color: var(--background);
                  padding-top: 5 * $desk + vw;
                  padding-bottom: 10 * $desk + vw;
                  &::after {
                    content: " ";
                    position: absolute;
                    right: -2 * $desk + vw;
                    top: -2vw;
                    width: 10 * $desk + vw;
                    height: 50 * $desk + vw;
                    border-right: 2px solid #eff1fa;
                    z-index: 0;
                  }
                }
              }
              .filter-options-wrapper {
                display: flex;
                gap: 1vw;
                margin-left: 2vw;
                .adjust_dd {
                  .selected-text {
                    padding: 8 * $desk + vw 0;
                    padding-right: 1.4vw;
                    padding-left: 0.5vw;
                    font-size: 0.7291666667vw;
                    background-color: var(--device-filter);
                    border-radius: 8px;
                    height: unset;
                  }
                  .marquee {
                    font-family: "Open Sans" !important;
                  }
                  .select-options {
                    .custom-select-option {
                      padding-right: 0;
                      padding-left: 8 * $desk + vw;
                    }
                    &:before {
                      left: 82.5%;
                    }
                    &::after {
                      left: 86.5%;
                    }
                  }
                  .active__ {
                    // top: unset;
                  }
                }
                .location_search {
                  display: inline-table;
                  .selected_options {
                    position: relative;
                    z-index: 100;
                    display: flex;
                    border: 2px solid #dddddd;
                    align-self: flex-end;
                    height: 39 * $desk + vw;
                    padding: 0.4166666667vw 0;
                    padding-right: 1.5vw;
                    padding-left: 0.5vw;
                    width: 10vw;
                    border-radius: 0 12px 12px 0;
                    font-size: 0.7291666667vw;
                    p {
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      text-transform: capitalize;
                    }
                  }
                  .location_search_input {
                    border: 1px solid #dddddd;
                    padding: 0.35vw 0;
                    padding-right: 1.4vw;
                    padding-left: 0.5vw;
                    border-radius: 6px;
                    outline: none;
                    background-color: var(--device-filter);
                    color: var(--text);
                    font-size: 0.7291666667vw;
                    width: 90%;
                    display: block;
                    margin: 0.5vw auto;
                  }
                  .suggestion_wrapper {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    border-radius: 6px;
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;
                    border: 2px solid #dddddd;
                    background-color: var(--background);
                    .suggestion {
                      position: relative;
                      z-index: 1;
                      display: flex;
                      flex-direction: column;
                      width: 100%;
                      background-color: var(--background);
                      // color: var(--text);
                      // width: 100%;
                      // border: 0;
                      // padding: 0.4166666667vw 1.25vw;
                      // text-align: left;
                      // background-color: var(--background);
                      // border-bottom: 1px solid #f4f7fe;

                      // cursor: pointer;
                      // word-break: break-all;
                      // overflow: hidden;
                      // font-size: 0.78125vw;
                      // border-radius: 6px;
                      // text-transform: capitalize;
                      .options {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border: 0;
                        color: var(--text);
                        padding: 8 * $desk + vw 24 * $desk + vw;
                        padding-right: 10 * $desk + vw;
                        text-align: left;
                        background-color: var(--background);
                        border-bottom: 1px solid #f4f7fe;
                        cursor: pointer;
                        text-transform: capitalize;
                        font-size: 0.8291666667vw;
                        &:last-child {
                          border-bottom: 0;
                        }
                        &:hover {
                          background-color: var(--primary);
                          color: #fff;

                        }
                      }
                    }
                  }
                }
                .adjust_location_dd {
                  .selected-text {
                    padding: 8 * $desk + vw 0;
                    padding-right: 1.4vw;
                    padding-left: 0.5vw;
                    font-size: 0.7291666667vw;
                    background-color: var(--device-filter);
                    border-radius: 8px;
                    border-right: 0;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    text-transform: capitalize;
                  }
                  .marquee {
                    font-family: "Open Sans" !important;
                  }
                  .select-options {
                    .custom-select-option {
                      padding-right: 0;
                      padding-left: 8 * $desk + vw;
                    }
                    &:before {
                      left: 82.5%;
                    }
                    &::after {
                      left: 86.5%;
                    }
                  }
                  .active__ {
                    // top: unset;
                  }
                }
              }
            }
          }
        }
      }
      .__profile_details__ {
        display: flex;
        padding: 30 * $desk + vw;
        height: 100%;
        gap: 20 * $desk + vw;
        .left {
          // background-color: red;
          height: 100%;
          flex: 1;
          border-radius: 12px;
          border: 0.1041666667vw solid rgba(218, 228, 248, 0.5);
          padding: 16 * $desk + vw 30 * $desk + vw;
          padding-right: 1vw;
          .user-name {
            font-size: 30 * $desk + vw;
            color: var(--text);
            letter-spacing: unset;
            margin-bottom: 0;
          }
          .user-created {
            font-size: 18 * $desk + vw;
            color: var(--text);
            opacity: 0.7;
            margin-top: 5 * $desk + vw;
          }
          .flex {
            display: flex;
            // height: 100%;
            margin-top: 20 * $desk + vw;
            padding-left: 0.5vw;
            padding-bottom: 1vw;
            .flex_left {
              flex: 4;
              .profile_image {
                width: 9vw;
                height: 9vw;
                object-fit: cover;
                border-radius: 12px;
                margin-bottom: 10 * $desk + vw;
                box-shadow: var(--box-shadow);
              }
              .user_avatar_img {
                width: 210 * $desk + vw;
                height: 210 * $desk + vw;
                border-radius: 8px;
                border: 1px solid var(--primary);
              }
              .requestCard {
                margin-top: 20 * $desk + vw;
                margin-bottom: 20 * $desk + vw;
                .manu_request_card_ {
                  border-radius: 12px;
                  box-shadow: var(--box-shadow);
                  width: 90%;
                }
              }
              .role_table {
                border-collapse: collapse;
                box-shadow: var(--box-shadow);
                font-size: 16 * $desk + vw;
                border-radius: 12px;
                td {
                  padding: 0.5vw;
                  border: 1px solid #dddddd;
                  text-transform: capitalize;
                }
              }
            }
            .flex_right {
              display: flex;
              flex-direction: column;
              gap: 50 * $desk + vw;
              flex: 5;
              height: 85%;
              .user-data {
                display: flex;
                .icon {
                  display: grid;
                  place-content: center;
                  width: 50 * $desk + vw;
                  height: 50 * $desk + vw;
                  border-radius: 50%;
                  background-color: var(--sidenav);
                  svg {
                    width: 25 * $desk + vw;
                    height: 25 * $desk + vw;
                  }
                }
                .info {
                  font-weight: 500;
                  font-size: 16 * $desk + vw;
                  margin-left: 20 * $desk + vw;
                }
              }
            }
            ._text {
              font-size: 16 * $desk + vw;
              opacity: 0.5;
              margin-bottom: 9 * $desk + vw;
            }
          }
        }
        .right {
          height: 100%;
          flex: 1;
          .top {
            border-radius: 12px;
            border: 0.1041666667vw solid rgba(218, 228, 248, 0.5);
          }
          .uc-header {
            padding: 15 * $desk + vw 30 * $desk + vw;
            display: flex;
            justify-content: space-between;
            border-bottom: 0.1041666667vw solid rgba(218, 228, 248, 0.5);

            .uc-details {
              font-size: 26 * $desk + vw;
              color: var(--text);
              font-weight: 400;
              letter-spacing: unset;
              margin-bottom: 0;
            }
            .uc-purchased {
              font-size: 14 * $desk + vw;
              font-family: "Open Sans";
              margin-top: 5 * $desk + vw;
              opacity: 0.7;
              font-weight: 400;
              span {
                font-size: 14 * $desk + vw;
                font-family: "Open Sans";
                font-weight: 600;
              }
            }
          }
          .usecase-details-table {
            padding-left: 0;
            padding-right: 12 * $desk + vw;
            td {
              padding: 10 * $desk + vw 15 * $desk + vw;
              border-bottom: 0;
            }
            tr:nth-child(even) {
              td:first-child {
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
                background-color: var(--sidenav);
              }
              td:last-child {
                border-bottom-right-radius: 15px;
                border-top-right-radius: 15px;
                background-color: var(--sidenav);
              }
              td {
                background-color: var(--sidenav);
              }
            }
            .sr {
              font-family: "Open Sans";
              font-weight: 600;
              text-align: left;
            }
            .userInfo {
              display: block;
              text-align: left;
              p:nth-child(1) {
                font-size: 18 * $desk + vw;
                font-weight: 400;
              }
              p:nth-child(2) {
                font-size: 14 * $desk + vw;
                font-weight: 400;
                opacity: 0.5;
              }
            
            }
            .infoIcon {
              width: 22 * $desk + vw !important;
              height: 22 * $desk + vw !important;
              cursor: pointer;
              path {
                fill: var(--text);
              }
            }
            .suspendedIcon {
              display: inline-block;
              // margin-right: 12 * $desk + vw;
            }
          }
        }
      }
    }
  }
  //global table css
  .data_table {
    border-collapse: separate;
    border-spacing: 0 9 * $desk + vw;
    width: 100%;
    padding-right: 1vw;
    padding-left: 1vw;
    td {
      border-bottom: 1px solid #f0f0f0;
      // border-top: 1px solid #f0f0f0;
      padding-top: 15 * $desk + vw;
      padding-bottom: 15 * $desk + vw;
      font-size: 15 * $desk + vw;
    }
    tr {
      cursor: pointer;
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    .adjust_info {
      display: flex;
      align-items: center;
    }

    tr {
      border-radius: 35px;
      border: 2px solid red;
      // background-color: red;
    }
    td {
      padding: 20 * $desk + vw 15 * $desk + vw;
      text-align: center;
      vertical-align: middle;
      // font-weight: 300;
      font-size: 18 * $desk + vw;
      // color: #fff;
      svg {
        display: inline-block;
        width: 30 * $desk + vw !important;
        height: 30 * $desk + vw !important;
        vertical-align: top;
        margin-right: 12 * $desk + vw;
      }
    }
    .sr {
      font-size: 20 * $desk + vw;
      font-weight: 500;
      // color: var(--primaryInvert);
      font-style: italic;
    }
    .userInfo {
      display: flex;
      justify-content: flex-start;
      .user_avatar_img {
        // display: inline-block;
        width: 60 * $desk + vw !important;
        height: 60 * $desk + vw !important;
        border-radius: 12px;
        object-fit: cover;
        margin-right: 0;
      }
      .meta {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
        margin-left: 20 * $desk + vw;
        .user_meta{
          text-transform: capitalize;
        }
        p:first-child {
          font-weight: bold;
        }
        p:nth-child(2) {
          font-size: 16 * $desk + vw;
          font-weight: 400;
        }
      }
    }
  }
}
